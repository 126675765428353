/**
 * author: He Ding Xiong
 * date: 2021-12-13
 * des: 类方法
 * */
import {
  drawImg,
  measureTextWidth,
  drawRoundRectPath,
  drawShadow,
  drawLeftTitle,
  drawLeftContent,
  drawDoubleEllipse,
} from './utils';
import {
  RECTANGLE_Y,
  RECTANGLE_R,
  ELLIPSE_OFFSET_X,
  ELLIPSE_OFFSET_Y,
  ELLIPSE_OFFSET_RX,
  ELLIPSE_OFFSET_RY,
  DIALOG_OFFSET_Y,
  CANVAS_WIDTH,
  CANVAS_HEIGHT,
  CANVAS_MULTIPLE,
  WAREHOUSE_TYPE,
  STORE_GOODS_STATE,
  STORE_GOODS_TYPE,
  STORE_GOODS_WARING,
  STORE_GOODS_ERROR,
  RECTANGLE_SHADOW_X,
  RECTANGLE_SHADOW_Y,
  RECTANGLE_SHADOW_BLUR,
  RECTANGLE_TITLE_X,
  RECTANGLE_TITLE_Y,
  RECTANGLE_OFFSET_Y,
  RECTANGLE_OFFSET_NUM_Y,
  CANVAS_DEBOUNCE_TIME,
  TITLE_NORMAL_COLOR,
  TITLE_ACTIVE_COLOR,
  TITLE_WARING_COLOR,
  TITLE_SELECT_COLOR,
  CONTENT_NORMAL_COLOR,
  CONTENT_ACTIVE_COLOR,
  CONTENT_WARING_COLOR,
  CONTENT_SELECT_COLOR,
  NUM_NORMAL_COLOR,
  NUM_ACTIVE_COLOR,
  NUM_WARING_COLOR,
  NUM_SELECT_COLOR,
  ELLIPSE_NEW_COLOR,
} from './const';

/**
 * 根据ID与配置 构建一个仓库
 */
export default class Warehouse {
  /**
   * @param dom canvas对象
   * @param {Object} config 配置独享 根据类型获取
   */
  constructor(dom, config) {
    this.dom = dom;
    this.dom.width = CANVAS_WIDTH;
    this.dom.height = CANVAS_HEIGHT;
    this.ctx = dom.getContext('2d');
    this.loadBgImg = null;
    this.needAnimation = false;
    this.timer = null; // 计时器数组
    this.speed = 0.24 * CANVAS_MULTIPLE; // 圆速度
    this.minOffsetRx = ELLIPSE_OFFSET_RX / 2;
    this.maxOffsetRy = ELLIPSE_OFFSET_RY / 2;
    this.config = config || {};
    this.level = config.level || WAREHOUSE_TYPE.HOME; // 当前层级
    this.dialogConfigData = config.dialogConfigData || [];
    this.refreshTimer = null;

    // 初始化图形
    this.init();
    return this;
  }

  init() {
    if (!this.config.bgImg) {
      throw Error('config.bgImg is Must');
    }
    this.loadBgImg = drawImg(this.config.bgImg);
    this.loadBgImg.onload = () => {
      this.ctx.drawImage(this.loadBgImg, 0, 0, this.dom.width, this.dom.height);
      switch (this.level) {
        case WAREHOUSE_TYPE.HOME:
          this.initHome();
          break;
        case WAREHOUSE_TYPE.STOREHOUSE:
          this.initStoreHouse();
          break;
        case WAREHOUSE_TYPE.SHELVES:
          this.initShelves();
          break;
        default:
          break;
      }
    };
  }

  /**
   * 初始化首页
   */
  // eslint-disable-next-line class-methods-use-this
  initHome() {
  }

  /**
   * 根据当前数据、判断是否需要动画
   */
  animationJudge() {
    const index = this.dialogConfigData.findIndex((config) => (
      config.state === STORE_GOODS_STATE.ACTIVE || config.waring === STORE_GOODS_STATE.WARING
    ));
    if (index !== -1) {
      this.needAnimation = true;
      this.animationLoad();
    } else {
      this.needAnimation = false;
      window.cancelAnimationFrame(this.timer);
    }
  }

  animationLoad() {
    window.cancelAnimationFrame(this.timer);
    const animation = () => {
      this.timer = requestAnimationFrame(animation);
      // 先清空画板
      this.ctx.clearRect(0, 0, this.dom.width, this.dom.height);
      // 背景已经加载过了 直接使用
      this.ctx.drawImage(this.loadBgImg, 0, 0, this.dom.width, this.dom.height);
      this.drawMulRectangleDialogBox();
    };
    animation();
  }

  /**
   * 初始化库区
   */
  initStoreHouse() {
    // 获取坐标列表 根据选择状态 判断是否渲染 由switch 控制
    this.drawMulRectangleDialogBox();
    // 初始化弹窗后, 进行动画渲染判断
    this.animationJudge();
  }

  /**
   * 初始化货架
   */
  initShelves() {
    this.drawMulRectangleDialogBox();
  }

  // 刷新页面
  refreshRectangleDialogBox(dialogConfig, bg = null) {
    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
    }

    // 先清空画板 减少频繁重绘次数
    this.refreshTimer = setTimeout(() => {
      this.refreshRectangle(dialogConfig, bg);
    }, CANVAS_DEBOUNCE_TIME);
  }

  refreshRectangle(dialogConfig, bg = null) {
    this.ctx.clearRect(0, 0, this.dom.width, this.dom.height);
    this.dialogConfigData = dialogConfig;
    // 背景已经加载过了 直接使用
    if (bg != null) {
      this.loadBgImg = drawImg(bg);
      this.loadBgImg.onload = () => {
        this.ctx.drawImage(this.loadBgImg, 0, 0, this.dom.width, this.dom.height);
        this.drawMulRectangleDialogBox();
        this.animationJudge();
      };
    } else {
      this.ctx.drawImage(this.loadBgImg, 0, 0, this.dom.width, this.dom.height);
      this.drawMulRectangleDialogBox();
      this.animationJudge();
    }
  }

  /**
   * 绘制弹窗对话框
   */
  drawMulRectangleDialogBox() {
    // eslint-disable-next-line max-len
    const index = this.dialogConfigData.findIndex((config) => config.state !== STORE_GOODS_STATE.NORMAL);
    const ellipseAnimation = this.needAnimation && index !== -1;

    // 在这里进行圆的计算 Tips: 椭圆是原点定位
    if (ellipseAnimation) {
      const minX = ELLIPSE_OFFSET_RX / 2;
      const minY = ELLIPSE_OFFSET_RY / 2;
      const maxX = ELLIPSE_OFFSET_RX;
      const maxY = ELLIPSE_OFFSET_RY;
      this.minOffsetRx += this.speed;
      this.maxOffsetRy += (this.speed / 2);

      if (this.minOffsetRx > maxX && this.maxOffsetRy > maxY) {
        this.minOffsetRx = minX;
        this.maxOffsetRy = minY;
      }
    }
    this.dialogConfigData.forEach((config) => {
      this.drawRectangleDialogBox(config);
    });
  }

  // eslint-disable-next-line class-methods-use-this
  drawRectangleText(ctx, config) {
    let titleColor = '';
    let contentColor = '';
    let numColor = '';
    // console.log(config);

    if (config.isExpired) {
      titleColor = TITLE_SELECT_COLOR;
      contentColor = CONTENT_SELECT_COLOR;
      numColor = NUM_SELECT_COLOR;
    }

    // 样式颜色 优先激活状态、其次筛选状态
    if (config.isNear && config.state !== STORE_GOODS_STATE.ACTIVE) {
      titleColor = 'rgba(255, 128, 48, 1)';
      contentColor = 'rgba(255, 128, 48, 1)';
      numColor = 'rgba(255, 128, 48, 1)';
    } else if (config.state === STORE_GOODS_STATE.ACTIVE) {
      titleColor = TITLE_ACTIVE_COLOR;
      contentColor = CONTENT_ACTIVE_COLOR;
      numColor = NUM_ACTIVE_COLOR;
    } else if (
      config.waring === STORE_GOODS_WARING.ACTIVE
      && config.error !== STORE_GOODS_STATE.ACTIVE
    ) {
      titleColor = TITLE_WARING_COLOR;
      contentColor = CONTENT_WARING_COLOR;
      numColor = NUM_WARING_COLOR;
    } else if (
      config.error === STORE_GOODS_STATE.ACTIVE
      && config.waring === STORE_GOODS_WARING.ACTIVE
    ) {
      titleColor = TITLE_SELECT_COLOR;
      contentColor = CONTENT_SELECT_COLOR;
      numColor = NUM_SELECT_COLOR;
    } else if (
      config.state === STORE_GOODS_STATE.NORMAL
      && config.waring === STORE_GOODS_WARING.NORMAL
    ) {
      titleColor = TITLE_NORMAL_COLOR;
      contentColor = CONTENT_NORMAL_COLOR;
      numColor = NUM_NORMAL_COLOR;
    }

    // 绘制标题
    drawLeftTitle(ctx, config.title, RECTANGLE_TITLE_X, RECTANGLE_TITLE_Y, titleColor);
    if (config?.content?.length > 0) {
      config.content.forEach((item, index) => {
        // eslint-disable-next-line max-len
        drawLeftContent(ctx, item.label, item.value, RECTANGLE_TITLE_X, RECTANGLE_OFFSET_Y + index * RECTANGLE_OFFSET_NUM_Y, contentColor, numColor);
      });
    }
  }

  compareContentText(config) {
    let maxWidth = 0;
    config.forEach((measureText) => {
      const labelWidth = measureTextWidth(this.ctx, measureText.label, 14);
      const valueWidth = measureTextWidth(this.ctx, measureText.value, 16, 'DinBold');
      if ((labelWidth + valueWidth) > maxWidth) {
        maxWidth = labelWidth + valueWidth;
      }
    });
    return maxWidth;
  }

  /**
   * 绘制矩形对话框
   * @param { object } config 配置
   */
  drawRectangleDialogBox(config) {
    const textWidth = this.compareContentText(config.content) + 2 * RECTANGLE_TITLE_X;
    this.ctx.save();
    // 移动到中心点
    // console.log(config.x, config.y);
    this.ctx.translate(config.x + ELLIPSE_OFFSET_X, config.y + ELLIPSE_OFFSET_Y);
    // 展示文字弹窗的情况: 1.类型不为隐藏 2.类型为激活状态 3.搜索结果 4.鼠标经过
    const needDrawDialog = config.type !== STORE_GOODS_TYPE.HIDDEN
      || config.state !== STORE_GOODS_STATE.NORMAL
      || config.waring === STORE_GOODS_WARING.ACTIVE
      || config.isHover;

    if (needDrawDialog) {
      // 根据文字长度计算宽度、高度固定.
      // 计算规则: 中心点移动到圆点左上角顶点. 矩形宽度为： 圆点顶点x - (计算宽度 / 2) 矩形高度: 圆点顶点y - 固定高度
      this.ctx.save();
      this.ctx.translate(-(textWidth / 2), -DIALOG_OFFSET_Y);

      drawRoundRectPath(this.ctx, textWidth, RECTANGLE_Y, RECTANGLE_R);
      // 绘制阴影
      drawShadow(this.ctx, RECTANGLE_SHADOW_X, RECTANGLE_SHADOW_Y, RECTANGLE_SHADOW_BLUR, 'rgba(34, 103, 81, 0.1)');
      // eslint-disable-next-line no-param-reassign
      this.ctx.fillStyle = '#fff';
      // eslint-disable-next-line no-param-reassign
      this.ctx.fill();
      // 文案绘制, 同一偏移设置
      this.drawRectangleText(this.ctx, config);
      this.ctx.restore();
    }

    // 计算当前应该使用什么颜色标记底部圆点 优先顺序：hover - state - error - waring
    let colorConfig = null;

    // hover状态
    if (config.isHover) {
      colorConfig = ELLIPSE_NEW_COLOR.SELECT;
    } else if (config.isNear && config.state !== STORE_GOODS_STATE.ACTIVE) {
      colorConfig = ELLIPSE_NEW_COLOR.NEAR;
    } else if (config.isExpired && config.state !== STORE_GOODS_STATE.ACTIVE) {
      colorConfig = ELLIPSE_NEW_COLOR.WARING;
    } else if (config.state === STORE_GOODS_STATE.ACTIVE) {
      colorConfig = ELLIPSE_NEW_COLOR.SELECT;
    } else if (config.error === STORE_GOODS_ERROR.ACTIVE) {
      colorConfig = ELLIPSE_NEW_COLOR.WARING;
    } else if (config.waring === STORE_GOODS_WARING.ACTIVE) {
      colorConfig = ELLIPSE_NEW_COLOR.NORMAL;
    } else {
      colorConfig = ELLIPSE_NEW_COLOR.NORMAL;
    }

    const { minColor, maxColor } = colorConfig;

    const ellipseAnimation = this.needAnimation && config.state !== STORE_GOODS_STATE.NORMAL;

    // 绘制底部椭圆 如果是hover状态、激活状态需要变为绿色， 点击状态需要动画展示
    drawDoubleEllipse(
      this.ctx,
      0,
      0,
      ellipseAnimation ? this.minOffsetRx : ELLIPSE_OFFSET_RX,
      ellipseAnimation ? this.maxOffsetRy : ELLIPSE_OFFSET_RY,
      ELLIPSE_OFFSET_RX / 2,
      ELLIPSE_OFFSET_RY / 2,
      minColor,
      maxColor,
    );
    this.ctx.restore();
  }
}
