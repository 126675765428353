<template>
  <!-- <canvas ref="home" /> -->
  <div class="home-box">
    <div class="home-span-box">
      <img src="../assets/panel/home2.png">
    </div>
  </div>
</template>

<script>
export default {
  name: 'HOME',
};
</script>

<style lang="scss" scoped>
.home-span-box{
  width: 100%;
  height: 100%;
  margin: 0px;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
