import {
  otmsHttp,
  warehouseHttp,
  wmsPlusHttp,
} from '@/utils/http/index';

/**
 * @description: 2.5D仓库首页
 * @api文档：https://yapi.ops.yunlizhi.cn/project/535/interface/api/18127
 */
export function getWarehouseHomePage(data = {}) {
  return warehouseHttp.post('/home/page/query', data);
}

/**
 * @description: 2.5D仓库库区
 * @api文档：https://yapi.ops.yunlizhi.cn/project/535/interface/api/18157
 */
export function getZoneQuery(data = {}) {
  return warehouseHttp.post('/zone/query', data);
}

/**
 * 获取单个库区详情
 * @param data
 * @param {String} data.zoneCode 库区编码
 */
export const getOneZoneDetail = (data) => warehouseHttp.post('/zone/detail', data);

/**
 * 库区配置查询
 * @param data
 */
export const getZoneConfig = (data = {}) => warehouseHttp.post('/zone/config/list', data);

/**
 * @description: 货主搜素
 * @api文档：（旧） https://yapi.ops.yunlizhi.cn/project/388/interface/api/12400
 * @api文档：（新） https://yapi.ops.yunlizhi.cn/project/388/interface/api/18865
 */
export function warehouseShipperQuery(data = {}) {
  return wmsPlusHttp.post('/warehouse_foundation/shipper/v2/list', data);
}

/**
 * @description: 货品搜索
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/15517
 */
export function warehouseGoodsQuery(data = {}, params = {}) {
  return wmsPlusHttp.post('/warehouse_foundation/warehouse_goods/page_by_shipper_v2', data, {
    params,
  });
}

/**
 * 仓库列表
 * @api文档: https://yapi.ops.yunlizhi.cn/project/388/interface/api/13940
 */
export const warehouseList = (data = {}) => wmsPlusHttp.post('/warehouse_foundation/warehouse/list', data);

/**
 * 货物查找
 * @api文档: https://yapi.ops.yunlizhi.cn/project/388/interface/api/11914
 */
export const warehouseGoods = (data = {}) => wmsPlusHttp.post('/warehouse_foundation/warehouse_goods/list', data);

/**
 * 货架查询
 * @param data
 * @api文档：https://yapi.ops.yunlizhi.cn/project/535/interface/api/18275
 */
export const getShelvesQuery = (data = {}) => warehouseHttp.post('/row/query', data);

/**
 * 货架详情查询
 * @param data
 * @api文档：https://yapi.ops.yunlizhi.cn/project/535/interface/api/18277
 */
export const shelvesDetailQuery = (data = {}) => warehouseHttp.post('/row/detail', data);

/**
 * 库位查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/535/interface/api/18315
 * @param data
 */
export const getLocationQuery = (data = {}) => warehouseHttp.post('/storehouse/query', data);

/**
 * 库位详情查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/535/interface/api/18317
 * @param data
 */
export const getLocationDetailQuery = (data = {}) => warehouseHttp.post('/storehouse/detail', data);

/**
 * 查询货主信息
 * @param data
 * @api文档：https://yapi.ops.yunlizhi.cn/project/295/interface/api/8488
 */
export const getCustomerDetails = (data = {}) => otmsHttp.post('/crm-plus/customer/detail/list', data);
