<template>
  <div
    v-if="hasSearch"
    class="search-bar-root"
  >
    <div
      class="search-condition"
    >
      <div
        v-if="!isIframe || iframeOfOTMS"
        class="condition-item"
      >
        <el-select
          v-model="ownerInfo"
          class="select-item"
          placeholder="请输入货主编码/名称"
          clearable
          filterable
          :filter-method="ownerFilterFn"
          @change="handleChangeOwnerInfo"
          @clear="handleClearOwnerInfo"
          @visible-change="ownerVisibleChange"
        >
          <el-option
            v-for="item in shipperFilterOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
            <span v-html="ownerFilterSelect(item.label)" />
          </el-option>
          <div class="pagination-wrap">
            <el-pagination
              small
              background
              layout="prev, pager, next"
              :page-size="paginationSize"
              :pager-count="paginationCount"
              :current-page="shipperCPage"
              :total="shipperTotal"
              @current-change="handleChangeShipper"
            />
          </div>
        </el-select>
      </div>
      <div class="condition-item">
        <el-select
          v-model="goodsInfo"
          class="select-item"
          filterable
          clearable
          placeholder="请输入货品编码/名称"
          :filter-method="goodsFilterFn"
          @change="handleChangeGoodsInfo"
          @clear="handleClearGoodsInfo"
          @visible-change="goodsVisibleChange"
        >
          <el-option
            v-for="item in goodsFilterOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
            <span v-html="goodsFilterSelect(item.label)" />
          </el-option>
          <div class="pagination-wrap">
            <el-pagination
              small
              background
              layout="prev, pager, next"
              :page-size="paginationSize"
              :pager-count="paginationCount"
              :current-page="goodsCPage"
              :total="goodsTotal"
              @current-change="handleChangeGoods"
            />
          </div>
        </el-select>
      </div>
      <div
        class="condition-list"
      >
        <div
          v-for="(condition) in conditionList"
          :key="condition.id"
        >
          <div
            class="btn"
            :class="{[condition.class]: true, 'active': condition.active}"
            @click="selectCondition(condition.id)"
          >
            <span class="icon" /><span class="title">{{ condition.title }}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="reset-btn"
      @click="handleResetCondition"
    >
      <span class="text">
        重置
      </span>
    </div>
  </div>
</template>

<script>
import EventBus from '@/views/3d-warehouse/warehouse/eventbus';
import {
  warehouseShipperQuery,
  warehouseGoodsQuery,
} from '../../api/warehouse';
import {
  WAREHOUSE_TYPE,
  GOODS_STATUS_TYPE,
  GOODS_STATUS_TYPE_MAP,
  IFRAME_TAG,
  DONGQI_CODE,
  DONGQI_WSCODE,
} from '../const';

export default {
  name: 'SearchBar',
  props: {
    level: {
      type: Number,
      default: WAREHOUSE_TYPE.HOME,
    },
    isIframe: {
      type: Boolean,
      default: false,
    },
    /* iframe的类型 */
    iframeTag: {
      type: String,
      default: '',
    },
  },
  emits: ['resetSearchCondition'],
  data() {
    return {
      paginationSize: 7, // 页码
      paginationCount: 4, // 最大页数
      shipperCPage: 1, // 货主当前页
      shipperTotal: 0, // 货主总数
      goodsCPage: 1, // 货品当前页
      goodsTotal: 1, // 货品总数
      ownerFilterValue: '',
      goodsFilterValue: '',
      ownerInfo: '', // 货主信息
      goodsInfo: '', // 货品搜索条件
      shipperFilterResult: [], // 货主筛选结果
      goodsFilterResult: [], // 货品筛选结果
      shipperOptions: [], // 商户
      shipperFilterOptions: [], // 商户搜索列表
      goodsOptions: [], // 货品
      goodsFilterOptions: [], // 货品搜索列表
      activeConditions: null, // 激活的条件
      loading: false,
      defaultConditionsConfig: [{
        id: GOODS_STATUS_TYPE.NEAR,
        title: GOODS_STATUS_TYPE_MAP[GOODS_STATUS_TYPE.NEAR],
        class: 'near',
        active: false,
      }, {
        id: GOODS_STATUS_TYPE.EXPIRE,
        title: GOODS_STATUS_TYPE_MAP[GOODS_STATUS_TYPE.EXPIRE],
        class: 'expire',
        active: false,
      }, {
        id: GOODS_STATUS_TYPE.IMPORT,
        title: GOODS_STATUS_TYPE_MAP[GOODS_STATUS_TYPE.IMPORT],
        class: 'import',
        active: false,
      }, {
        id: GOODS_STATUS_TYPE.UP,
        title: GOODS_STATUS_TYPE_MAP[GOODS_STATUS_TYPE.UP],
        class: 'up',
        active: false,
      }, {
        id: GOODS_STATUS_TYPE.DOWN,
        title: GOODS_STATUS_TYPE_MAP[GOODS_STATUS_TYPE.DOWN],
        class: 'down',
        active: false,
      }],
    };
  },
  computed: {
    /* 是否是otms嵌入 */
    iframeOfOTMS() {
      return this.iframeTag === IFRAME_TAG.OTMS;
    },
    hasSearch() {
      return this.level !== WAREHOUSE_TYPE.HOME;
    },
    conditionList() {
      const condConfig = [...this.defaultConditionsConfig];
      // 如果是iframe. 且不是otms
      if (this.isIframe && !this.iframeOfOTMS) {
        condConfig.splice(2, 2);
      }
      return condConfig.map((condition) => ({
        ...condition,
        active: this.activeConditions === condition.id, // 如果匹配则 激活
      }));
    },
  },
  watch: {
    '$route.query.level': {
      immediate: true,
      handler(level) {
        if (parseInt(level, 10) === WAREHOUSE_TYPE.HOME) {
          this.ownerInfo = '';
          this.ownerFilterValue = '';
          this.goodsInfo = '';
          this.goodsFilterValue = '';
          this.activeConditions = null;
        }
      },
    },
    '$route.query.owner': {
      deep: true,
      handler(owner) {
        // 其他地方设置owner时候、同步该值
        if (this.ownerInfo !== owner) {
          this.ownerInfo = owner;
          this.ownerFilterValue = owner;
          this.goodsOptions = [];
          this.goodsInfo = ''; // 货品搜索条件
          const temp = [...this.shipperOptions];
          // 如果已经有选中值, 那么初始化的时候需要定位
          if (owner) {
            const index = temp.findIndex((item) => item.value === owner);
            if (index !== -1) {
              this.shipperCPage = parseInt(index / this.paginationSize, 10) + 1;
            }
          }
          // eslint-disable-next-line max-len
          this.shipperFilterOptions = temp.slice((this.shipperCPage - 1) * this.paginationSize, this.shipperCPage * this.paginationSize);
        }
      },
    },
    shipperOptions() {
      const { owner } = this.$route.query;
      const temp = [...this.shipperOptions];
      // 如果已经有选中值, 那么初始化的时候需要定位
      if (owner) {
        const index = temp.findIndex((item) => item.value === owner);
        if (index !== -1) {
          this.shipperCPage = parseInt(index / this.paginationSize, 10) + 1;
        }
      }
      // eslint-disable-next-line max-len
      this.shipperFilterOptions = temp.slice((this.shipperCPage - 1) * this.paginationSize, this.shipperCPage * this.paginationSize);
    },
    goodsOptions() {
      this.goodsFilterOptions = [...this.goodsOptions];
    },
    ownerInfo(val) {
      // 不为空的时候、刷新货品信息
      if (!val) {
        return;
      }
      this.fetchGoodsQuery();
    },
    goodsInfo(val) {
      if (!val) {
        return;
      }
      this.setRouterQuery({
        goods: this.goodsInfo,
      });
    },
  },
  async mounted() {
    // 触发商品搜索的变化
    EventBus.on('goodsChange', (args) => {
      if (args.goodsId == null) {
        return;
      }
      this.goodsInfo = args.goodsId;
      this.setRouterQuery({
        goods: args.goodsId,
      });
    });

    await this.initSearchCondition();
    await this.fetchSearchConfig();
    if (this.isIframe && !this.iframeOfOTMS) {
      await this.fetchGoodsQuery();
    }
  },
  methods: {
    ownerFilterFn(val) {
      this.ownerFilterValue = val;
      let tempOptions = [];
      if (val) {
        tempOptions = [...this.shipperOptions]
          .filter((v) => v.label
            .toLowerCase()
            .includes(this.ownerFilterValue.toLowerCase()));
        this.shipperFilterResult = tempOptions;
      } else {
        tempOptions = [...this.shipperOptions];
        this.shipperFilterResult = [];
      }
      this.shipperTotal = tempOptions.length;
      // eslint-disable-next-line max-len
      this.shipperFilterOptions = tempOptions.slice((this.shipperCPage - 1) * this.paginationSize, this.shipperCPage * this.paginationSize);
    },
    ownerFilterSelect(val) {
      // eslint-disable-next-line no-empty
      if (!val) {
        return '';
      }
      return val.replace(
        new RegExp(this.ownerFilterValue.toUpperCase(), 'g'),
        `<span style='color: #06b880'>${this.ownerFilterValue.toUpperCase()}</span>`,
      );
    },
    goodsFilterFn(val) {
      this.goodsFilterValue = val;
      let tempOptions = [];
      if (val) {
        tempOptions = [...this.goodsOptions]
          .filter((v) => v.label
            .toLowerCase()
            .includes(this.goodsFilterValue.toLowerCase()));
        this.goodsFilterResult = tempOptions;
      } else {
        tempOptions = [...this.goodsOptions];
        this.goodsFilterResult = [];
      }
      this.goodsTotal = tempOptions.length;
      // eslint-disable-next-line max-len
      this.goodsFilterOptions = tempOptions.slice((this.goodsCPage - 1) * this.paginationSize, this.goodsCPage * this.paginationSize);
    },
    goodsFilterSelect(val) {
      return val.replace(
        new RegExp(this.goodsFilterValue.toUpperCase(), 'g'),
        `<span style='color: #06b880'>${this.goodsFilterValue.toUpperCase()}</span>`,
      );
    },
    handleChangeShipper(currentPage) {
      this.shipperCPage = currentPage;
      const temp = this.shipperFilterResult.length ? this.shipperFilterResult : this.shipperOptions;
      // eslint-disable-next-line max-len
      this.shipperFilterOptions = temp.slice((this.shipperCPage - 1) * this.paginationSize, this.shipperCPage * this.paginationSize);
    },
    handleChangeGoods(currentPage) {
      this.goodsCPage = currentPage;
      const temp = this.goodsFilterResult.length ? this.goodsFilterResult : this.goodsOptions;
      // eslint-disable-next-line max-len
      this.goodsFilterOptions = temp.slice((this.goodsCPage - 1) * this.paginationSize, this.goodsCPage * this.paginationSize);
    },
    async fetchSearchConfig() {
      const data = {};
      data.code = DONGQI_CODE;
      data.warehouseSourceCode = DONGQI_WSCODE;
      const shipperData = await warehouseShipperQuery(data);
      if (!shipperData) {
        return;
      }
      this.shipperOptions = shipperData.map((item) => ({
        label: item.shipperName,
        value: item.customerId,
      }));
      this.shipperTotal = this.shipperOptions.length;
    },
    async fetchGoodsQuery() {
      const data = {
        shipperId: this.ownerInfo,
      };
      const params = {
        page: 1,
        size: 9999,
      };
      const goodsData = await warehouseGoodsQuery(data, params);
      this.goodsOptions = goodsData.records.map((item) => ({
        label: item.goodsName,
        value: item.id,
      }));
      this.goodsTotal = goodsData.total;
    },
    initSearchCondition() {
      const { query } = this.$route;
      const { cond, owner, goods } = query;
      if (cond) {
        this.activeConditions = parseInt(cond, 10);
      }
      if (owner) {
        this.ownerInfo = owner;
      }
      if (goods) {
        this.goodsInfo = goods;
      }
    },
    handleClearOwnerInfo() {
      this.handleChangeOwnerInfo();
      this.shipperCPage = 1;
    },
    handleChangeOwnerInfo() {
      this.goodsOptions = [];
      this.goodsInfo = ''; // 货品搜索条件
      this.setRouterQuery({
        owner: this.ownerInfo,
        goods: '',
      });
    },
    handleClearGoodsInfo() {
      this.handleChangeGoodsInfo();
      this.goodsCPage = 1;
    },
    handleChangeGoodsInfo() {
      this.setRouterQuery({
        goods: this.goodsInfo,
      });
    },
    ownerVisibleChange(state) {
      if (!state) {
        return;
      }
      if (this.shipperFilterResult.length !== 0) {
        this.shipperFilterResult = [];
        this.shipperTotal = this.shipperOptions.length;
        const temp = [...this.shipperOptions];
        // 如果已经有选中值, 那么初始化的时候需要定位
        if (this.ownerInfo) {
          const index = temp.findIndex((item) => item.value === this.ownerInfo);
          if (index !== -1) {
            this.shipperCPage = parseInt(index / this.paginationSize, 10) + 1;
          }
        }
      }
      this.ownerFilterValue = '';
      // eslint-disable-next-line max-len
      this.shipperFilterOptions = [...this.shipperOptions].slice((this.shipperCPage - 1) * this.paginationSize, this.shipperCPage * this.paginationSize);
    },
    goodsVisibleChange(state) {
      if (!state) {
        return;
      }
      if (this.goodsFilterResult.length !== 0) {
        this.goodsFilterResult = [];
        this.goodsTotal = this.goodsOptions.length;
        const temp = [...this.goodsOptions];
        // 如果已经有选中值, 那么初始化的时候需要定位
        if (this.goodsInfo) {
          const index = temp.findIndex((item) => item.value === this.goodsInfo);
          if (index !== -1) {
            this.goodsCPage = parseInt(index / this.paginationSize, 10) + 1;
          }
        }
      }
      this.goodsFilterValue = '';
      // eslint-disable-next-line max-len
      this.goodsFilterOptions = [...this.goodsOptions].slice((this.goodsCPage - 1) * this.paginationSize, this.goodsCPage * this.paginationSize);
    },
    async handleResetCondition() {
      if (!this.goodsInfo && !this.ownerInfo && !this.activeConditions) {
        return;
      }
      this.resetCondition();
      // 如果筛选值为空、则不触发清除
      this.$emit('resetSearchCondition');
    },
    resetCondition(params = {}) {
      const { query = {} } = params;

      let routeQuery = {
        ...query,
        goods: '',
        cond: '',
      };

      // 内嵌情况下, 不清空货品列表 与 货主信息
      if (!this.isIframe || this.iframeOfOTMS) {
        this.goodsOptions = [];
        this.ownerInfo = ''; // 货主信息
        routeQuery = {
          ...routeQuery,
          owner: '',
        };
      }
      this.activeConditions = '';
      this.goodsInfo = ''; // 货品搜索条件
      // 并清空路由上的信息 其他模块监听并读取页面上的路由 进行渲染
      this.setRouterQuery(routeQuery);
    },
    setRouterQuery(params) {
      const { path, query } = this.$route;
      // 每次搜索条件改变时, 清空当前页的筛选数据
      const clearQuery = {};
      switch (this.level) {
        case WAREHOUSE_TYPE.STOREHOUSE: clearQuery.store = ''; break;
        case WAREHOUSE_TYPE.SHELVES: clearQuery.shelves = ''; break;
        case WAREHOUSE_TYPE.LOCATION: clearQuery.loc = ''; break;
        default: break;
      }
      const newQuery = {
        ...query,
        ...clearQuery,
        ...params,
      };
      this.$router.replace({
        path,
        query: newQuery,
      });
    },
    selectCondition(id) {
      if (this.activeConditions !== id) {
        // 目前为单选 删除这里 为多选
        this.activeConditions = id;
        this.setRouterQuery({
          cond: this.activeConditions,
        });
      } else {
        this.activeConditions = null;
        this.setRouterQuery({
          cond: '',
        });
        // 如果筛选值全为空, 则重置数据
        if (!this.goodsInfo && !this.ownerInfo && !this.activeConditions) {
          // 如果筛选值为空、则不触发清除
          this.$emit('resetSearchCondition');
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .search-bar-root {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 0.8em;
    padding: 0.24em;
    background-color: #fff;
    border-radius: 0.16em;
    z-index: 100;
    margin-right: 0.3em;

    .search-condition {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .condition-item {
        display: flex;
        margin-right: 0.2em;
        width: 1.8em;
        height: 0.32em;

        .select-item {
          font-size: inherit;
          height: 0.32em;
        }
      }

      .condition-list {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: inherit;

        .btn {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 0.06em 0.12em;
          background: #f7f8fa;
          border-radius: 4px;
          font-size: inherit;
          cursor: pointer;
          border: 1px solid #f7f8fa;

          &.btn {
            margin-right: 0.08em;
          }

          span {
            display: block;
            font-size: inherit;
          }

          .icon {
            display: inline-block;
            margin-right: 0.08em;
            width: 0.16em;
            height: 0.16em;
            background-size: contain;
            background-repeat: no-repeat;
          }

          .title {
            font-size: 0.14em;
          }

          &:hover {
            color: #06b880;
          }
        }

        .active {
          color: #06b880;
          background-color: #f2fbf8;
          border: 1px solid #06b880;
        }

        .expire {
          .icon {
            background-image: url('../assets/search-bar/expire-normal.png');
          }
        }

        .import {
          .icon {
            background-image: url('../assets/search-bar/import-normal.png');
          }
        }

        .up {
          .icon {
            background-image: url('../assets/search-bar/up-normal.png');
          }
        }

        .down {
          .icon {
            background-image: url('../assets/search-bar/down-normal.png');
          }
        }

        .near {
          .icon {
            background-image: url('../assets/search-bar/near-normal.png');
          }
        }

        .expire.active {
          .icon {
            background-image: url('../assets/search-bar/expire-active.png');
          }
        }

        .import.active {
          .icon {
            background-image: url('../assets/search-bar/import-active.png');
          }
        }

        .up + .active {
          .icon {
            background-image: url('../assets/search-bar/up-active.png');
          }
        }

        .down.active {
          .icon {
            background-image: url('../assets/search-bar/down-active.png');
          }
        }

        .near.active {
          .icon {
            background-image: url('../assets/search-bar/near-active.png');
          }
        }
      }
    }

    .reset-btn {
      padding: 0.06em 0.16em;
      background: #fff;
      border-radius: 0.04em;
      border: 1px solid #dcdee0;
      cursor: pointer;

      .text {
        display: block;
        color: #323233;
        font-size: 0.14em;
      }

      &:hover {
        border: 1px solid #37c598;

        .text {
          color: #37c598;
        }
      }
    }
  }

  :deep(.el-input) {
    font-size: inherit;
    height: 0.32em;

    .el-input__suffix {
      height: 100%;

      .el-input__suffix-inner {
        display: block;
        height: 100%;

        .el-input__icon {
          display: block;
          line-height: 100%;
          height: 100%;
        }
      }
    }
  }

  :deep(.el-input__inner) {
    height: 100%;
    font-size: 0.14em;
  }
</style>
