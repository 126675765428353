<template>
  <div
    class="detail-comp-warp-root"
  >
    <div
      class="text-warp"
      :class="{ 'need-footer-btn': showNextBtn }"
    >
      <div
        v-if="!isDetailSearch || level === WAREHOUSE_TYPE.HOME"
        class="intro"
      >
        <span>{{ topTitleText }}</span>
      </div>
      <div
        v-else
        class="intro"
      >
        <condition-loc-spe-item
          v-if="level === WAREHOUSE_TYPE.LOCATION"
          :detail-item="headerConditionData"
        />
        <condition-spe-item
          v-else
          :detail-item="headerConditionData"
        />
      </div>
      <div
        v-if="dataChange"
        ref="detailWrap"
        class="list"
        :class="{
          'list-condition': isDetailSearch,
        }"
      >
        <component
          :is="detailComp"
          v-for="(item) in bodyConditionData"
          :key="item.key"
          :level="level"
          :detail-item="item"
          @handleShowCustomer="handleChangeCustomer"
        />
      </div>
      <div
        v-if="showNextBtn"
        class="footer"
      >
        <el-button
          class="footer-btn"
          type="primary"
          size="small"
          @click="handleNextStep"
        >
          {{ nextBtnText }}
        </el-button>
      </div>
    </div>
    <customer-list
      class="customer-list-wrap"
      :class="{'show-customer': showCustomer}"
      :shipper-list="shipperList"
      :select-warehouse="selectWarehouse"
      :level="level"
      @handleNextStep="handleNextStep"
      @handleChangeCustomer="handleChangeCustomer"
    />
  </div>
</template>

<script>
import DetailItem from './detail-item.vue';
import LocDetailItem from './loc-detail-item.vue';
import ConditionItem from './condition-detail-item.vue';
import ConditionSpeItem from './condition-spe-item.vue';
import ConditionLocSpeItem from './condition-loc-spe-item.vue';
import CustomerList from './customer-list.vue';
import { WAREHOUSE_TYPE, GOODS_STATUS_TYPE_MAP } from '../../const';

export default {
  name: 'DetailComp',
  components: {
    DetailItem,
    LocDetailItem,
    ConditionItem,
    ConditionSpeItem,
    ConditionLocSpeItem,
    CustomerList,
  },
  props: {
    level: {
      type: Number,
      default: WAREHOUSE_TYPE.HOME,
    },
    selectWarehouse: {
      type: Object,
      default: null,
    },
    selectWareArray: {
      type: Array,
      default: () => [],
    },
    // 包含条件筛选
    isConditionSearch: {
      type: Boolean,
      default: false,
    },
    hasConditionParams: {
      type: Boolean,
      default: false,
    },
    warehouseData: {
      type: Array,
      default: () => [],
    },
    isIframe: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['levelChange', 'changeLoadingState'],
  data() {
    return {
      dataChange: false,
      WAREHOUSE_TYPE,
      timer: null,
      showCustomer: false,
      warehouseCompData: [],
    };
  },
  computed: {
    /* 筛选后，单独选中进行展示 */
    isSpeConditionSearch() {
      const { owner, goods, cond } = this.$route.query;
      if (this.isIframe) {
        return !!(cond || goods);
      }
      return !!(cond || owner || goods);
    },
    isDetailSearch() {
      if (this.level === WAREHOUSE_TYPE.LOCATION) {
        const hasKey = this.warehouseCompData.findIndex((item) => item.key === 'headLine');
        return this.isConditionSearch && hasKey !== -1;
      }
      return this.isConditionSearch;
    },
    headerConditionData() {
      return this.warehouseCompData.length ? this.warehouseCompData[0] : [];
    },
    bodyConditionData() {
      if (this.warehouseCompData.length <= 0) {
        return [];
      }
      if (this.isDetailSearch) {
        return this.warehouseCompData.slice(1, this.warehouseCompData.length);
      }
      // 删除货主列表
      const temp = [];
      this.warehouseCompData.forEach((item) => {
        if (item.key !== 'shipperList') {
          temp.push(item);
        }
      });
      return temp;
    },
    shipperList() {
      const index = this.warehouseCompData.findIndex((item) => item.key === 'shipperList');
      if (index !== -1) {
        return this.warehouseCompData[index].content;
      }
      return [];
    },
    detailComp() {
      if (this.level === WAREHOUSE_TYPE.LOCATION) {
        return this.isDetailSearch ? ConditionLocSpeItem : LocDetailItem;
      }
      return this.isDetailSearch ? ConditionItem : DetailItem;
    },
    topTitleText() {
      let text = '';
      switch (this.level) {
        case WAREHOUSE_TYPE.HOME:
          text = '冷库概况';
          break;
        case WAREHOUSE_TYPE.STOREHOUSE:
          text = this.computedStoreText();
          break;
        case WAREHOUSE_TYPE.SHELVES:
          text = this.computedShelvesText();
          break;
        case WAREHOUSE_TYPE.LOCATION:
          text = this.computedLocationText();
          break;
        default:
          text = '冷库概况';
      }
      return text;
    },
    nextBtnText() {
      let text = '';
      switch (this.level) {
        case WAREHOUSE_TYPE.HOME: text = '仓库'; break;
        case WAREHOUSE_TYPE.STOREHOUSE: text = '货架'; break;
        case WAREHOUSE_TYPE.SHELVES: text = '库位'; break;
        default: text = '库区';
      }
      return `点击进入${text}`;
    },
    showNextBtn() {
      // 在首页
      const inHome = this.level === WAREHOUSE_TYPE.HOME;
      // 不在库位
      const outLocation = this.level !== WAREHOUSE_TYPE.LOCATION;
      // 未选中禁止态按钮
      const disabled = this.selectWarehouse && !this.selectWarehouse.isDisabled;
      // 搜索状态时, 禁止按钮无法进入下层
      const isSearch = disabled && this.isSpeConditionSearch;
      // eslint-disable-next-line max-len
      return inHome || (outLocation && (disabled || isSearch));
    },
  },
  watch: {
    level() {
      if (this.level === WAREHOUSE_TYPE.HOME) {
        this.showCustomer = false;
      }
    },
    warehouseData: {
      immediate: true,
      deep: true,
      handler() {
        this.loading = true;
        // 多次触发 合并为一次处理
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          this.initWarehouseData();
        }, 80);
      },
    },
    isConditionSearch() {
      // 回到顶点
      this.$refs.detailWrap.scrollTop = 0;
    },
  },
  methods: {
    initWarehouseData() {
      this.dataChange = false;
      this.$nextTick(() => {
        this.warehouseCompData = [];
        setTimeout(() => {
          this.warehouseCompData = [...this.warehouseData];
          this.dataChange = true;
          this.$emit('changeLoadingState', false);
        });
      });
    },
    handleNextStep() {
      // 进入货架、 传入货架Id
      this.$emit('levelChange', {
        level: this.level + 1,
      });
    },
    computedStoreText() {
      let text = '';
      if (this.selectWarehouse) {
        text = `${this.selectWarehouse.title}-概况`;
      } else {
        // 当只有一个筛选条件时
        const { owner, goods, cond } = this.$route.query;
        if (!owner && !goods && cond) {
          text = `${GOODS_STATUS_TYPE_MAP[parseInt(cond, 10)]}概况`;
        } else {
          text = '冷藏库和冷冻库概况';
        }
      }
      return text;
    },
    computedShelvesText() {
      let text = '';
      // eslint-disable-next-line max-len
      const activeStore = this.selectWareArray.find((item) => item.levelType === WAREHOUSE_TYPE.STOREHOUSE);
      let storeTitle = '';
      if (activeStore) {
        storeTitle = activeStore.title;
      }
      if (this.selectWarehouse) {
        text = `${storeTitle}-${this.selectWarehouse.title}概况`;
      } else {
        // 当只有一个筛选条件时
        const { owner, goods, cond } = this.$route.query;
        if (!owner && !goods && cond) {
          text = `${GOODS_STATUS_TYPE_MAP[parseInt(cond, 10)]}概况`;
        } else {
          text = `${storeTitle}-概况`;
        }
      }
      return text;
    },
    computedLocationText() {
      let text = '';
      // eslint-disable-next-line max-len
      const activeLocation = this.selectWareArray.find((item) => item.levelType === WAREHOUSE_TYPE.SHELVES);
      let storeTitle = '';
      if (activeLocation) {
        storeTitle = activeLocation.title;
      }
      // eslint-disable-next-line no-unused-expressions
      this.selectWarehouse ? (text = `${this.selectWarehouse.code}-库存概况`) : text = `${storeTitle}-库位概况`;
      return text;
    },
    handleChangeCustomer() {
      this.showCustomer = !this.showCustomer;
    },
  },
};
</script>

<style lang="scss" scoped>

.detail-comp-warp-root {
  position: relative;
  width: 5.4em;
  z-index: 100;

  .text-warp {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    border-radius: 0.16em;
    background-color: #fff;
    box-shadow: 0 0 0 0 #f0f0f0;
    height: 100%;
    z-index: 99;
    padding-bottom: 0.1em;

    .intro {
      padding: 0.15em;
      font-weight: 600;
      text-align: center;

      span {
        display: block;
        color: #323233;
        font-size: 0.2em;
      }
    }

    .list {
      padding: 0 0.24em;
      overflow-x: hidden;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 6px;
        height: 8px;
      }

      &::-webkit-scrollbar-track-piece {
        -webkit-border-radius: 0;
      }

      &::-webkit-scrollbar-track { background-color: #eee; } /* 滚动条的滑轨背景颜色 */
      &::-webkit-scrollbar-button { background-color: #eee; } /* 滑轨两头的监听按钮颜色 */
      &::-webkit-scrollbar-corner { background-color: black; } /* 横向滚动条和纵向滚动条相交处尖角的颜色 */

      &::-webkit-scrollbar-thumb {
        height: 30px;
        -webkit-border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.2);
      }

      &::-webkit-scrollbar-thumb:hover {
        height: 50px;
        -webkit-border-radius: 4px;
      }
    }

    .list-condition {
      padding: 0 0.24em;
    }

    .footer {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.24em 0.66em;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 0.88em;
      border-top: 1px solid #f0f0f0;

      .footer-btn {
        width: 100%;
        height: 100%;
      }
    }
  }

  .need-footer-btn {
    padding-bottom: 0.88em;
  }

  .customer-list-wrap {
    position: absolute;
    left: 5.4em;
    top: 0;
    z-index: 100;
    width: 100%;
    opacity: 0;
    transition: left 0.3s ease-in-out;
  }

  .show-customer {
    left: 0;
    opacity: 1;
  }
}
</style>
