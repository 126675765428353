<template>
  <div
    ref="warehouseRoot"
    class="warehouse-wrap-root"
  >
    <!-- 全局画布 根据类型进行切换 -->
    <home-canvas
      :is="wareHouse"
      v-if="wareHouseLevel == 1 || wareHouseLevel == null"
      :show-dialog="showDialog"
      :level="wareHouseLevel"
      :warehouse-config="warehouseConfigData"
      class="global-canvas-panel"
      @handleClickStore="handleClickWarehouse"
      @setChoiceKey="setChoiceKeyByLevel"
      @resetSearchCondition="resetSearchCondition"
    />
    <!-- 页面组件 -->
    <header-nav
      :level="wareHouseLevel"
      :is-iframe="isIframe"
      :iframe-tag="iframeTag"
      @levelChange="wareHouseLevelChange"
    />
    <div class="warehouse-container">
      <div class="left-bar">
        <search-bar
          ref="SearchBar"
          :level="wareHouseLevel"
          :is-iframe="isIframe"
          :iframe-tag="iframeTag"
          @resetSearchCondition="clearSelectWareHouse"
        />
        <position-bar
          ref="PositionBar"
          :level="wareHouseLevel"
          :is-iframe="isIframe"
          :position-data="positionData"
          @changeKey="changePositionKey"
          @updateChoiceArray="updateSelectWareArray"
        />
        <component
          :is="wareHouse"
          v-if="wareHouseLevel != null && wareHouseLevel != 1"
          :show-dialog="showDialog"
          :level="wareHouseLevel"
          :warehouse-config="warehouseConfigData"
          @handleClickStore="handleClickWarehouse"
          @setChoiceKey="setChoiceKeyByLevel"
          @resetSearchCondition="resetSearchCondition"
        />
      </div>
      <detail-comp
        v-loading="detailLoading"
        :level="wareHouseLevel"
        :warehouse-data="warehouseData"
        :select-warehouse="selectWareHouse"
        :select-ware-array="selectWareArray"
        :is-condition-search="isConditionSearch"
        :has-condition-params="hasConditionParams"
        :is-iframe="isIframe"
        @levelChange="wareHouseLevelChange"
        @changeLoadingState="changeLoadingState"
      />
    </div>
    <!-- <switch-bar
      :level="wareHouseLevel"
      :is-iframe="isIframe"
      @dialogState="handleChangeShowDialog"
    /> -->
  </div>
</template>

<script>
import {
  setToken,
  setLongToken,
  removeToken,
  removeLongToken,
} from '@/utils/auth';
import { ElMessage } from 'element-plus';
import HeaderNav from './components/header-nav.vue';
import SearchBar from './components/search-bar.vue';
import PositionBar from './components/position-bar/position-bar.vue';
import DetailComp from './components/detail-comp/index.vue';
import SwitchBar from './components/switch-bar.vue';
import homeCanvas from './canvas-panel/home.vue';
import storeCanvas from './canvas-panel/storeHouse.vue';
import shelvesCanvas from './canvas-panel/shelves.vue';
import locationDom from './canvas-panel/location.vue';

import {
  GOODS_STATUS_TYPE,
  WAREHOUSE_TYPE,
  STORE_GOODS_WARING,
  STORE_GOODS_STATE,
  IFRAME_HOUSE_CODE,
  IFRAME_TAG,
  DONGQI_CODE,
  DONGQI_WSCODE,
} from './const';
import { hasExistProperty } from './utils';
import {
  computedHomeConfig,
  computedStoreConfig,
  computedStorePlaneConfig,
  computedSearchConfig,
  computedSearchStoreConfig,
  computedShelvesPlaneConfig,
  computedLocationPlaneConfig,
  computedLocationConfig,
  computedSearchLocationConfig,
} from './config/data-factory';
import {
  getWarehouseHomePage,
  getZoneQuery,
  getOneZoneDetail,
  getZoneConfig,
  getShelvesQuery,
  shelvesDetailQuery,
  getLocationQuery,
  getLocationDetailQuery,
  getCustomerDetails,
} from '../api/warehouse';

const WareHouseOptions = [
  {
    comp: homeCanvas,
    level: WAREHOUSE_TYPE.HOME,
  },
  {
    comp: storeCanvas,
    level: WAREHOUSE_TYPE.STOREHOUSE,
  },
  {
    comp: shelvesCanvas,
    level: WAREHOUSE_TYPE.SHELVES,
  },
  {
    comp: locationDom,
    level: WAREHOUSE_TYPE.LOCATION,
  },
];

export default {
  name: 'Warehouse',
  components: {
    SearchBar,
    HeaderNav,
    PositionBar,
    DetailComp,
    SwitchBar,
    homeCanvas,
  },
  data() {
    return {
      detailLoading: false, // 详情页loading态
      showDialog: true, // 是否展示弹窗文字
      warehouseData: [], // 仓库信息
      warehouseConfigData: null, // canvas配置信息
      wareHouseLevel: null, // 默认在首页
      selectWareHouse: null, // 目前选中的对象
      selectWareArray: [], // 选中对象数组存储, 用于层级展示
      storeConfig: [], // 库区目前配置，标记哪些是可用的
      warehouse_code: null,
    };
  },
  computed: {
    // 判断是否是嵌套或外部引入场景
    isIframe() {
      // const { tag } = this.$route.query;
      // return !!tag;
      return false;
    },
    // 当前嵌入的tag
    iframeTag() {
      // const { tag = '' } = this.$route.query;
      return false;
    },
    // 是否是条件搜索、只包含货品状态时、不是条件搜索
    isConditionSearch() {
      const { owner, goods, cond } = this.$route.query;
      if (cond && (owner || goods)) {
        return true;
      }
      return !!(owner || goods);
    },
    // 是否包含条件参数 参数有 货主、货品、货品状态
    hasConditionParams() {
      const { owner, goods, cond } = this.$route.query;
      return !!(cond || owner || goods);
    },
    wareHouse() {
      const warehouse = WareHouseOptions.find((item) => item.level === this.wareHouseLevel);
      if (warehouse) {
        return warehouse.comp;
      }
      return WareHouseOptions[0].comp;
    },
    positionData() {
      return this.warehouseConfigData || [];
    },
  },
  watch: {
    '$route.query.level': {
      immediate: true,
      handler(val, oldVal) {
        if (val && oldVal) {
          // 当两值存在且变量值小于时 则是在后退, 重新设置url参数重新请求数据
          if (val < oldVal) {
            this.resetRouteQueryByLevel(val);
          }
        }
        this.$nextTick(() => {
          this.initWarehouseData();
        });
      },
    },
    '$route.query.goods': {
      handler(val) {
        if (!val) {
          return;
        }
        this.$nextTick(() => {
          this.selectWareHouse = null;
          this.reloadWarehouseData();
        });
      },
    },
    '$route.query.owner': {
      handler(val) {
        if (!val) {
          return;
        }
        this.$nextTick(() => {
          this.selectWareHouse = null;
          this.reloadWarehouseData();
        });
      },
    },
    '$route.query.cond': {
      handler(val) {
        if (!val) {
          return;
        }
        this.$nextTick(() => {
          this.selectWareHouse = null;
          this.reloadWarehouseData();
        });
      },
    },
    '$route.query.store': {
      handler() {
        // 切换标签 数据更改
        if (this.wareHouseLevel === WAREHOUSE_TYPE.SHELVES) {
          this.$nextTick(() => {
            this.initShelvesData();
            this.setRouterQuery({
              shelves: '',
            });
          });
        }
      },
    },
    '$route.query.shelves': {
      handler() {
        if (this.wareHouseLevel === WAREHOUSE_TYPE.LOCATION) {
          this.$nextTick(() => {
            this.initLocationData();
          });
        }
        // 只在shelves是清空状态时 重置shelves
        // if (this.wareHouseLevel === WAREHOUSE_TYPE.SHELVES && !oldShelves) {
        //   this.$nextTick(() => {
        //     this.reloadWarehouseData();
        //   });
        // }
      },
    },
    '$route.query': {
      handler(query, oldQuery) {
        // 比较两个对象，判断非筛选值是否变化, 如果变化, 则筛选没变
        const hasDiff = this.compareObjectDiff(query, oldQuery);
        // 筛选值变化 重新加载
        const {
          goods,
          owner,
          cond,
          store,
          shelves,
          loc,
        } = query;
        const {
          goods: oldGoods,
          owner: oldOwner,
          cond: oldCond,
          store: oldStore,
          shelves: oldShelves,
          loc: oldLoc,
        } = oldQuery;
        const conditionChange = (!goods && oldGoods) || (!owner && oldOwner) || (!cond && oldCond);
        const paramsChange = (!store && oldStore) || (!shelves && oldShelves) || (!loc && oldLoc);
        if (hasDiff) {
          // 处理筛选值选中 重置数据的情况， 筛选值清空, 且store、shelves、loc都有变化
          if (paramsChange && conditionChange) {
            this.$nextTick(() => {
              this.reloadWarehouseData();
            });
          }
          return;
        }
        if (conditionChange) {
          this.$nextTick(() => {
            this.reloadWarehouseData();
          });
        }
      },
    },
  },
  created() {
    this.initWareHouseTokenByIframe();
    this.initRouteState();
  },
  mounted() {
    // 页面必传参数warehouse_code, 初始化仓库code
    this.warehouse_code = this.$route.query.warehouse_code;
    // 内嵌情况
    if (this.isIframe && this.iframeTag !== IFRAME_TAG.OTMS) {
      this.fetchShipperDetail();
    }
    // 计算页面宽高、使用满屏展示
    this.setHtmlSizeByDeviceWidth();
    // 获取像素比
    window.addEventListener('resize', this.setHtmlSizeByDeviceWidth);
  },
  unmounted() {
    window.removeEventListener('resize', this.setHtmlSizeByDeviceWidth);
  },
  methods: {
    initWareHouseTokenByIframe() {
      if (this.isIframe) {
        const { tag, token } = this.$route.query;
        // 删除互斥token 保证每种嵌入类型 只有一种token
        if (tag === IFRAME_TAG.OMS) {
          removeLongToken();
          setToken(token);
        }
        if (tag === IFRAME_TAG.OTMS) {
          removeToken();
          setLongToken(token);
        }
      } else {
        removeLongToken();
      }
    },
    async fetchShipperDetail() {
      const { cId = '', tId = IFRAME_HOUSE_CODE } = this.$route.query;
      const data = {
        customerCodes: [cId],
        ownerTenantId: tId,
        needCustomerExt: true,
      };
      const customerList = await getCustomerDetails(data);
      if (customerList.length === 0) {
        ElMessage({
          type: 'warning',
          message: '该货主在此仓库下无货',
        });
        return;
      }
      const { customerId: shipperId } = customerList[0]?.customerExt;
      // 拿到后, 写入成默认值
      this.setRouterQuery({
        owner: shipperId,
      });
    },
    changeLoadingState(boolean) {
      this.detailLoading = boolean;
    },
    // 比较Route中 除了筛选栏参数是否有变化
    compareObjectDiff(obj1, obj2) {
      if (!obj2) {
        return true;
      }
      let hasDiff = false;
      const temp1 = {
        t1: obj1.store,
        t2: obj1.shelves,
        t3: obj1.loc,
      };
      const temp2 = {
        t1: obj2.store,
        t2: obj2.shelves,
        t3: obj2.loc,
      };

      Object.keys(temp1).forEach((key) => {
        if (temp1[key] !== temp2[key]) {
          hasDiff = true;
        }
      });
      return hasDiff;
    },
    // 初始化仓库数据
    async initWarehouseData() {
      switch (this.wareHouseLevel) {
        case WAREHOUSE_TYPE.HOME:
          await this.initHomeData();
          break;
        case WAREHOUSE_TYPE.STOREHOUSE:
          await this.initStoreHouse();
          break;
        case WAREHOUSE_TYPE.SHELVES:
          await this.initShelves();
          break;
        case WAREHOUSE_TYPE.LOCATION:
          await this.initLocationData();
          break;
        default: break;
      }
    },
    // 重新加载页面数据
    async reloadWarehouseData() {
      switch (this.wareHouseLevel) {
        case WAREHOUSE_TYPE.HOME: await this.initHomeData(); break;
        case WAREHOUSE_TYPE.STOREHOUSE:
          await this.initStoreHouseData();
          if (this.hasConditionParams && this.$route.query.store) {
            await this.initStoreDetailBySelect(this.$route.query.store);
          }
          await this.initSelectStoreHouse();
          break;
        case WAREHOUSE_TYPE.SHELVES:
          await this.initShelvesData();
          if (this.hasConditionParams && this.$route.query.shelves) {
            await this.initShelvesDetailBySelect(this.$route.query.shelves);
          }
          await this.initSelectStoreHouse();
          break;
        case WAREHOUSE_TYPE.LOCATION:
          await this.initLocationData();
          break;
        default: break;
      }
    },
    // 初始化首页数据
    async initHomeData() {
      // 详情页加载态
      this.detailLoading = true;
      const data = {};
      if (this.isIframe) {
        data.warehouseCode = IFRAME_HOUSE_CODE;
      }
      data.code = DONGQI_CODE;
      data.warehouseSourceCode = DONGQI_WSCODE;
      const warehouseData = await getWarehouseHomePage(data);
      this.warehouseData = computedHomeConfig(warehouseData);
    },
    // 初始化库区状态. 由于新增筛选状态, 所以需要分开展示
    async initStoreHouse() {
      const { store } = this.$route.query;
      await this.fetchAvailableStoreHouse();
      await this.initStoreHouseData();
      if (this.isConditionSearch && store) {
        await this.initStoreDetailBySelect(store);
      } else if (store) {
        await this.fetchStoreDataById(store);
      }
      await this.initSelectStoreHouse();
    },
    async initShelves() {
      const { shelves } = this.$route.query;
      await this.initShelvesData();
      if (this.isConditionSearch && shelves) {
        await this.initShelvesDetailBySelect(shelves);
      } else if (shelves) {
        await this.fetchShelvesDetailById(shelves);
      }
      await this.initSelectStoreHouse();
    },
    /* 根据筛选状态初始化右侧数据栏 */
    async initShelvesDetailBySelect(shelves) {
      const data = this.getSearchConditionParams();
      const { store } = this.$route.query;
      if (store) {
        data.zoneCode = store;
      }
      if (shelves) {
        data.rowCode = shelves;
      }
      if (this.isIframe) {
        data.warehouseCode = IFRAME_HOUSE_CODE;
      }
      data.code = DONGQI_CODE;
      data.warehouseSourceCode = DONGQI_WSCODE;
      // 详情页加载态
      this.detailLoading = true;
      const { shipperGoodsOverview } = await getLocationQuery(data);
      // 存在筛选条件, 则是在筛选
      if (hasExistProperty(data)) {
        if (shipperGoodsOverview) {
          // 展示筛选结果
          this.warehouseData = computedSearchLocationConfig(shipperGoodsOverview);
        } else {
          this.detailLoading = false;
        }
      } else {
        this.detailLoading = false;
      }
    },
    /* 根据筛选状态初始化右侧数据栏 */
    async initStoreDetailBySelect(store) {
      // 如果是筛选状态 且 包含选中状态, 则右侧获取下个库区内容
      const data = this.getSearchConditionParams();
      if (store) {
        data.zoneCode = store;
      }
      // 详情页加载态
      this.detailLoading = true;
      if (this.isIframe) {
        data.warehouseCode = IFRAME_HOUSE_CODE;
      }
      data.code = DONGQI_CODE;
      data.warehouseSourceCode = DONGQI_WSCODE;
      const { shipperGoodsOverview } = await getShelvesQuery(data);
      // 存在筛选条件, 则是在筛选
      if (hasExistProperty(data)) {
        if (shipperGoodsOverview) {
          // 展示筛选结果
          // eslint-disable-next-line max-len
          this.warehouseData = computedSearchStoreConfig(shipperGoodsOverview, WAREHOUSE_TYPE.SHELVES);
        } else {
          this.detailLoading = false;
        }
      } else {
        this.detailLoading = false;
      }
    },
    /* 写入路由会触发请求、所以需要把事件区分开 */
    setChoiceKeyByLevel(id) {
      const query = {};
      switch (this.wareHouseLevel) {
        case WAREHOUSE_TYPE.STOREHOUSE: query.store = id; query.shelves = ''; query.loc = ''; break;
        case WAREHOUSE_TYPE.SHELVES: query.shelves = id; query.loc = ''; break;
        case WAREHOUSE_TYPE.LOCATION: query.loc = id; break;
        default: break;
      }
      this.setRouterQuery(query);
    },
    async fetchShelvesDetailById(id) {
      const { store } = this.$route.query;
      if (!store) {
        return;
      }
      const data = {
        zoneCode: store,
        rowCode: id,
      };
      if (this.isIframe) {
        data.warehouseCode = IFRAME_HOUSE_CODE;
      }
      data.code = DONGQI_CODE;
      data.warehouseSourceCode = DONGQI_WSCODE;
      // 详情页加载态
      this.detailLoading = true;
      const oneShelvesData = await shelvesDetailQuery(data);
      this.warehouseData = computedSearchConfig(oneShelvesData);
    },
    async fetchLocationDetailById(id) {
      const data = {
        locationId: id,
      };
      if (this.isIframe) {
        data.warehouseCode = IFRAME_HOUSE_CODE;
      }
      data.code = DONGQI_CODE;
      data.warehouseSourceCode = DONGQI_WSCODE;
      // 详情页加载态
      this.detailLoading = true;
      const oneLocationData = await getLocationDetailQuery(data);
      this.warehouseData = computedLocationConfig(oneLocationData);
    },
    // 根据id获取单个详情 库区、货架、 库位
    fetchClickDetail(id) {
      const needDetail = this.isConditionSearch && id;
      switch (this.wareHouseLevel) {
        case WAREHOUSE_TYPE.STOREHOUSE:
          // eslint-disable-next-line no-unused-expressions
          needDetail ? this.initStoreDetailBySelect(id) : this.fetchStoreDataById(id);
          break;
        case WAREHOUSE_TYPE.SHELVES:
          // eslint-disable-next-line no-unused-expressions
          needDetail ? this.initShelvesDetailBySelect(id) : this.fetchShelvesDetailById(id);
          break;
        case WAREHOUSE_TYPE.LOCATION: this.fetchLocationDetailById(id); break;
        default: break;
      }
    },
    resetClickDetail() {
      switch (this.wareHouseLevel) {
        case WAREHOUSE_TYPE.STOREHOUSE: this.initStoreHouseData(); break;
        case WAREHOUSE_TYPE.SHELVES: this.initShelvesData(true); break;
        case WAREHOUSE_TYPE.LOCATION: this.initLocationData(); break;
        default: break;
      }
    },
    // 页面点击 区分点击类型
    handleClickWarehouse(info) {
      this.selectWareHouse = info;
      if (info) {
        this.fetchClickDetail(info.id);
      } else {
        // 重置数据 交互逻辑变更 不需要再重置数据
        this.resetClickDetail();
      }
    },
    async fetchAvailableStoreHouse() {
      const data = {};
      if (this.isIframe) {
        data.warehouseCode = IFRAME_HOUSE_CODE;
      }
      data.code = DONGQI_CODE;
      data.warehouseSourceCode = DONGQI_WSCODE;
      this.storeConfig = await getZoneConfig(data);
    },
    getSearchConditionParams() {
      // 获取搜索条件
      const data = {};
      const {
        goods,
        owner,
        cond,
      } = this.$route.query;
      if (owner) {
        data.shipperId = owner;
      }
      if (goods) {
        data.goodsId = goods;
      }
      if (cond) {
        switch (parseInt(cond, 10)) {
          case GOODS_STATUS_TYPE.NEAR: data.isStopWarningStock = true; break;
          case GOODS_STATUS_TYPE.EXPIRE: data.aboutToExpire = true; break;
          case GOODS_STATUS_TYPE.IMPORT: data.isImport = true; break;
          case GOODS_STATUS_TYPE.UP: data.upperShelfOfCurrentDay = true; break;
          case GOODS_STATUS_TYPE.DOWN: data.offShelfOfCurrentDay = true; break;
          default: break;
        }
      }
      return data;
    },
    // 初始化库区数据
    async initStoreHouseData() {
      const data = this.getSearchConditionParams();
      // 详情页加载态
      this.detailLoading = true;
      if (this.isIframe) {
        data.warehouseCode = IFRAME_HOUSE_CODE;
      }
      data.code = DONGQI_CODE;
      data.warehouseSourceCode = DONGQI_WSCODE;
      const { overview, shipperGoodsOverview, zones } = await getZoneQuery(data);
      const { store } = this.$route.query;
      const storeNeedDetail = this.isConditionSearch && store;
      if (store) {
        data.zoneCode = store;
      }
      // 存在筛选条件, 则是在筛选
      if (hasExistProperty(data)) {
        if (shipperGoodsOverview) {
          // 展示筛选结果
          if (!storeNeedDetail) {
            // eslint-disable-next-line max-len
            this.warehouseData = computedSearchStoreConfig(shipperGoodsOverview, WAREHOUSE_TYPE.STOREHOUSE);
          }
        } else {
          // 当单个进行条件筛选时、则展示所有信息
          // eslint-disable-next-line no-lonely-if
          if (!storeNeedDetail) {
            this.clearSelectWareHouse();
            this.warehouseData = computedStoreConfig(overview);
          }
        }
        // 如果是嵌入页面且筛选条件只有一个owner, 则不显示激活状态
        // eslint-disable-next-line max-len
        const STATE = this.isIframeShowState() ? STORE_GOODS_WARING.NORMAL : STORE_GOODS_WARING.ACTIVE;
        // eslint-disable-next-line max-len
        this.warehouseConfigData = computedStorePlaneConfig(zones, this.storeConfig, STATE);
      } else {
        // 提取出综合库区信息 给详情展示
        this.warehouseData = computedStoreConfig(overview);
        // 提取出多个库区信息 给canvas
        this.warehouseConfigData = computedStorePlaneConfig(zones, this.storeConfig);
      }
    },
    // 页面嵌入时, 选中状态初始化的隔离
    isIframeShowState() {
      const { owner, goods, cond } = this.$route.query;
      return owner && this.isIframe && !goods && !cond;
    },
    // 初始化选中态 与选中的数据
    initSelectStoreHouse() {
      // 默认筛选状态下 不需要初始化选中态
      if (!this.warehouseConfigData) {
        return;
      }
      const { store, shelves } = this.$route.query;
      const targetKey = this.wareHouseLevel === WAREHOUSE_TYPE.STOREHOUSE ? store : shelves;
      const index = this.warehouseConfigData.findIndex((item) => item.id === targetKey);
      if (index !== -1) {
        this.warehouseConfigData[index].state = STORE_GOODS_STATE.ACTIVE;
        this.selectWareHouse = this.warehouseConfigData[index];
      }
    },

    // 根据点击结果加载数据
    async fetchStoreDataById(id) {
      const data = {
        zoneCode: id,
      };
      if (this.isIframe) {
        data.warehouseCode = IFRAME_HOUSE_CODE;
      }
      data.code = DONGQI_CODE;
      data.warehouseSourceCode = DONGQI_WSCODE;
      // 详情页加载态
      this.detailLoading = true;
      const oneZoneData = await getOneZoneDetail(data);
      this.warehouseData = computedSearchConfig(oneZoneData);
    },
    // 清空搜索条件, 重置选中状态
    resetSearchCondition(params) {
      this.showDialog = true;
      this.$refs.SearchBar.resetCondition(params);
      this.$refs.PositionBar.resetPositionWaring();
    },
    clearSelectWareHouse() {
      this.showDialog = true;
      this.selectWareHouse = null;
      this.$refs.PositionBar.resetPositionWaring();
    },
    // 初始化货架数据 默认不是reset
    async initShelvesData(reset = false) {
      const data = this.getSearchConditionParams();
      const { store, shelves } = this.$route.query;
      // 当store不存在时 不加载
      if (!store) {
        return;
      }
      if (store) {
        data.zoneCode = store;
      }
      if (shelves && !reset) {
        data.rowCode = shelves;
      }
      if (this.isIframe) {
        data.warehouseCode = IFRAME_HOUSE_CODE;
      }
      data.code = DONGQI_CODE;
      data.warehouseSourceCode = DONGQI_WSCODE;
      const shelvesNeedDetail = this.hasConditionParams && shelves && !reset;
      // 详情页加载态
      this.detailLoading = true;
      const { overview, shipperGoodsOverview, rows } = await getShelvesQuery(data);
      if (!shipperGoodsOverview && !overview) {
        this.warehouseData = [];
        this.warehouseConfigData = [];
        return;
      }
      // 存在筛选条件, 则是在筛选
      if (hasExistProperty(data)) {
        if (shipperGoodsOverview) {
          // 展示筛选结果
          if (!shelvesNeedDetail) {
            // eslint-disable-next-line max-len
            this.warehouseData = computedSearchStoreConfig(shipperGoodsOverview, WAREHOUSE_TYPE.SHELVES);
          }
        } else if (!shelvesNeedDetail) {
          // 当单个进行条件筛选时、则展示所有信息
          this.clearSelectWareHouse();
          // 提取出综合库区信息 给详情展示
          this.warehouseData = computedStoreConfig(overview);
        }
        // eslint-disable-next-line max-len
        const STATE = this.isIframeShowState() ? STORE_GOODS_WARING.NORMAL : STORE_GOODS_WARING.ACTIVE;
        this.warehouseConfigData = computedShelvesPlaneConfig(rows, STATE);
      } else {
        // 提取出综合库区信息 给详情展示
        this.warehouseData = computedStoreConfig(overview);
        // 提取出多个库区信息 给canvas
        this.warehouseConfigData = computedShelvesPlaneConfig(rows);
      }
    },
    // 初始化库位数据
    async initLocationData() {
      const data = this.getSearchConditionParams();
      const { store, shelves } = this.$route.query;
      if (store) {
        data.zoneCode = store;
      }
      if (shelves) {
        data.rowCode = shelves;
      }
      if (!store || !shelves) {
        return;
      }
      if (this.isIframe) {
        data.warehouseCode = IFRAME_HOUSE_CODE;
      }
      data.code = DONGQI_CODE;
      data.warehouseSourceCode = DONGQI_WSCODE;
      // 详情页加载态
      this.detailLoading = true;
      const { overview, shipperGoodsOverview, storehouses } = await getLocationQuery(data);
      if (!shipperGoodsOverview && !overview) {
        this.warehouseData = [];
        this.warehouseConfigData = [];
        return;
      }
      // 存在筛选条件, 则是在筛选
      if (hasExistProperty(data)) {
        if (shipperGoodsOverview) {
          // 展示筛选结果
          this.warehouseData = computedSearchLocationConfig(shipperGoodsOverview);
        } else {
          this.warehouseData = computedStoreConfig(overview);
        }
        // 提取出综合库区信息 给详情展示
        // eslint-disable-next-line max-len
        const STATE = this.isIframeShowState() ? STORE_GOODS_WARING.NORMAL : STORE_GOODS_WARING.ACTIVE;
        this.warehouseConfigData = computedLocationPlaneConfig(storehouses, STATE);
      } else {
        this.warehouseData = computedStoreConfig(overview);
        this.warehouseConfigData = computedLocationPlaneConfig(storehouses);
      }
    },
    initRouteState() {
      const { level } = this.$route.query;
      this.wareHouseLevel = parseInt(level, 10) || WAREHOUSE_TYPE.HOME;
    },
    // 改变key值 记录层级页码状态
    changePositionKey(params) {
      const { key, shelves = '' } = params;

      const query = {};
      switch (this.wareHouseLevel) {
        case WAREHOUSE_TYPE.STOREHOUSE: query.store = key; query.shelves = shelves; break;
        case WAREHOUSE_TYPE.SHELVES: query.store = key; query.shelves = shelves; break;
        case WAREHOUSE_TYPE.LOCATION: query.shelves = key; break;
        default: query.shelves = key; break;
      }

      this.setRouterQuery({
        level: this.wareHouseLevel,
        ...query,
      });
    },

    setRouterQuery(params) {
      const { path, query } = this.$route;
      const newQuery = {
        ...query,
        ...params,
      };
      this.$router.replace({
        path,
        query: newQuery,
      });
    },
    setHtmlSizeByDeviceWidth() {
      // 加载时直接写入一次 19.2 因为设计稿为1920 大小。 1920 / 100 = 19.2
      const fontSize = document.documentElement.clientWidth / 19.2;

      this.$refs.warehouseRoot.style.fontSize = `${fontSize > 54 ? fontSize : 54}px`;
      this.$refs.warehouseRoot.style.height = `${window.innerHeight}px`;
    },
    updateSelectWareArray(arr) {
      // 如果已经存在，则覆盖。 不存在则写入
      const index = this.selectWareArray.findIndex((config) => config.type === arr.type);
      if (index === -1) {
        this.selectWareArray.push(arr);
        return;
      }
      this.selectWareArray.splice(index, 1, arr);
    },
    resetRouteQueryByLevel(level) {
      const query = {};
      switch (parseInt(level, 10)) {
        case WAREHOUSE_TYPE.HOME:
          query.owner = '';
          query.goods = '';
          query.cond = '';
          query.store = '';
          query.shelves = '';
          query.loc = '';
          break;
        case WAREHOUSE_TYPE.STOREHOUSE: query.shelves = ''; query.loc = ''; break;
        case WAREHOUSE_TYPE.SHELVES: query.loc = ''; break;
        default: break;
      }
      this.setRouterQuery(query);
    },
    wareHouseLevelChange(query) {
      // 进入下一页时, 如果存在已选择项，则存储该项目
      if (this.selectWareHouse) {
        this.updateSelectWareArray({
          levelType: this.wareHouseLevel,
          ...this.selectWareHouse,
        });
      }
      const { level } = query;
      // 路由变化时, 清空上次的选中态
      this.clearSelectWareHouse();
      this.wareHouseLevel = level;
      this.setRouterQuery({
        ...query,
      });
    },
    handleChangeShowDialog(state) {
      this.showDialog = state;
    },
  },
};

</script>
<style lang="scss" scoped>
@import './styles/global.scss';

:deep html, body{
  overflow: hidden !important;
}

.warehouse-wrap-root {
  position: relative;
  width: 100%;
  height: auto;
  background-color:#f4f4f4;
  z-index: 99;
  overflow: hidden;
  .global-canvas-panel {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .warehouse-container {
    display: flex;
    justify-content: center;
    padding: 0.32em 0.54em;
    height: calc(100% - 0.88em);
  }
}
.left-bar{
  width: 12.2em;
  margin-right: 0.32em;
  position: relative;
}
</style>
