/**
 * author: He Ding Xiong
 * date: 2021-12-13
 * des: 存放当前所有仓库、库位等页面的配置文件
 * */

/* canvas 画布比例 */
export const CANVAS_MULTIPLE = 2.2; // 画布比例控制
export const CANVAS_WIDTH = 1240 * CANVAS_MULTIPLE;
export const CANVAS_HEIGHT = 704 * CANVAS_MULTIPLE;

// 固定双手仓编码配置
export const IFRAME_HOUSE_CODE = process.env.VUE_APP_ENV === 'prod' ? 'T10000138' : 'T1020007044';

export const DONGQI_CODE = 'code-T10000037003';
export const DONGQI_WSCODE = 'T10000037003';

/* 弹窗矩形参数 */
export const RECTANGLE_X = 160 * CANVAS_MULTIPLE;
export const RECTANGLE_Y = 116 * CANVAS_MULTIPLE;
export const RECTANGLE_R = 10 * CANVAS_MULTIPLE;

/* 弹框椭圆参数 */
export const ELLIPSE_OFFSET_X = 80 * CANVAS_MULTIPLE; // 基于矩形框 x 轴距离
export const ELLIPSE_OFFSET_Y = 120 * CANVAS_MULTIPLE; // 基于矩形框 y 轴距离
export const DIALOG_OFFSET_Y = 142 * CANVAS_MULTIPLE; // 弹窗额外的高度
export const ELLIPSE_OFFSET_RX = 20 * CANVAS_MULTIPLE; // 底部椭圆 x 半径
export const ELLIPSE_OFFSET_RY = 10 * CANVAS_MULTIPLE; // 底部椭圆 y 半径

/* 阴影、偏移等 参数 */
export const RECTANGLE_SHADOW_X = 2 * CANVAS_MULTIPLE;
export const RECTANGLE_SHADOW_Y = 2 * CANVAS_MULTIPLE;
export const RECTANGLE_SHADOW_BLUR = 4 * CANVAS_MULTIPLE;

/* 标题字体大小 */
export const RECTANGLE_TITLE_X = 12 * CANVAS_MULTIPLE;
export const RECTANGLE_TITLE_Y = 12 * CANVAS_MULTIPLE;
export const RECTANGLE_OFFSET_Y = 40 * CANVAS_MULTIPLE;
export const RECTANGLE_OFFSET_NUM_Y = 24 * CANVAS_MULTIPLE;

/* 画板重绘控制 */
export const CANVAS_DEBOUNCE_TIME = 60; // 合并60毫秒内所有的重绘一次

export const STORE_PLANE_ORDER = ['zoneX', 'zoneY']; // 仓位固定顺序

export const IFRAME_TAG = {
  OMS: 'oms', // OMS系统嵌入
  OTMS: 'otms', // OTMS系统嵌入
};
/**
 * 仓库类型 以及层级
 * @type {{LOCATION: number, STOREHOUSE: number, SHELVES: number, HOME: number}}
 */
export const WAREHOUSE_TYPE = {
  HOME: 1, // 主页
  STOREHOUSE: 2, // 库区
  SHELVES: 3, // 货架
  LOCATION: 4, // 库位
};

/**
 * 枚举文案
 * @type {{[p: string]: string, [p: number]: string}}
 */
export const WAREHOUSE_TYPE_MAP = {
  [WAREHOUSE_TYPE.HOME]: '主页',
  [WAREHOUSE_TYPE.STOREHOUSE]: '库区',
  [WAREHOUSE_TYPE.SHELVES]: '货架',
  [WAREHOUSE_TYPE.LOCATION]: '库位',
};

/**
 * 货物类型 四种
 * @type {{IMPORT: number, DOWN: number, EXPIRE: number, UP: number}}
 */
export const GOODS_STATUS_TYPE = {
  EXPIRE: 1, // 过期品库存
  IMPORT: 2, // 导入
  UP: 3, // 当日上架
  DOWN: 4, // 当日下架
  NEAR: 5, // 临期
};

/**
 * 临期品map
 * @type {{[p: string]: string, [p: number]: string}}
 */
export const GOODS_STATUS_TYPE_MAP = {
  [GOODS_STATUS_TYPE.EXPIRE]: '过期品库存',
  [GOODS_STATUS_TYPE.IMPORT]: '进口货品',
  [GOODS_STATUS_TYPE.UP]: '当日上架',
  [GOODS_STATUS_TYPE.DOWN]: '当日下架',
  [GOODS_STATUS_TYPE.NEAR]: '临期品库存',
};

export const STORE_GOODS_TYPE = {
  NORMAL: 1, // 平时状态
  HIDDEN: 2, // 隐藏状态
};
/**
 * 库区、库位、货物的三种状态
 * @type {{ACTIVE: number, WARING: number, NORMAL: number}}
 */
export const STORE_GOODS_STATE = {
  NORMAL: 1, // 正常状态
  ACTIVE: 2, // 激活状态
};

/**
 * 警告状态
 * @type {{ACTIVE: number, NORMAL: number}}
 */
export const STORE_GOODS_WARING = {
  NORMAL: 1, // 正常状态
  ACTIVE: 2, // 激活状态
};

/**
 * 错误状态
 * @type {{ACTIVE: number, NORMAL: number}}
 */
export const STORE_GOODS_ERROR = {
  NORMAL: 1, // 正常状态
  ACTIVE: 2, // 激活状态
};

/**
 * 通常圆点颜色
 */
export const ELLIPSE_COLOR = {
  [STORE_GOODS_STATE.NORMAL]: {
    minColor: 'rgba(150, 151, 153, 1)',
    maxColor: 'rgba(150, 151, 153, 0.1)',
  },
  [STORE_GOODS_STATE.ACTIVE]: {
    minColor: 'rgba(6, 184, 128, 1)',
    maxColor: 'rgba(6, 184, 128, 0.1)',
  },
};

/**
 * 筛选圆点颜色
 */
export const ELLIPSE_WARING_COLOR = {
  [STORE_GOODS_WARING.NORMAL]: {
    minColor: 'rgba(150, 151, 153, 1)',
    maxColor: 'rgba(150, 151, 153, 0.1)',
  },
  [STORE_GOODS_WARING.ACTIVE]: {
    minColor: 'rgba(255, 128, 48, 1)',
    maxColor: 'rgba(255, 128, 48, 0.1)',
  },
};

/**
 * 筛选圆点颜色
 */
export const ELLIPSE_SELECT_COLOR = {
  [STORE_GOODS_WARING.NORMAL]: {
    minColor: 'rgba(150, 151, 153, 1)',
    maxColor: 'rgba(150, 151, 153, 0.1)',
  },
  [STORE_GOODS_WARING.ACTIVE]: {
    minColor: 'rgba(245, 34, 45, 1)',
    maxColor: 'rgba(245, 34, 45, 0.1)',
  },
};

/**
 * 筛选圆点颜色
 */
export const ELLIPSE_NEW_COLOR = {
  NORMAL: {
    minColor: 'rgba(150, 151, 153, 1)',
    maxColor: 'rgba(150, 151, 153, 0.1)',
  },
  SELECT: {
    minColor: 'rgba(6, 184, 128, 1)',
    maxColor: 'rgba(6, 184, 128, 0.1)',
  },
  NEAR: {
    minColor: 'rgba(255, 128, 48, 1)',
    maxColor: 'rgba(255, 128, 48, 0.1)',
  },
  WARING: {
    minColor: 'rgba(245, 34, 45, 1)',
    maxColor: 'rgba(245, 34, 45, 0.1)',
  },
};

export const TITLE_NORMAL_COLOR = 'rgba(50, 50, 51, 1)'; // 通常状态的标题颜色
export const TITLE_ACTIVE_COLOR = 'rgba(6, 184, 128, 1)'; // 激活状态的标题颜色
export const TITLE_WARING_COLOR = 'rgba(50, 50, 51, 1)'; // 筛选状态的标题颜色
export const TITLE_SELECT_COLOR = 'rgba(245, 34, 45, 1)'; // 筛选后选中的状态的标题颜色

export const CONTENT_NORMAL_COLOR = 'rgba(100, 101, 102, 1)'; // 通常状态的label颜色
export const CONTENT_ACTIVE_COLOR = 'rgba(85, 214, 173, 1)'; // 激活状态的label颜色
export const CONTENT_WARING_COLOR = 'rgba(100, 101, 102, 1)'; // 筛选状态的label颜色
export const CONTENT_SELECT_COLOR = 'rgba(255, 108, 115, 1)'; // 筛选后选中的状态的标题颜色

export const NUM_NORMAL_COLOR = 'rgba(50, 50, 51, 1)'; // 通常状态的value颜色
export const NUM_ACTIVE_COLOR = 'rgba(6, 184, 128, 1)'; // 激活状态的value颜色
export const NUM_WARING_COLOR = 'rgba(50, 50, 51, 1)'; // 筛选状态的value颜色
export const NUM_SELECT_COLOR = 'rgba(245, 34, 45, 1)'; // 筛选后选中的状态的value颜色
