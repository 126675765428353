<template>
  <div class="store-box">
    <div class="store-span-box">
      <canvas ref="storehouse" class="storeHouse-panel" />
    </div>
  </div>
</template>

<script>
import Warehouse from '../objects';
import storeHouse from '../assets/panel/storeHouse2.png';
import {
  WAREHOUSE_TYPE,
  RECTANGLE_X,
  RECTANGLE_Y,
  ELLIPSE_OFFSET_X,
  ELLIPSE_OFFSET_Y,
  ELLIPSE_OFFSET_RX,
  ELLIPSE_OFFSET_RY,
  STORE_GOODS_STATE,
  STORE_GOODS_TYPE,
  STORE_GOODS_WARING,
} from '../const';
import { judgeInBoundary, getRealCoordinate } from '../utils';

export default {
  name: 'StoreHouse',
  props: {
    level: {
      type: Number,
      default: WAREHOUSE_TYPE.STOREHOUSE,
    },
    showDialog: {
      type: Boolean,
      default: true,
    },
    warehouseConfig: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['handleClickStore', 'setChoiceKey', 'resetSearchCondition'],
  data() {
    return {
      selectInfo: null, // 已选择的点
      warehouse: null,
      storeData: [],
    };
  },
  watch: {
    warehouseConfig: {
      deep: true,
      handler() {
        if (!this.warehouse) {
          return;
        }
        this.storeData = [...this.warehouseConfig];
        if (!this.showDialog) {
          this.storeData.forEach((item, index) => {
            // eslint-disable-next-line max-len
            this.storeData[index].type = this.showDialog ? STORE_GOODS_TYPE.NORMAL : STORE_GOODS_TYPE.HIDDEN;
          });
        }

        this.refreshCanvasBox(this.storeData);
      },
    },
    showDialog() {
      this.resetStoreDataType();
    },
  },
  mounted() {
    this.warehouse = new Warehouse(this.$refs.storehouse, {
      bgImg: storeHouse,
      level: this.level,
      dialogConfigData: this.storeData,
    });

    // 点击事件监听
    this.$refs.storehouse.addEventListener('click', this.listenerStoreHouseClick);
    this.$refs.storehouse.addEventListener('mousemove', this.listenerStoreHouseHover);
  },
  methods: {
    refreshCanvasBox(data) {
      this.warehouse.refreshRectangleDialogBox(data);
    },
    // 1.使用顶点数组，计算得出 原点数组 (向后匹配, 存在遮盖问题), 已最后匹配结果为准！！！
    // 2.判断X、Y 是否在集合内部
    computedClickConfig(x, y, isHover = false) {
      let choiceStore = null;
      // console.log(x, y, 100, 50, RECTANGLE_X, RECTANGLE_Y);
      // console.log(judgeInBoundary(x, y, 100, 50, RECTANGLE_X, RECTANGLE_Y));
      this.storeData.forEach((store) => {
        // 是否是矩形选中 isRectangleIn,
        // eslint-disable-next-line max-len
        const isRectangleIn = !isHover && (this.showDialog || store.waring === STORE_GOODS_WARING.ACTIVE)
          ? judgeInBoundary(x, y, store.x, store.y, RECTANGLE_X, RECTANGLE_Y) : false;
        // 椭圆中心对齐，所以x、y要减去半径
        const isEllipseIn = judgeInBoundary(x, y,
          store.x + ELLIPSE_OFFSET_X - ELLIPSE_OFFSET_RX,
          store.y + ELLIPSE_OFFSET_Y - ELLIPSE_OFFSET_RY,
          ELLIPSE_OFFSET_RX * 2,
          ELLIPSE_OFFSET_RY * 2);

        // 只存放最后一个匹配内容
        if (isRectangleIn || isEllipseIn) {
          choiceStore = store;
        }
      });
      return choiceStore;
    },
    // 重置所有点
    resetStoreDataState(key, value) {
      this.storeData.forEach((item, index) => {
        this.storeData[index][key] = value;
      });
    },
    // 重置隐藏状态 并重绘
    resetStoreDataType() {
      if (!this.warehouse) {
        return;
      }
      this.storeData.forEach((item, index) => {
        // eslint-disable-next-line max-len
        this.storeData[index].type = this.showDialog ? STORE_GOODS_TYPE.NORMAL : STORE_GOODS_TYPE.HIDDEN;
      });
      this.refreshCanvasBox(this.storeData);
    },
    setSelectInfo(val) {
      this.selectInfo = val;
      this.$emit('handleClickStore', this.selectInfo);
    },
    setChoiceKeyInfo(key) {
      this.$emit('setChoiceKey', key);
    },
    listenerStoreHouseClick(event) {
      const { X, Y } = getRealCoordinate(event, '.storeHouse-panel');
      const config = this.computedClickConfig(X, Y);
      if (!config) {
        return;
      }
      const index = this.storeData.findIndex((item) => item.id === config.id);

      if (index !== -1) {
        const equalActive = this.storeData[index].state === STORE_GOODS_STATE.ACTIVE;
        // 记录点击点
        this.setChoiceKeyInfo(equalActive ? '' : this.storeData[index].id);
        this.setSelectInfo(equalActive ? null : this.storeData[index]);
        this.resetStoreDataState('state', STORE_GOODS_STATE.NORMAL);
        // 如果本身就是激活状态, 则取消选择
        if (!equalActive) {
          // 匹配到点后, 先重置所有state 并设置匹配的state
          this.storeData[index].state = STORE_GOODS_STATE.ACTIVE;
        }
        // 改变数据状态, 并重新渲染
        this.refreshCanvasBox(this.storeData);
      }
    },
    listenerStoreHouseHover(event) {
      const { X, Y } = getRealCoordinate(event, '.storeHouse-panel');
      const config = this.computedClickConfig(X, Y, true);

      // 重置所有hover状态
      this.resetStoreDataState('isHover', false);
      // 如果已经是hover态, 则不触发
      if (config?.isHover) {
        return;
      }
      if (!config) {
        return;
      }
      const index = this.storeData.findIndex((item) => item.id === config.id);
      if (index !== -1) {
        // 匹配到点后, 先重置所有state 并设置匹配的state
        this.storeData[index].isHover = true;
        // 改变数据状态, 并重新渲染
        this.refreshCanvasBox(this.storeData);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.storeHouse-panel {
  font-family: "DinBold", serif;
}
$width: 12.2em;
$height: $width * 0.568;
.store-box{
  margin-top: 0.3em;
  height: 100%;
  background: #FFF;
}
.store-span-box{
  width: $width;
  height: $height;
  .storeHouse-panel{
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 999;
  }
}
</style>
