/**
 * Author: He DingXiong
 * Date: 2021-21-24
 * Des: data-config 配置当前各层级侧边栏数据展示结构
 * */

/**
 * 首页数据结构
 */
export const HomeDataConfig = [{
  title: '货主数量(个)',
  key: 'shipperNum',
  detail: '',
  content: [{
    key: 'lessThanCarloadShipperNum',
    label: '零担(个)',
    value: '',
  }, {
    key: 'contractShipperNum',
    label: '合约(个)',
    value: '',
  }],
}, {
  title: '货品库存数量(箱)',
  key: 'goodsStockList',
  content: [{
    key: 'storage',
    label: '冷藏',
    value: '',
  }, {
    key: 'normal',
    label: '常温',
    value: '',
  }, {
    key: 'freeze',
    label: '冷冻',
    value: '',
  }, {
    key: 'constant',
    label: '恒温',
    value: '',
  }],
}, {
  title: '仓库收入情况(元)',
  key: 'income',
  content: [{
    key: 'todayReceivableAmount',
    label: '今日应收金额',
    value: '',
  }, {
    key: 'currentMonthReceivableAmount',
    label: '当月应收金额',
    value: '',
  }, {
    key: 'currentMonthStoreIncome',
    label: '月度存储收入',
    value: '',
  }, {
    key: 'currentMonthOperateIncome',
    label: '月度操作收入',
    value: '',
  }],
}, {
  title: '库位使用率/占用板位',
  key: 'location',
  content: [{
    key: 'storage',
    label: '冷藏',
    value: '',
  }, {
    key: 'normal',
    label: '常温',
    value: '',
  }, {
    key: 'freeze',
    label: '冷冻',
    value: '',
  }, {
    key: 'constant',
    label: '恒温',
    value: '',
  }],
}, {
  title: '当日入库数量(箱)/板位',
  key: 'todayPutIn',
  content: [{
    key: 'storage',
    label: '冷藏',
    value: '',
  }, {
    key: 'normal',
    label: '常温',
    value: '',
  }, {
    key: 'freeze',
    label: '冷冻',
    value: '',
  }, {
    key: 'constant',
    label: '恒温',
    value: '',
  }],
}, {
  title: '当日出库数量(箱)/板位',
  key: 'todayOutIn',
  content: [{
    key: 'storage',
    label: '冷藏',
    value: '',
  }, {
    key: 'normal',
    label: '常温',
    value: '',
  }, {
    key: 'freeze',
    label: '冷冻',
    value: '',
  }, {
    key: 'constant',
    label: '恒温',
    value: '',
  }],
}, {
  title: '过期品占比',
  key: 'temporaryProduct',
  content: [{
    key: 'storage',
    label: '冷藏',
    value: '',
  }, {
    key: 'normal',
    label: '常温',
    value: '',
  }, {
    key: 'freeze',
    label: '冷冻',
    value: '',
  }, {
    key: 'constant',
    label: '恒温',
    value: '',
  }],
}];

/**
 * 库区详情数据结构
 * @type {{}[]}
 */
export const StoreDataConfig = [{
  title: '货主数量(个)',
  key: 'shipperNum',
  detail: '',
  content: [{
    key: 'lessThanCarloadShipperNum',
    label: '零担(个)',
    value: '',
  }, {
    key: 'contractShipperNum',
    label: '合约(个)',
    value: '',
  }],
}, {
  title: '货品库存数量(箱)',
  key: 'goodsStockList',
  content: [{
    key: 'storage',
    label: '冷藏',
    value: '',
  }, {
    key: 'freeze',
    label: '冷冻',
    value: '',
  }],
}, {
  title: '库位使用率/占用板位',
  key: 'location',
  content: [{
    key: 'storage',
    label: '冷藏',
    value: '',
  }, {
    key: 'normal',
    label: '常温',
    value: '',
  }, {
    key: 'freeze',
    label: '冷冻',
    value: '',
  }, {
    key: 'constant',
    label: '恒温',
    value: '',
  }],
}, {
  title: '当日入库数量(箱)/板位',
  key: 'todayPutIn',
  content: [{
    key: 'storage',
    label: '冷藏',
    value: '',
  }, {
    key: 'normal',
    label: '常温',
    value: '',
  }, {
    key: 'freeze',
    label: '冷冻',
    value: '',
  }, {
    key: 'constant',
    label: '恒温',
    value: '',
  }],
}, {
  title: '当日出库数量(箱)/板位',
  key: 'todayOutIn',
  content: [{
    key: 'storage',
    label: '冷藏',
    value: '',
  }, {
    key: 'normal',
    label: '常温',
    value: '',
  }, {
    key: 'freeze',
    label: '冷冻',
    value: '',
  }, {
    key: 'constant',
    label: '恒温',
    value: '',
  }],
}, {
  title: '过期品占比',
  key: 'temporaryProduct',
  content: [{
    key: 'storage',
    label: '冷藏',
    value: '',
  }, {
    key: 'normal',
    label: '常温',
    value: '',
  }, {
    key: 'freeze',
    label: '冷冻',
    value: '',
  }, {
    key: 'constant',
    label: '恒温',
    value: '',
  }],
}];

/**
 * 库区搜索数据结构
 * @type {{}[]}
 */
export const StoreSearchConfig = [{
  title: '货主数量(个)',
  key: 'shipperNum',
  detail: '',
  content: [{
    key: 'lessThanCarloadShipperNum',
    label: '零担(个)',
    value: '',
  }, {
    key: 'contractShipperNum',
    label: '合约(个)',
    value: '',
  }],
}, {
  title: '货品库存数量(箱)',
  key: 'goodsStockList',
  content: [{
    key: 'storage',
    label: '冷藏',
    value: '',
  }, {
    key: 'freeze',
    label: '冷冻',
    value: '',
  }],
}, {
  title: '库位使用率/占用板位',
  key: 'location',
  content: [{
    key: 'storage',
    label: '冷藏',
    value: '',
  }, {
    key: 'normal',
    label: '常温',
    value: '',
  }, {
    key: 'freeze',
    label: '冷冻',
    value: '',
  }, {
    key: 'constant',
    label: '恒温',
    value: '',
  }],
}, {
  title: '当日入库数量(箱)/板位',
  key: 'todayPutIn',
  content: [{
    key: 'storage',
    label: '冷藏',
    value: '',
  }, {
    key: 'normal',
    label: '常温',
    value: '',
  }, {
    key: 'freeze',
    label: '冷冻',
    value: '',
  }, {
    key: 'constant',
    label: '恒温',
    value: '',
  }],
}, {
  title: '当日出库数量(箱)/板位',
  key: 'todayOutIn',
  content: [{
    key: 'storage',
    label: '冷藏',
    value: '',
  }, {
    key: 'normal',
    label: '常温',
    value: '',
  }, {
    key: 'freeze',
    label: '冷冻',
    value: '',
  }, {
    key: 'constant',
    label: '恒温',
    value: '',
  }],
}, {
  title: '过期品占比',
  key: 'temporaryProduct',
  content: [{
    key: 'storage',
    label: '冷藏',
    value: '',
  }, {
    key: 'normal',
    label: '常温',
    value: '',
  }, {
    key: 'freeze',
    label: '冷冻',
    value: '',
  }, {
    key: 'constant',
    label: '恒温',
    value: '',
  }],
}];

/**
 * 库区条件筛选
 * @type {{}[]}
 */
export const StoreConditionConfig = [{
  title: '',
  key: 'headLine',
  content: [{
    key: 'currentMonthReceivableAmount',
    label: '当月应收金额',
    value: 0,
  }, {
    key: 'todayReceivableAmount',
    label: '今日应收金额',
    value: 0,
  }, {
    key: '',
    label: '月度存储收入',
    value: 0,
  }, {
    key: 'currentMonthStoreIncome',
    label: '月度操作收入',
    value: 0,
  }, {
    key: 'goodsStockNum',
    label: '货品总量(箱)',
    value: 0,
  }, {
    key: 'goodsVeneerNum',
    label: '占用板位（板）',
    value: 0,
  }, {
    key: 'aboutToExpireGoodsNum',
    label: '过期品数量(箱)',
    value: 0,
  }],
}, {
  title: '库区A',
  key: 'zoneCode',
  content: [{
    key: '',
    label: '货品总量(箱)',
    value: '',
  }, {
    key: '',
    label: '货品总量(箱)',
    value: '',
  }, {
    key: '',
    label: '过期品数量(箱)',
    value: '',
  }],
}];

/**
 * 货架详情数据结构
 * @type {{}[]}
 */
export const ShelvesDataConfig = [{
  title: '货主数量(个)',
  key: 'shipperNum',
  detail: '',
  content: [{
    key: 'lessThanCarloadShipperNum',
    label: '零担(个)',
    value: '',
  }, {
    key: 'contractShipperNum',
    label: '合约(个)',
    value: '',
  }],
}, {
  title: '货品库存数量(箱)',
  key: 'goodsNum',
  content: [{
    key: 'storage',
    label: '冷藏',
    value: '',
  }, {
    key: 'freeze',
    label: '冷冻',
    value: '',
  }],
}, {
  title: '库位使用率/占用板位',
  key: 'location',
  content: [{
    key: 'storage',
    label: '冷藏',
    value: '',
  }, {
    key: 'normal',
    label: '常温',
    value: '',
  }, {
    key: 'freeze',
    label: '冷冻',
    value: '',
  }, {
    key: 'constant',
    label: '恒温',
    value: '',
  }],
}, {
  title: '当日入库数量(箱)/板位',
  key: 'todayPutIn',
  content: [{
    key: 'storage',
    label: '冷藏',
    value: '',
  }, {
    key: 'normal',
    label: '常温',
    value: '',
  }, {
    key: 'freeze',
    label: '冷冻',
    value: '',
  }, {
    key: 'constant',
    label: '恒温',
    value: '',
  }],
}, {
  title: '当日出库数量(箱)/板位',
  key: 'todayOutIn',
  content: [{
    key: 'storage',
    label: '冷藏',
    value: '',
  }, {
    key: 'normal',
    label: '常温',
    value: '',
  }, {
    key: 'freeze',
    label: '冷冻',
    value: '',
  }, {
    key: 'constant',
    label: '恒温',
    value: '',
  }],
}, {
  title: '过期品占比',
  key: 'temporaryProduct',
  content: [{
    key: 'storage',
    label: '冷藏',
    value: '',
  }, {
    key: 'normal',
    label: '常温',
    value: '',
  }, {
    key: 'freeze',
    label: '冷冻',
    value: '',
  }, {
    key: 'constant',
    label: '恒温',
    value: '',
  }],
}];

/**
 * 库位详情数据结构
 * @type {{}[]}
 */
export const LocationDataConfig = [{
  title: '货主数量(个)',
  key: 'shipperNum',
  detail: '',
  content: [{
    key: 'lessThanCarloadShipperNum',
    label: '零担(个)',
    value: '',
  }, {
    key: 'contractShipperNum',
    label: '合约(个)',
    value: '',
  }],
}, {
  title: '货品库存数量(箱)',
  key: 'goodsNum',
  content: [{
    key: 'storage',
    label: '冷藏',
    value: '',
  }, {
    key: 'freeze',
    label: '冷冻',
    value: '',
  }],
}, {
  title: '库位使用率/占用板位',
  key: 'location',
  content: [{
    key: 'storage',
    label: '冷藏',
    value: '',
  }, {
    key: 'normal',
    label: '常温',
    value: '',
  }, {
    key: 'freeze',
    label: '冷冻',
    value: '',
  }, {
    key: 'constant',
    label: '恒温',
    value: '',
  }],
}, {
  title: '当日入库数量(箱)/板位',
  key: 'todayPutIn',
  content: [{
    key: 'storage',
    label: '冷藏',
    value: '',
  }, {
    key: 'normal',
    label: '常温',
    value: '',
  }, {
    key: 'freeze',
    label: '冷冻',
    value: '',
  }, {
    key: 'constant',
    label: '恒温',
    value: '',
  }],
}, {
  title: '当日出库数量(箱)/板位',
  key: 'todayOutIn',
  content: [{
    key: 'storage',
    label: '冷藏',
    value: '',
  }, {
    key: 'normal',
    label: '常温',
    value: '',
  }, {
    key: 'freeze',
    label: '冷冻',
    value: '',
  }, {
    key: 'constant',
    label: '恒温',
    value: '',
  }],
}, {
  title: '过期品占比',
  key: 'temporaryProduct',
  content: [{
    key: 'storage',
    label: '冷藏',
    value: '',
  }, {
    key: 'normal',
    label: '常温',
    value: '',
  }, {
    key: 'freeze',
    label: '冷冻',
    value: '',
  }, {
    key: 'constant',
    label: '恒温',
    value: '',
  }],
}];
