<template>
  <div class="shelves-box">
    <div class="shelves-span-box">
      <canvas
        ref="shelves"
        class="shelves-panel"
      />
    </div>
  </div>
</template>

<script>
import Warehouse from '../objects';
import Shelves from '../assets/panel/shelves2.png';
import Shelves2 from '../assets/panel/shelves3.png';
import {
  WAREHOUSE_TYPE,
  RECTANGLE_X,
  RECTANGLE_Y,
  ELLIPSE_OFFSET_X,
  ELLIPSE_OFFSET_Y,
  ELLIPSE_OFFSET_RX,
  ELLIPSE_OFFSET_RY,
  STORE_GOODS_STATE,
  STORE_GOODS_TYPE,
  STORE_GOODS_WARING,
} from '../const';
import { judgeInBoundary, getRealCoordinate } from '../utils';

export default {
  name: 'Shelves',
  props: {
    level: {
      type: Number,
      default: WAREHOUSE_TYPE.STOREHOUSE,
    },
    showDialog: {
      type: Boolean,
      default: true,
    },
    warehouseConfig: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['handleClickStore', 'resetSearchCondition', 'setChoiceKey'],
  data() {
    return {
      selectInfo: null, // 已选择的点
      shelves: null,
      shelvesData: [],
      shelvesBg: Shelves,
    };
  },
  watch: {
    warehouseConfig: {
      deep: true,
      handler() {
        if (!this.shelves) {
          return;
        }
        this.$nextTick(() => {
          this.shelvesData = [...this.warehouseConfig];
          if (!this.showDialog) {
            this.shelvesData.forEach((item, index) => {
              // eslint-disable-next-line max-len
              this.shelvesData[index].type = this.showDialog ? STORE_GOODS_TYPE.NORMAL : STORE_GOODS_TYPE.HIDDEN;
            });
          }
          this.refreshCanvasBox(this.shelvesData);
        });
      },
    },
    '$route.query.store': {
      immediate: true,
      handler(val) {
        if (val && val === 'zoneY') {
          this.shelvesBg = `${Shelves2}`;
        } else {
          this.shelvesBg = `${Shelves}`;
        }
        this.$nextTick(() => {
          this.shelves.refreshRectangleDialogBox(this.shelvesData, this.shelvesBg);
        });
      },
    },
    showDialog() {
      this.resetStoreDataType();
    },
  },
  mounted() {
    this.shelves = new Warehouse(this.$refs.shelves, {
      level: this.level, // 当前仓库类型
      bgImg: this.shelvesBg, // 当前定位背景图片
      dialogConfigData: this.shelvesData,
    });

    // 点击事件监听
    this.$refs.shelves.addEventListener('click', this.listenerShelvesClick);
    this.$refs.shelves.addEventListener('mousemove', this.listenerShelvesHover);
  },
  methods: {
    refreshCanvasBox(data) {
      this.shelves.refreshRectangleDialogBox(data);
    },
    // 1.使用顶点数组，计算得出 原点数组 (向后匹配, 存在遮盖问题), 已最后匹配结果为准！！！
    // 2.判断X、Y 是否在集合内部
    computedClickConfig(x, y, isHover = false) {
      let choiceStore = null;
      this.shelvesData.forEach((store) => {
        // 是否是矩形选中 isRectangleIn,
        // eslint-disable-next-line max-len
        const isRectangleIn = !isHover && (this.showDialog || store.waring === STORE_GOODS_WARING.ACTIVE) ? judgeInBoundary(x, y, store.x, store.y, RECTANGLE_X, RECTANGLE_Y) : false;
        // 椭圆中心对齐，所以x、y要减去半径
        const isEllipseIn = judgeInBoundary(x, y, store.x + ELLIPSE_OFFSET_X - ELLIPSE_OFFSET_RX,
          store.y + ELLIPSE_OFFSET_Y - ELLIPSE_OFFSET_RY,
          ELLIPSE_OFFSET_RX * 2, ELLIPSE_OFFSET_RY * 2);

        // 只存放最后一个匹配内容
        if (isRectangleIn || isEllipseIn) {
          choiceStore = store;
        }
      });
      return choiceStore;
    },
    // 重置所有点
    resetStoreDataState(key, value) {
      this.shelvesData.forEach((item, index) => {
        this.shelvesData[index][key] = value;
      });
    },
    // 重置隐藏状态 并重绘
    resetStoreDataType() {
      this.shelvesData.forEach((item, index) => {
        // eslint-disable-next-line max-len
        this.shelvesData[index].type = this.showDialog ? STORE_GOODS_TYPE.NORMAL : STORE_GOODS_TYPE.HIDDEN;
      });
      this.refreshCanvasBox(this.shelvesData);
    },
    setSelectInfo(val) {
      this.selectInfo = val;
      this.$emit('handleClickStore', this.selectInfo);
    },
    setChoiceKeyInfo(key) {
      this.$emit('setChoiceKey', key);
    },
    listenerShelvesClick(event) {
      const { X, Y } = getRealCoordinate(event, '.shelves-panel');
      const config = this.computedClickConfig(X, Y);
      if (!config) {
        return;
      }
      const index = this.shelvesData.findIndex((item) => item.id === config.id);
      // 点击筛选结果 进行跳转
      // eslint-disable-next-line max-len
      // const hasWaring = this.shelvesData.findIndex((item) => item.waring === STORE_GOODS_WARING.ACTIVE);
      // if (hasWaring !== -1) {
      //   this.setSelectInfo(this.shelvesData[index]);
      //   // 如果当前数据存在搜索数据、则重置所有数据与搜索条件
      //   this.$emit('resetSearchCondition', {
      //     query: {
      //       shelves: this.shelvesData[index].id,
      //     },
      //   });
      //   return;
      // }
      if (index !== -1) {
        const equalActive = this.shelvesData[index].state === STORE_GOODS_STATE.ACTIVE;
        // 记录点击点
        this.setChoiceKeyInfo(equalActive ? '' : this.shelvesData[index].id);
        this.setSelectInfo(equalActive ? null : this.shelvesData[index]);
        this.resetStoreDataState('state', STORE_GOODS_STATE.NORMAL);
        // 如果本身就是激活状态, 则取消选择
        if (!equalActive) {
          // 匹配到点后, 先重置所有state 并设置匹配的state
          this.shelvesData[index].state = STORE_GOODS_STATE.ACTIVE;
        }
        // 改变数据状态, 并重新渲染
        this.refreshCanvasBox(this.shelvesData);
      }
    },
    listenerShelvesHover(event) {
      const { X, Y } = getRealCoordinate(event, '.shelves-panel');
      const config = this.computedClickConfig(X, Y, true);

      // 重置所有hover状态
      this.resetStoreDataState('isHover', false);
      // 如果已经是hover态, 则不触发
      if (config?.isHover) {
        return;
      }
      if (!config) {
        return;
      }
      const index = this.shelvesData.findIndex((item) => item.id === config.id);
      if (index !== -1) {
        // 匹配到点后, 先重置所有state 并设置匹配的state
        this.shelvesData[index].isHover = true;
        // 改变数据状态, 并重新渲染
        this.refreshCanvasBox(this.shelvesData);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$width: 12.2em;
$height: $width * 0.568;
.shelves-box{
  margin-top: 0.32em;
  background: #FFF;
  overflow: hidden;
  height: calc(100% - 1.13em);
}
.shelves-span-box{
  width: $width;
  height: $height;
  border-radius: 0.16em;
  .shelves-panel{
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 999;
    font-family: "DinBold", serif;
  }
}
</style>
