<template>
  <div
    v-if="detailItem"
    class="item"
    :class="{'active': detailItem?.key === HeadKey}"
  >
    <div
      class="title"
      :class="{'title-tag': hasGoods || detailItem?.key === HeadKey}"
    >
      <span>{{ hasGoods ? detailItem.goods : detailItem?.title }}</span>
    </div>
    <div
      v-if="hasGoods"
      class="goods"
    >
      <img
        class="goods-icon"
        src="../../assets/detail-comp/goods.png"
        alt="icon"
      >
      <span>{{ detailItem.title }}</span>
    </div>
    <div class="detail">
      <p
        v-for="(item) in detailItem.content"
        :key="item.label"
        class="detail-item"
      >
        <span class="label">{{ item.label }}:</span>
        <span
          class="value"
          :class="{'red': detailItem.key === HeadKey && item.key === SpecialKey}"
        >
          {{ item.value }}
        </span>
      </p>
    </div>
  </div>
</template>

<script>
const HeadKey = 'headLine'; // 头部Key值 区分样式
const SpecialKey = 'aboutToExpireGoodsNum'; // 特殊值key 展示为红色

export default {
  name: 'SpeDetailItem',
  props: {
    detailItem: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      HeadKey,
      SpecialKey,
      compItem: {},
    };
  },
  computed: {
    hasGoods() {
      return !!this.detailItem?.goods;
    },
  },
  watch: {
    detailItem: {
      deep: true,
      immediate: true,
      handler() {
        this.compItem = { ...this.detailItem };
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  padding: 0.24em;

  .goods {
    margin-top: 0.12em;
    margin-bottom: 0.16em;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .goods-icon {
      margin-right: 0.02em;
      width: 0.16em;
      height: 0.16em;
    }

    span {
      font-size: 0.14em;
      color: #646566;
    }
  }

  .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding-left: 0;
    font-weight: 600;
    color: #323233;
    line-height: 0.28em;
    text-align: left;

    span {
      display: block;
      font-size: 0.16em;
    }

    .title-detail {
      margin-left: 16px;
      color: rgba(100, 101, 102, 1);
      font-size: 0.16em;
    }
  }

  .title-tag {
    padding-left: 14px;

    &::after {
      position: absolute;
      left: 0;
      top: 0.06em;
      content: '';
      background-color: #06b880;
      width: 4px;
      height: 0.16em;
      border-radius: 0.02em;
    }
  }

  .detail {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    .detail-item {
      margin-top: 0.12em;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-shrink: 0;
      width: 50%;

      .label {
        flex-shrink: 0;
        margin-right: 2px;
        font-size: 0.14em;
        font-weight: 400;
        color: #646566;
      }

      .value {
        display: inline-block;
        font-size: 0.14em;
        font-family: "DinBold", serif;
        font-weight: 600;
        color: #323233;
        word-break: break-all;
        text-align: left;

        &.red {
          color: #f5222d;
        }
      }
    }
  }
}

.active {
  background-color: #f7f8fa;
  border-radius: 0.12em;
}
</style>
