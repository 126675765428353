<template>
  <div
    class="customer-warp"
    :class="{ 'need-footer-btn': showNextBtn }"
  >
    <div
      class="intro"
    >
      <span
        class="left-arrow"
        @click="handleBack"
      />
      <span class="text">货主列表</span>
    </div>
    <div
      class="list"
    >
      <div class="title">
        <span class="text">库存数据分布排名</span>
      </div>
      <div class="detail-list">
        <el-radio-group
          v-model="customer"
          @change="handleChangeCustomer"
        >
          <div
            v-for="(item) in customerList"
            :key="item.shipperCode"
            class="item"
          >
            <div class="content">
              <div class="name">
                <el-radio
                  :label="item.shipperId"
                  size="large"
                >
                  {{ item.shipperName }}
                </el-radio>
              </div>
              <div
                class="value"
                :class="{'rate-active': item.shipperId === customer}"
              >
                {{ multiplyRate(item.shipperRate) }}%
              </div>
            </div>
            <div class="process">
              <span
                class="percent"
                :class="{ 'min-percent': item.shipperRate < 0.3 }"
                :style="{width: (item.shipperRate < 0.01 ? 0.01 : item.shipperRate) * 100 + '%'}"
              />
            </div>
          </div>
        </el-radio-group>
      </div>
    </div>
    <div
      v-if="showNextBtn"
      class="footer"
    >
      <el-button
        class="footer-btn isActive"
        type="primary"
        size="small"
        @click="handleNextStep"
      >
        点击进入{{ footerBtnText }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { multiply } from '../../utils';
import { WAREHOUSE_TYPE } from '../../const';

export default {
  name: 'CustomerList',
  props: {
    shipperList: {
      type: Array,
      default: () => [],
    },
    selectWarehouse: {
      type: Object,
      default: () => {},
    },
    level: {
      type: Number,
      default: null,
    },
  },
  emits: ['handleChangeCustomer', 'handleNextStep'],
  data() {
    return {
      customerList: [],
      customer: '',
    };
  },
  computed: {
    footerBtnText() {
      if (this.level === WAREHOUSE_TYPE.STOREHOUSE) {
        return '货架';
      }
      return '库位';
    },
    showNextBtn() {
      return this.level !== WAREHOUSE_TYPE.LOCATION && !this.selectWarehouse?.isDisabled;
    },
  },
  watch: {
    '$route.query.owner': {
      handler(owner) {
        this.customer = owner;
      },
    },
    shipperList(list) {
      if (list.length <= 0) {
        return;
      }
      this.customer = '';
      this.customerList = this.shipperList;
    },
  },
  methods: {
    handleNextStep() {
      this.$emit('handleNextStep');
      this.handleBack();
    },
    handleChangeCustomer(customer) {
      this.setRouterQuery({
        owner: customer,
        goods: '',
      });
    },
    handleBack() {
      this.customer = '';
      this.$emit('handleChangeCustomer');
    },
    multiplyRate(val) {
      return multiply(val, 100);
    },
    setRouterQuery(params) {
      const { path, query } = this.$route;
      const newQuery = {
        ...query,
        ...params,
      };
      this.$router.replace({
        path,
        query: newQuery,
      });
    },
  },
};
</script>

<style lang="scss" scoped>

  :deep(.el-radio-group) {
    width: 100%;
    font-size: inherit;
  }

  .customer-warp {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    border-radius: 0.16em;
    background-color: #fff;
    box-shadow: 0 0 0 0 #f0f0f0;
    height: 100%;

    .intro {
      position: relative;
      padding: 0.15em;
      font-weight: 600;
      text-align: center;

      .left-arrow {
        display: block;
        position: absolute;
        left: 0.28em;
        top: 0.2em;
        width: 0.12em;
        height: 0.12em;
        border-width: 0.02em;
        border-style: solid;
        border-color: #c8c9cc transparent transparent #c8c9cc;
        transform: rotate(-45deg);
        cursor: pointer;
      }

      .text {
        display: block;
        color: #323233;
        font-size: 0.2em;
      }
    }

    .list {
      padding: 0.24em 0.24em 0 0.48em;
      overflow-x: hidden;
      overflow-y: scroll;

      .title {
        position: relative;
        line-height: 0.24em;
        padding-left: 0.12em;
        color: #646566;

        .text {
          display: block;
          font-size: 0.16em;
        }

        &::after {
          position: absolute;
          left: 0;
          top: 0.05em;
          content: '';
          background-color: #06b880;
          width: 0.04em;
          height: 0.14em;
          border-radius: 0.02em;
        }
      }

      .detail-list {
        display: flex;
        padding-bottom: 0.16em;

        .item {
          margin-top: 0.24em;

          .content {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .name {
              :deep(.el-radio) {
                display: flex;
                font-size: inherit;
                line-height: 0.2em;
                height: 0.2em;

                .el-radio__input {
                  display: block;
                  font-size: inherit;
                  line-height: 0.2em;

                  .el-radio__inner {
                    display: block;
                    width: 0.16em;
                    height: 0.16em;
                  }
                }

                .el-radio__label {
                  display: block;
                  font-size: 0.14em;
                }
              }

              :deep(.el-radio.is-checked) {
                .el-radio__input.is-checked {
                  .el-radio__inner {
                    background-color: #fff;

                    &::after {
                      width: 0.08em;
                      height: 0.08em;
                      background-color: #06b880;
                    }
                  }
                }

                .el-radio__label {
                  font-weight: 600;
                }
              }
            }

            .value {
              font-family: "DinBold", serif;
              font-weight: bold;
              font-size: 0.16em;

              &.rate-active {
                color: #06b880;
              }
            }
          }

          .process {
            margin-top: 0.16em;
            width: 100%;
            height: 0.1em;
            border-radius: 0.08em;
            background-color: #e6f6ff;

            .percent {
              display: block;
              border-radius: 0.08em;
              height: 0.1em;
              background-color: #57a8eb;
            }

            .min-percent {
              border-radius: 0.08em 0 0 0.08em;
            }
          }
        }
      }

      &::-webkit-scrollbar {
        width: 6px;
        height: 8px;
      }

      &::-webkit-scrollbar-track-piece {
        -webkit-border-radius: 0;
      }

      &::-webkit-scrollbar-track { background-color: #eee; } /* 滚动条的滑轨背景颜色 */
      &::-webkit-scrollbar-button { background-color: #eee; } /* 滑轨两头的监听按钮颜色 */
      &::-webkit-scrollbar-corner { background-color: black; } /* 横向滚动条和纵向滚动条相交处尖角的颜色 */

      &::-webkit-scrollbar-thumb {
        height: 30px;
        -webkit-border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.2);
      }

      &-webkit-scrollbar-thumb:hover {
        height: 50px;
        -webkit-border-radius: 4px;
      }
    }

    .footer {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.24em 0.66em;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 0.88em;
      border-top: 1px solid #f0f0f0;

      .footer-btn {
        width: 100%;
        height: 100%;
      }

      .isActive {
        background-color: #06b880;
        border-color: #06b880;
        color: #fff;
      }

      .isDisabled {
        background-color: #d9d9d9;
        border-color: #d9d9d9;
        color: #fff;
      }
    }
  }

  .need-footer-btn {
    padding-bottom: 0.88em;
  }
</style>
