<template>
  <div
    v-if="hasPosition"
    class="position-bar-root"
  >
    <!-- <div
      class="dot-preview"
    >
      <component
        :is="wareHouse"
        :position-key="positionKey"
        :position-data="availablePositionConfig"
        @changeKey="changeStoreKey"
      />
    </div> -->
    <div
      class="position-item"
      :class="{'is-shelves': isShelves}"
    >
      <p
        v-for="(item) in availablePositionConfig"
        :key="item.id"
        :class="{
          'active': item.state === STORE_GOODS_STATE.ACTIVE,
          'waring': item.waring === STORE_GOODS_WARING.ACTIVE
        }"
        class="item"
        @click="changeStoreKey(item.id)"
      >
        <span class="title"> {{ item.title }} </span>
        <span class="item-detail">
          <span class="text">
            {{ item.content[0].value }}件 / {{ item.content[1].value }}KG
          </span>
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import { getShelvesQuery, getZoneConfig, getZoneQuery } from '../../../api/warehouse';
import storePreview from './store-preview.vue';
import shelvesPreview from './shelves-preview.vue';
import {
  WAREHOUSE_TYPE,
  STORE_GOODS_STATE,
  STORE_GOODS_WARING,
  IFRAME_HOUSE_CODE, GOODS_STATUS_TYPE,
  DONGQI_CODE,
  DONGQI_WSCODE,
} from '../../const';
import { hasExistProperty } from '../../utils';
import {
  computedShelvesPlaneConfig,
  computedStorePlaneConfig,
} from '../../config/data-factory';

const WareHouseOptions = [
  {
    comp: storePreview,
    level: WAREHOUSE_TYPE.SHELVES,
  },
  {
    comp: shelvesPreview,
    level: WAREHOUSE_TYPE.LOCATION,
  },
];

export default {
  name: 'PositionBar',
  props: {
    positionData: {
      type: Array,
      default: () => [],
    },
    level: {
      type: Number,
      default: WAREHOUSE_TYPE.HOME,
    },
    isIframe: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['changeKey', 'updateChoiceArray'],
  data() {
    return {
      storeConfig: [], // 库区配置
      positionConfig: [], // 当前库区点位值
      availableStore: [], // 可用库区
      availableShelves: [], // 可用货架
      STORE_GOODS_STATE,
      STORE_GOODS_WARING,
      positionKey: null, // 缩略图的key值
      isBack: false, // 是否是页面回退
    };
  },
  computed: {
    isShelves() {
      return this.level === WAREHOUSE_TYPE.SHELVES;
    },
    hasPosition() {
      return this.level === WAREHOUSE_TYPE.SHELVES || this.level === WAREHOUSE_TYPE.LOCATION;
    },
    wareHouse() {
      const warehouse = WareHouseOptions.find((item) => item.level === this.level);
      if (warehouse) {
        return warehouse.comp;
      }
      return WareHouseOptions[0].comp;
    },
    availablePositionConfig() {
      const results = [];
      this.positionConfig.forEach((config) => {
        if (!config.isDisabled) {
          results.push(config);
        }
      });
      return results;
    },
  },
  watch: {
    '$route.query.level': {
      immediate: true,
      handler(val, oldVal) {
        if (val && oldVal) {
          // 当两值存在且变量值小于时 则是在后退, 后端需要重新请求数据
          this.isBack = val < oldVal;
        } else {
          this.isBack = false;
        }
        this.$nextTick(() => {
          // 每次路由变化 清空存储的数据
          this.positionConfig = [];
          this.initPositionKey();
          this.initPositionBar();
        });
      },
    },
  },
  methods: {
    // 页面嵌入时, 选中状态初始化的隔离
    isIframeShowState() {
      const { owner, goods, cond } = this.$route.query;
      return owner && this.isIframe && !goods && !cond;
    },
    // 初始化缩略图选中值
    initPositionKey() {
      const { store, shelves } = this.$route.query;
      switch (this.level) {
        case WAREHOUSE_TYPE.STOREHOUSE: this.positionKey = store || ''; break;
        case WAREHOUSE_TYPE.SHELVES: this.positionKey = store || ''; break;
        case WAREHOUSE_TYPE.LOCATION: this.positionKey = shelves || ''; break;
        default: break;
      }
    },
    initPositionBar() {
      // 当level更改为货架与库位时、初始化值
      if (this.level === WAREHOUSE_TYPE.SHELVES) {
        this.initShelvesConfig();
      } else if (this.level === WAREHOUSE_TYPE.LOCATION) {
        this.initLocationConfig();
      }
    },
    async fetchAvailableStoreHouse() {
      const data = {};
      if (this.isIframe) {
        data.warehouseCode = IFRAME_HOUSE_CODE;
      }
      data.code = DONGQI_CODE;
      data.warehouseSourceCode = DONGQI_WSCODE;
      this.storeConfig = await getZoneConfig(data);
    },
    // 和index 同一方法 待优化
    getSearchConditionParams() {
      // 获取搜索条件
      const data = {};
      const {
        goods,
        owner,
        cond,
      } = this.$route.query;

      if (owner) {
        data.shipperId = owner;
      }
      if (goods) {
        data.goodsId = goods;
      }
      if (cond) {
        switch (parseInt(cond, 10)) {
          case GOODS_STATUS_TYPE.NEAR: data.isStopWarningStock = true; break;
          case GOODS_STATUS_TYPE.EXPIRE: data.aboutToExpire = true; break;
          case GOODS_STATUS_TYPE.IMPORT: data.isImport = true; break;
          case GOODS_STATUS_TYPE.UP: data.upperShelfOfCurrentDay = true; break;
          case GOODS_STATUS_TYPE.DOWN: data.offShelfOfCurrentDay = true; break;
          default: break;
        }
      }
      if (this.isIframe) {
        data.warehouseCode = IFRAME_HOUSE_CODE;
      }
      data.code = DONGQI_CODE;
      data.warehouseSourceCode = DONGQI_WSCODE;
      return data;
    },
    // 初始化库区数据
    async initStoreHouseData() {
      // 获取搜索条件
      const data = this.getSearchConditionParams();

      const { zones } = await getZoneQuery(data);
      // 这里需要判断是否已经选中库区
      if (hasExistProperty(data)) {
        // 如果是嵌入页面且筛选条件只有一个owner, 则不显示激活状态
        // eslint-disable-next-line max-len
        const STATE = this.isIframeShowState() ? STORE_GOODS_WARING.NORMAL : STORE_GOODS_WARING.ACTIVE;
        // 提取出综合库区信息 给详情展示
        this.positionConfig = computedStorePlaneConfig(zones, this.storeConfig, STATE);
      } else {
        this.positionConfig = computedStorePlaneConfig(zones, this.storeConfig);
      }
    },
    // 初始化货架数据
    async initShelvesData() {
      const data = this.getSearchConditionParams();
      const { store, shelves } = this.$route.query;
      if (store) {
        data.zoneCode = store;
      }
      if (shelves) {
        data.rowCode = shelves;
      }
      if (this.isIframe) {
        data.warehouseCode = IFRAME_HOUSE_CODE;
      }
      data.code = DONGQI_CODE;
      data.warehouseSourceCode = DONGQI_WSCODE;
      const { rows } = await getShelvesQuery(data);
      // 存在筛选条件, 则是在筛选
      if (hasExistProperty(data)) {
        // 如果是嵌入页面且筛选条件只有一个owner, 则不显示激活状态
        // eslint-disable-next-line max-len
        const STATE = this.isIframeShowState() ? STORE_GOODS_WARING.NORMAL : STORE_GOODS_WARING.ACTIVE;
        this.positionConfig = computedShelvesPlaneConfig(rows, STATE);
      } else {
        this.positionConfig = computedShelvesPlaneConfig(rows);
      }
    },
    async initShelvesConfig() {
      const { shelves } = this.$route.query;
      // eslint-disable-next-line max-len
      const hasWaringIndex = this.positionData.findIndex((item) => item.waring === STORE_GOODS_WARING.ACTIVE && !item.isDisabled);
      if (
        Array.isArray(this.positionData)
        && this.positionData.length !== 0
        && !this.isBack
        && hasWaringIndex !== -1
      ) {
        this.positionConfig = [...this.positionData];
        this.setActivePosition({ shelves });
      } else {
        await this.fetchAvailableStoreHouse();
        await this.initStoreHouseData();
        this.setActivePosition({ shelves });
      }
    },
    async initLocationConfig() {
      // eslint-disable-next-line max-len
      const hasWaringIndex = this.positionData.findIndex((item) => item.waring === STORE_GOODS_WARING.ACTIVE);
      if (
        Array.isArray(this.positionData)
        && this.positionData.length !== 0
        && !this.isBack
        && hasWaringIndex !== -1
      ) {
        this.positionConfig = [...this.positionData];
        this.setActivePosition();
      } else {
        await this.initShelvesData();
        this.setActivePosition();
      }
    },
    // 重置所有点
    resetPositionWaring() {
      // eslint-disable-next-line max-len
      const hasWaring = this.positionConfig.findIndex((config) => config.waring === STORE_GOODS_WARING.ACTIVE);
      if (hasWaring === -1) return;

      this.positionConfig.forEach((item, index) => {
        this.positionConfig[index].waring = STORE_GOODS_WARING.NORMAL;
      });
    },
    setActivePosition(params = {}) {
      // eslint-disable-next-line max-len
      const hasWaringIndex = this.positionConfig.findIndex((item) => item.waring === STORE_GOODS_WARING.ACTIVE && !item.isDisabled);
      if (hasWaringIndex !== -1) {
        // 有选中值优先展示选中值
        const activeKey = this.positionKey || this.positionConfig[hasWaringIndex].id;
        const index = this.positionConfig.findIndex((item) => item.id === activeKey);
        this.positionConfig[index].state = STORE_GOODS_STATE.ACTIVE;
        this.$emit('updateChoiceArray', {
          levelType: this.level - 1,
          ...this.positionConfig[index],
        });
        this.$emit('changeKey', {
          key: this.positionConfig[index].id,
          ...params,
        });
        return;
      }
      const index = this.positionConfig.findIndex((item) => item.id === this.positionKey);
      // 查找当前是否有绑定key, 有则选中绑定key. 无则查找是否有筛选状态、有则默认选中第一个
      if (index !== -1) {
        if (this.positionConfig[index].state === STORE_GOODS_STATE.ACTIVE) {
          return;
        }
        this.positionConfig[index].state = STORE_GOODS_STATE.ACTIVE;
        this.$emit('updateChoiceArray', {
          levelType: this.level - 1,
          ...this.positionConfig[index],
        });
        this.$emit('changeKey', {
          key: this.positionConfig[index].id,
          ...params,
        });
      }
    },
    changeStoreKey(key) {
      const index = this.positionConfig.findIndex((config) => config.id === key);
      if (index !== -1) {
        if (this.positionConfig[index].state === STORE_GOODS_STATE.ACTIVE) {
          return;
        }
        // 重置点
        this.positionConfig.forEach((item, itemIndex) => {
          this.positionConfig[itemIndex].state = STORE_GOODS_STATE.NORMAL;
        });
        this.positionKey = key;
        this.positionConfig[index].state = STORE_GOODS_STATE.ACTIVE;
        this.$emit('updateChoiceArray', {
          levelType: this.level - 1,
          ...this.positionConfig[index],
        });
        this.$emit('changeKey', {
          key,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.position-bar-root {
  margin-top: 0.16em;
  position: absolute;
  left: 0;
  top: 1em;
  display: flex;
  justify-content: flex-start;
  z-index: 1000;

  .dot-preview {
    width: 1.3em;
    height: 0.88em;
    padding: 0.1em;
    background-color: #fff;
    border-radius: 0.12em;
  }

  .position-item {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    // margin-left: 0.24em;

    width: 7.2em;

    .item {
      position: relative;
      margin-bottom: 0.16em;
      margin-left: 0.16em;
      margin-top: 0.12em;
      width: 1.04em;
      height: 0.36em;
      line-height: 0.36em;
      text-align: center;
      border-radius: 0.04em;
      background-color: #fff;
      color: #323233;
      cursor: pointer;
      border: 1px solid #f4f4f4;
      .item-detail {
        display: none;
        position: absolute;
        bottom: 0.36em;
        left: 50%;
        min-width: 0.76em;
        height: 0.4em;
        padding: 0 0.12em;
        line-height: 0.4em;
        background-color: #fff;
        box-shadow: 0 0.02em 0.08em 0 rgba(200, 201, 204, 0.5);
        border-radius: 0.04em;
        z-index: 99;
        transform: translateX(-50%);

        .text {
          display: block;
          font-size: 0.14em;
          font-weight: 600;
          text-align: center;
          white-space: nowrap;
        }

        &::after {
          position: absolute;
          content: '';
          bottom: -0.02em;
          left: 50%;
          width: 10px;
          height: 10px;
          z-index: 100;
          background-color: #fff;
          transform: translateX(-50%) rotate(45deg);
        }
      }

      &.active {
        background: #06b880;
        color: #fff !important;
      }

      &.waring {
        // color: rgba(255, 128, 48, 1);

        &:hover {
          .item-detail {
            display: block;
            color: #323233;
            z-index: 100;
          }
        }
      }

      .title {
        display: block;
        font-size: 0.14em;
      }
    }
  }

  .is-shelves {
    // width: 2.4em;
  }
}
</style>
