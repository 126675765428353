/**
 * Author: He DingXiong
 * Date: 2021-21-24
 * Des: data-factory 组合当前数据
 * */
import moment from 'moment';
import { multiply, thousandBitSeparator } from '../utils';

import {
  HomeDataConfig,
  StoreDataConfig,
  StoreSearchConfig,
} from './data-config';
import { StoreHouseConfig, ShelvesConfig } from './canvas-config';
import {
  WAREHOUSE_TYPE,
  STORE_GOODS_TYPE,
  STORE_GOODS_STATE,
  STORE_GOODS_WARING,
  STORE_PLANE_ORDER,
} from '../const';

const ObjectArrayNumberSort = (arr = [], key) => {
  const result = [...arr];

  result.sort((a, b) => {
    const tempA = parseInt(a[key], 10);
    const tempB = parseInt(b[key], 10);

    return tempA - tempB;
  });

  return result;
};

const combineParams = (arr1, arr2, isPercent) => {
  if (arr1.length !== arr2.length) {
    return [];
  }
  return arr1.map((item, index) => {
    const firstValue = isPercent ? `${multiply(item.value, 100)}%` : thousandBitSeparator(item.value || 0);
    const value = [firstValue, thousandBitSeparator(arr2[index].value)];
    return {
      key: item.temperatureLayerCode,
      label: item.temperatureLayerName,
      // percentKey: isPercent ? null : 'WORD_BREAK',
      percentKey: 'WORD_BREAK',
      value,
    };
  });
};

const coverParams = (arr1) => arr1.map((item) => ({
  key: item.temperatureLayerCode,
  label: item.temperatureLayerName,
  value: thousandBitSeparator(item.value),
}));

const computedSingleProp = (arr1) => arr1.map((item) => ({
  key: item.temperatureLayerCode,
  label: item.temperatureLayerName,
  value: `${multiply(item.value, 100)}%`,
}));

// 组装单个百分比数组
const computedSinglePropArray = (configArray, targetKey, arr1) => {
  const index = configArray.findIndex((_) => _.key === targetKey);

  if (index !== -1) {
    // eslint-disable-next-line no-param-reassign
    configArray[index].content = computedSingleProp(arr1);
  }
};

// 获取数组
const computedArray = (configArray, targetKey, isPercent = false, arr1, arr2 = null) => {
  const index = configArray.findIndex((_) => _.key === targetKey);

  if (index !== -1) {
    // eslint-disable-next-line no-param-reassign,max-len
    configArray[index].content = !arr2 ? coverParams(arr1, isPercent) : combineParams(arr1, arr2, isPercent);
  }
};

// 组装仓库首页数据格式
export const computedHomeConfig = (homeConfig) => {
  if (!homeConfig) {
    return HomeDataConfig;
  }
  const resultConfig = [...HomeDataConfig];
  // 收入赋值
  // 2.5D仓可视化联调处理zg
  const todayReceivableAmount = homeConfig.income?.incomeNoTaxDaily || 0;
  const currentMonthReceivableAmount = homeConfig.income?.incomeNoTaxMonth || 0;
  const currentMonthStoreIncome = homeConfig.income?.storeIncomeNoTaxMonth || 0;
  const currentMonthOperateIncome = homeConfig.income?.operationIncomeNoTaxMonth || 0;
  resultConfig[2].content[0].value = thousandBitSeparator(todayReceivableAmount);
  resultConfig[2].content[1].value = thousandBitSeparator(currentMonthReceivableAmount);
  resultConfig[2].content[2].value = thousandBitSeparator(currentMonthStoreIncome);
  resultConfig[2].content[3].value = thousandBitSeparator(currentMonthOperateIncome);
  // 货主数量赋值
  resultConfig[0].detail = thousandBitSeparator(homeConfig.shipperNum);
  resultConfig[0].content[0].value = thousandBitSeparator(homeConfig.lessThanCarloadShipperNum);
  resultConfig[0].content[1].value = thousandBitSeparator(homeConfig.contractShipperNum);
  // 库位使用率/占用板位
  computedArray(resultConfig, 'location', true, homeConfig.locationUsingRateList, homeConfig.veneerNumList);
  // 当日入库数量（EA）/板位
  computedArray(resultConfig, 'todayPutIn', false, homeConfig.todayReceiptNumList, homeConfig.todayReceiptVeneerNumList);
  // 当日出库数量（EA）/板位
  computedArray(resultConfig, 'todayOutIn', false, homeConfig.todayDeliverNumList, homeConfig.todayDeliverVeneerNumList);
  // 临期品占比
  computedSinglePropArray(resultConfig, 'temporaryProduct', homeConfig.aboutToExpireGoodsRateList);
  // 货品库存数量（EA）
  computedArray(resultConfig, 'goodsStockList', false, homeConfig.goodsStockList);
  return resultConfig;
};

// 组装库区、货架筛选结果详情数据
export const computedSearchStoreConfig = (searchConfig, type) => {
  if (!searchConfig) {
    return [];
  }
  const { zoneGoods, rowGoods } = searchConfig;

  const resultConfig = [];
  let intro = {};
  if (type === WAREHOUSE_TYPE.STOREHOUSE) {
    intro = {
      title: searchConfig.shipperName,
      goods: searchConfig.goodsName, // 商品信息
      key: 'headLine',
      content: [{
        key: 'currentMonthReceivableAmount',
        label: '当月应收金额',
        value: thousandBitSeparator(searchConfig.income.incomeNoTaxMonth),
      }, {
        key: 'todayReceivableAmount',
        label: '今日应收金额',
        value: thousandBitSeparator(searchConfig.income.incomeNoTaxDaily),
      }, {
        key: 'currentMonthStoreIncome',
        label: '月度存储收入',
        value: thousandBitSeparator(searchConfig.income.storeIncomeNoTaxMonth),
      }, {
        key: 'currentMonthOperateIncome',
        label: '月度操作收入',
        value: thousandBitSeparator(searchConfig.income.operationIncomeNoTaxMonth),
      }, {
        key: 'goodsStockNum',
        label: '货品总量(箱)',
        value: thousandBitSeparator(searchConfig.goodsStockNum),
      }, {
        key: 'goodsVeneerNum',
        label: '占用板位(板)',
        value: thousandBitSeparator(searchConfig.goodsVeneerNum),
      }, {
        key: 'aboutToExpireGoodsNum',
        label: '过期品数量(箱)',
        value: thousandBitSeparator(searchConfig.aboutToExpireGoodsNum),
      }],
    };
  } else {
    intro = {
      title: searchConfig.shipperName,
      goods: searchConfig.goodsName, // 商品信息
      key: 'headLine',
      content: [{
        key: 'goodsStockNum',
        label: '货品总量(箱)',
        value: thousandBitSeparator(searchConfig.goodsStockNum),
      }, {
        key: 'goodsVeneerNum',
        label: '占用板位(板)',
        value: thousandBitSeparator(searchConfig.goodsVeneerNum),
      }, {
        key: 'aboutToExpireGoodsNum',
        label: '过期品数量(箱)',
        value: thousandBitSeparator(searchConfig.aboutToExpireGoodsNum),
      }],
    };
  }

  resultConfig.push(intro);
  // 区分是 库存搜索、还是货架搜索
  if (type === WAREHOUSE_TYPE.STOREHOUSE) {
    zoneGoods.forEach((zone) => {
      if (!zone.stockNum && !zone.zoneVeneerNum && !zone.zoneAboutToExpireGoodsNum) {
        return;
      }
      resultConfig.push({
        title: zone.zoneName,
        key: zone.zoneCode,
        content: [{
          key: 'stockNum',
          label: '货品总量(箱)',
          value: thousandBitSeparator(zone.stockNum),
        }, {
          key: 'zoneVeneerNum',
          label: '占用板位(板)',
          value: thousandBitSeparator(zone.zoneVeneerNum),
        }, {
          key: 'zoneAboutToExpireGoodsNum',
          label: '过期品数量(箱)',
          value: thousandBitSeparator(zone.zoneAboutToExpireGoodsNum),
        }],
      });
    });
  } else {
    let sortRowGoods = [...rowGoods];
    // 因为有固定排序处理、这里需要添加一次，如果zoneCode顺序改变的情况的处理
    sortRowGoods.sort((a, b) => {
      const tempA = a.rowCode.toUpperCase();
      const tempB = b.rowCode.toUpperCase();

      if (tempA < tempB) {
        return -1;
      }
      if (tempA > tempB) {
        return 1;
      }
      return 0;
    });
    // 如是rowCode是纯数字, 则不展示
    // eslint-disable-next-line max-len
    sortRowGoods = sortRowGoods.filter((config) => Number.isNaN(parseInt(config.rowCode, 10)));
    sortRowGoods.forEach((row) => {
      if (!row.stockNum && !row.rowVeneerNum && !row.aboutToExpireGoodsNum) {
        return;
      }
      resultConfig.push({
        title: `货架${row.rowCode}`,
        key: row.rowCode,
        content: [{
          key: 'stockNum',
          label: '货品总量(箱)',
          value: thousandBitSeparator(row.stockNum),
        }, {
          key: 'rowVeneerNum',
          label: '占用板位(板)',
          value: thousandBitSeparator(row.rowVeneerNum),
        }, {
          key: 'aboutToExpireGoodsNum',
          label: '过期品数量(箱)',
          value: thousandBitSeparator(row.aboutToExpireGoodsNum),
        }],
      });
    });
  }
  return resultConfig;
};

// 组装筛选结果详情数据
export const computedSearchLocationConfig = (searchConfig) => {
  if (!searchConfig) {
    return [];
  }
  const onlyShipperHead = [{
    key: 'goodsStockNum',
    label: '货品总量(箱)',
    value: thousandBitSeparator(searchConfig.goodsStockNum || 0),
  }, {
    key: 'goodsVeneerNum',
    label: '占用板位(板)',
    value: thousandBitSeparator(searchConfig.goodsVeneerNum || 0),
  }, {
    key: 'aboutToExpireGoodsNum',
    label: '过期品数量(箱)',
    value: thousandBitSeparator(searchConfig.aboutToExpireGoodsNum || 0),
  }];
  const hasGoodsHead = [{
    key: 'goodsCode',
    label: '货品编码',
    value: searchConfig.goodsCode,
  }, {
    key: 'spec',
    label: '规格',
    value: searchConfig.spec,
  }, {
    key: 'shelfLife',
    label: '保质期',
    value: searchConfig.shelfLife ? `${searchConfig.shelfLife}天` : '',
  }];
  const resultConfig = [];
  const intro = {
    title: searchConfig.shipperName,
    goods: searchConfig.goodsName, // 商品信息
    key: 'headLine',
    content: searchConfig.goodsName ? hasGoodsHead : onlyShipperHead,
  };
  resultConfig.push(intro);
  const { storehouseGoods = [] } = searchConfig;

  // 组装子数据
  if (searchConfig.goodsName) {
    storehouseGoods.forEach((zone) => {
      resultConfig.push({
        title: zone.locationCode,
        key: zone.locationCode,
        content: [{
          key: 'batchNo',
          label: '客户批次',
          value: zone.batchNo,
        }, {
          key: 'stockBigUnitNum',
          label: '库存数量',
          value: thousandBitSeparator(zone.stockBigUnitNum),
        }, {
          key: 'productDate',
          label: '生产日期',
          value: zone.productDate ? moment(zone.productDate).format('YYYY-MM-DD') : '',
        }, {
          key: 'expireDate',
          label: '到期日期',
          value: zone.expireDate ? moment(zone.expireDate).format('YYYY-MM-DD') : '',
        }, {
          key: 'upperShelfUserName',
          label: '上架员',
          value: zone.upperShelfUserName,
        }, {
          key: 'offShelfUserName',
          label: '下架员',
          value: zone.offShelfUserName,
        }],
      });
    });
  } else {
    storehouseGoods.forEach((zone) => {
      resultConfig.push({
        title: zone.locationCode,
        key: zone.locationCode,
        content: [{
          key: 'goodsName',
          label: '货品名称',
          value: zone.goodsName,
          goodsId: zone.goodsId || null,
        }, {
          key: 'productDate',
          label: '生产日期',
          value: zone.productDate ? moment(zone.productDate).format('YYYY-MM-DD') : '',
        }, {
          key: 'stockBigUnitNum',
          label: '货品数量(箱)',
          value: thousandBitSeparator(zone.stockBigUnitNum),
        }],
      });
    });
  }

  return resultConfig;
};

// 组装库区详情数据格式
export const computedStoreConfig = (storeConfig) => {
  if (!storeConfig) {
    return StoreDataConfig;
  }
  const resultConfig = [...StoreDataConfig];

  // 库位使用率/占用板位
  computedArray(resultConfig, 'location', true, storeConfig.locationUsingRateList, storeConfig.veneerNumList);
  // 当日入库数量（EA）/板位
  computedArray(resultConfig, 'todayPutIn', false, storeConfig.todayReceiptNumList, storeConfig.todayReceiptVeneerNumList);
  // 当日出库数量（EA）/板位
  computedArray(resultConfig, 'todayOutIn', false, storeConfig.todayDeliverNumList, storeConfig.todayDeliverVeneerNumList);
  // 临期品占比
  computedSinglePropArray(resultConfig, 'temporaryProduct', storeConfig.aboutToExpireGoodsRateList);
  // 货品库存数量（EA）
  computedArray(resultConfig, 'goodsStockList', false, storeConfig.goodsStockList);
  // 货主列表
  resultConfig.push({
    key: 'shipperList',
    content: storeConfig?.shipperList ?? [],
  });
  // 货主数量赋值
  resultConfig[0].detail = thousandBitSeparator(storeConfig.shipperNum);
  resultConfig[0].content[0].value = thousandBitSeparator(storeConfig.lessThanCarloadShipperNum);
  resultConfig[0].content[1].value = thousandBitSeparator(storeConfig.contractShipperNum);

  return resultConfig;
};

// 组装库区画板数据格式
// eslint-disable-next-line max-len
export const computedStorePlaneConfig = (storePlaneConfig, storeConfig = [], waring = STORE_GOODS_WARING.NORMAL) => {
  if (!storePlaneConfig) {
    return [];
  }
  const resultConfig = [];
  storePlaneConfig.forEach((config, index) => {
    const obj = storeConfig.find((item) => item.zoneCode === config.zoneCode);
    // 最大只添加七个
    if (index > 1) {
      return;
    }
    // eslint-disable-next-line max-len
    const needShowDialog = waring === STORE_GOODS_WARING.ACTIVE ? config.stockNum !== 0 || config.zoneVeneerNum !== 0 : true;
    // eslint-disable-next-line max-len
    const isError = waring === STORE_GOODS_WARING.ACTIVE ? config.zoneAboutToExpireGoodsNum > 0 : false;
    // 根据zoneCode进行顺序查找、这里的zoneCode后端一定是位置不变的
    const position = STORE_PLANE_ORDER.findIndex((code) => code === config.zoneCode);
    const temp = {
      id: config.zoneCode, // 库区编码
      // title: config.zoneName, // 库区名称
      title: index === 0 ? '冷冻库' : '冷藏库', // 库区名称
      x: StoreHouseConfig[position].x, // 库位位置
      y: StoreHouseConfig[position].y, // 库位位置
      type: needShowDialog ? STORE_GOODS_TYPE.NORMAL : STORE_GOODS_TYPE.HIDDEN, // 1.normal 2.hidden
      state: STORE_GOODS_STATE.NORMAL, // 1.normal 2.active
      waring: needShowDialog ? waring : STORE_GOODS_WARING.NORMAL, // 选中态
      error: isError ? STORE_GOODS_STATE.ACTIVE : STORE_GOODS_STATE.NORMAL, // 筛选状态中 包含 过期态
      isDisabled: obj ? !obj.showWarehouseRow : false, // 是否禁止点入下层
      isHover: false, // 鼠标移入
      // eslint-disable-next-line max-len
      isNear: config.zoneAboutToExpireGoodsNum === 0 && config.zoneAboutToStopWarningStockNum > 0, // 是否临期
      isExpired: config.zoneAboutToExpireGoodsNum > 0, // 是否到期
      content: [
        {
          label: '库存数量(箱): ',
          value: thousandBitSeparator(config.stockNum), // 货品数量
        },
        {
          label: '库存重量(KG): ',
          value: thousandBitSeparator(config.weight), // 货品板位
        },
        {
          label: '占用板位: ',
          value: `${thousandBitSeparator(config.zoneVeneerNum)} / ${multiply(config.veneerNumRate, 100)}%`, // 货品板位
        },
      ],
    };
    resultConfig.push(temp);
  });
  return resultConfig;
};

// 组装库区搜索结构数据格式 （公用结构）
export const computedSearchConfig = (searchConfig) => {
  if (!searchConfig) {
    return [];
  }
  const resultConfig = [...StoreSearchConfig];

  // 货主数量赋值
  resultConfig[0].detail = thousandBitSeparator(searchConfig.shipperNum);
  resultConfig[0].content[0].value = thousandBitSeparator(searchConfig.lessThanCarloadShipperNum);
  resultConfig[0].content[1].value = thousandBitSeparator(searchConfig.contractShipperNum);

  // 库位使用率/占用板位
  computedArray(resultConfig, 'location', true, searchConfig.locationUsingRateList, searchConfig.veneerNumList);
  // 当日入库数量（EA）/板位
  computedArray(resultConfig, 'todayPutIn', false, searchConfig.todayReceiptNumList, searchConfig.todayReceiptVeneerNumList);
  // 当日出库数量（EA）/板位
  computedArray(resultConfig, 'todayOutIn', false, searchConfig.todayDeliverNumList, searchConfig.todayDeliverVeneerNumList);
  // 临期品占比
  computedSinglePropArray(resultConfig, 'temporaryProduct', searchConfig.aboutToExpireGoodsRateList);
  // 货品库存数量（EA）
  computedArray(resultConfig, 'goodsStockList', false, searchConfig.goodsStockList);

  // 货主列表
  resultConfig.push({
    key: 'shipperList',
    content: searchConfig?.shipperList ?? [],
  });

  return resultConfig;
};

// 组装单个库区详情页
export const computedLocationConfig = (locationConfig) => {
  if (!locationConfig) {
    return [];
  }
  const resultConfig = [];
  locationConfig.forEach((config) => {
    const intro = {
      title: config.shipperName,
      goods: config.goodsName, // 商品信息
      stockNumStr: config.stockNumStr, // 库存数量
      key: config.batchNo,
      content: [{
        key: 'goodsCode',
        label: '货品编码',
        value: config.goodsCode,
      }, {
        key: 'spec',
        label: '规格',
        value: config.spec,
      }, {
        key: 'shelfLife',
        label: '保质期',
        value: config.shelfLife ? `${config.shelfLife}天` : '-',
      }, {
        key: 'batchNo',
        label: '客户批次',
        value: config.batchNo,
      }, {
        key: 'productDate',
        label: '生产日期',
        value: config.productDate ? moment(config.productDate).format('YYYY-MM-DD') : '',
      }, {
        key: 'expireDate',
        label: '到期日期',
        value: config.expireDate ? moment(config.expireDate).format('YYYY-MM-DD') : '',
      }, {
        key: 'locationCode',
        label: '所处库位',
        value: config.locationCode,
      }, {
        key: 'aboutToExpireGoodsNum',
        label: '上架员',
        value: config.upperShelfUserName,
      }, {
        key: 'aboutToExpireGoodsNum',
        label: '下架员',
        value: config.offShelfUserName,
      }],
    };
    resultConfig.push(intro);
  });

  return resultConfig;
};

// 组装货架画板数据格式
// eslint-disable-next-line max-len
export const computedShelvesPlaneConfig = (shelvesPlaneConfig, waring = STORE_GOODS_WARING.NORMAL) => {
  if (!shelvesPlaneConfig) {
    return [];
  }
  let sortShelvesConfig = [...shelvesPlaneConfig];
  // 因为有固定排序处理、这里需要添加一次，如果zoneCode顺序改变的情况的处理
  sortShelvesConfig.sort((a, b) => {
    const tempA = a.rowCode.toUpperCase();
    const tempB = b.rowCode.toUpperCase();

    if (tempA < tempB) {
      return -1;
    }
    if (tempA > tempB) {
      return 1;
    }
    return 0;
  });
  const filterZm = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'Z'];
  // 如是rowCode是纯数字, 则不展示
  // eslint-disable-next-line max-len
  sortShelvesConfig = sortShelvesConfig.filter((config) => filterZm.includes(config.rowCode.trim()) && Number.isNaN(parseInt(config.rowCode, 10)));
  const resultConfig = [];
  if (window.location.href.indexOf('store=zoneY') !== -1) {
    sortShelvesConfig = sortShelvesConfig.filter((config) => config.rowCode === 'Z');
  }
  sortShelvesConfig.forEach((config, index) => {
    if (index > 8) {
      return;
    }
    // eslint-disable-next-line max-len
    const isError = waring === STORE_GOODS_WARING.ACTIVE ? config.aboutToExpireGoodsNum > 0 : false;
    // eslint-disable-next-line max-len
    const needShowDialog = waring === STORE_GOODS_WARING.ACTIVE ? config.stockNum !== 0 || config.rowVeneerNum !== 0 : true;

    let ShelvesConfigX = ShelvesConfig[index].x;
    let ShelvesConfigY = ShelvesConfig[index].y;
    // 本版本特殊处理代码，当选中指定货架时，维护一个坐标点的卡片
    if (window.location.href.indexOf('store=zoneY') !== -1) {
      const newShelvesConfig = ShelvesConfig.filter((item) => item.id === 11);
      ShelvesConfigX = newShelvesConfig[0].x;
      ShelvesConfigY = newShelvesConfig[0].y;
    }

    const temp = {
      id: config.rowCode, // 库区编码
      title: `货架${config.rowCode}`, // 库区名称
      x: ShelvesConfigX, // 库位位置
      y: ShelvesConfigY, // 库位位置
      type: needShowDialog ? STORE_GOODS_TYPE.NORMAL : STORE_GOODS_TYPE.HIDDEN, // 1.normal 2.hidden
      state: STORE_GOODS_STATE.NORMAL, // 1.normal 2.active
      waring: needShowDialog ? waring : STORE_GOODS_WARING.NORMAL, // 选中态
      error: isError ? STORE_GOODS_STATE.ACTIVE : STORE_GOODS_STATE.NORMAL, // 筛选状态中 包含 过期态
      isDisabled: false, // 是否禁止点入下层
      isHover: false, // 鼠标移入
      isNear: config.aboutToExpireGoodsNum === 0 && config.aboutToStopWarningStockNum > 0, // 是否临期
      isExpired: config.aboutToExpireGoodsNum > 0, // 是否到期
      content: [
        {
          label: '库存数量(箱): ',
          value: thousandBitSeparator(config.stockNum), // 货品数量
        },
        {
          label: '库存重量(KG): ',
          value: thousandBitSeparator(config.weight), // 货品板位
        },
        {
          label: '占用板位: ',
          value: `${thousandBitSeparator(config.rowVeneerNum)} / ${multiply(config.veneerNumRate, 100)}%`, // 货品板位
        },
      ],
    };
    resultConfig.push(temp);
  });

  return resultConfig;
};

// 组装库位数据
// eslint-disable-next-line max-len
export const computedLocationPlaneConfig = (locationPlaneConfig, waring = STORE_GOODS_WARING.NORMAL) => {
  let results = [];
  locationPlaneConfig.forEach((config) => {
    // eslint-disable-next-line max-len
    const needShowDialog = waring === STORE_GOODS_WARING.ACTIVE ? config.stockNum !== 0 || config.storehouseVeneerNum !== 0 : true;
    const index = results.findIndex((res) => res.x === config.col);
    const param = {
      x: config.col,
      y: config.layer,
      code: config.locationCode,
      id: config.locationId,
      state: STORE_GOODS_TYPE.NORMAL,
      waring: needShowDialog ? waring : STORE_GOODS_WARING.NORMAL, // 选中态
      expire: config.aboutToExpireGoodsNum !== 0, // 是否含有过期品
      empty: config.stockNum === 0 && config.storehouseVeneerNum === 0, // 是否为空
      stock: config.stockNum,
      storehouseVeneerNum: config.storehouseVeneerNum,
      aboutToExpireGoodsNum: config.aboutToExpireGoodsNum,
      aboutToStopWarningStockNum: config.aboutToStopWarningStockNum,
    };
    const temp = {
      x: config.col,
      content: [param],
    };
    if (index === -1) {
      results.push(temp);
    } else {
      results[index].content.push(param);
    }
  });
  // 进行两次顺序排序, 一次是一维数组排序, 一次是每个二维数组排序
  results = ObjectArrayNumberSort(results, 'x');
  results = results.map((config) => ({
    ...config,
    content: ObjectArrayNumberSort(config.content, 'y'),
  }));
  return results;
};
