<template>
  <div class="store-preview">
    <div class="left-disable">
      <span class="horizontal" />
      <div class="vertical-list">
        <span class="dot-item" />
        <span class="dot-item" />
        <span class="dot-item" />
      </div>
      <span class="horizontal" />
    </div>
    <div class="right-active">
      <span
        v-for="(item) in positionData"
        :key="item.id"
        class="horizontal"
        :class="{
          'active': item.state === STORE_GOODS_STATE.ACTIVE,
        }"
        @click="handleChangeKey(item.id)"
      />
    </div>
  </div>
</template>

<script>
import { STORE_GOODS_STATE } from '../../const';

export default {
  name: 'StorePreview',
  props: {
    positionKey: {
      type: String,
      default: '',
    },
    positionData: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['changeKey'],
  data() {
    return {
      STORE_GOODS_STATE,
    };
  },
  methods: {
    handleChangeKey(key) {
      if (key === this.positionKey) {
        return;
      }
      this.$emit('changeKey', key);
    },
  },
};
</script>

<style lang="scss" scoped>
/* 库区切换 */
.store-preview {
  display: flex;
  justify-content: flex-start;

  .left-disable {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    .horizontal {
      width: 0.63em;
      height: 0.1em;
      background: #f7f8fa;
      border-radius: 2px;
    }

    .vertical-list {
      margin: 0.05em 0;
      display: flex;
      justify-content: space-between;

      .dot-item {
        width: 0.17em;
        height: 0.38em;
        background: #f7f8fa;
        border-radius: 2px;
      }
    }
  }

  .right-active {
    margin-left: 0.06em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .horizontal {
      width: 0.41em;
      height: 0.13em;
      background-color: #ebedf0;
      border-radius: 2px;
      cursor: pointer;

      &.active {
        background-color: #06b880;
      }
    }
  }
}
</style>
