<template>
  <div class="header-navbar-root">
    <div class="header-navbar">
      <img
        class="logo"
        src="../assets/header-bar/logo.png"
        alt="ylz-logo"
      >
    </div>
    <div class="bread-crumb">
      <p
        v-for="(item) in nowBreadList"
        :key="item.level"
        class="item"
      >
        <span
          v-if="item.level === WAREHOUSE_TYPE.HOME ||
            (item.level === WAREHOUSE_TYPE.STOREHOUSE && isIframe && !iframeOfOTMS)"
          class="icon"
        />
        <span
          class="title"
          :class="{'active': item.level === level}"
          @click="handleRoute(item.level)"
        >
          {{ item.title }}
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import {
  WAREHOUSE_TYPE,
  WAREHOUSE_TYPE_MAP,
  IFRAME_TAG,
} from '../const';

export default {
  name: 'HeaderNav',
  props: {
    /* 面包屑层级 1 - 4 */
    level: {
      type: Number,
      default: WAREHOUSE_TYPE.HOME,
    },
    isIframe: {
      type: Boolean,
      default: false,
    },
    /* iframe的类型 */
    iframeTag: {
      type: String,
      default: '',
    },
  },
  emits: ['levelChange'],
  data() {
    return {
      WAREHOUSE_TYPE,
    };
  },
  computed: {
    iframeOfOTMS() {
      return this.iframeTag === IFRAME_TAG.OTMS;
    },
    breadList() {
      const bread = [
        {
          level: WAREHOUSE_TYPE.HOME,
          title: WAREHOUSE_TYPE_MAP[WAREHOUSE_TYPE.HOME],
        },
        {
          level: WAREHOUSE_TYPE.STOREHOUSE,
          title: WAREHOUSE_TYPE_MAP[WAREHOUSE_TYPE.STOREHOUSE],
        },
        {
          level: WAREHOUSE_TYPE.SHELVES,
          title: WAREHOUSE_TYPE_MAP[WAREHOUSE_TYPE.SHELVES],
        },
        {
          level: WAREHOUSE_TYPE.LOCATION,
          title: WAREHOUSE_TYPE_MAP[WAREHOUSE_TYPE.LOCATION],
        },
      ];
      // 是外嵌页面时、使用iframe
      if (this.isIframe && !this.iframeOfOTMS) {
        bread.shift();
      }
      return bread;
    },
    nowBreadList() {
      // 当层级只有主页时，不展示
      if (this.level === WAREHOUSE_TYPE.HOME) {
        return [];
      }
      const index = this.breadList.findIndex((_) => _.level === this.level);
      if (index === -1) {
        return [this.breadList[0]];
      }
      return this.breadList.slice(0, index + 1);
    },
  },
  methods: {
    handleRoute(level) {
      if (this.level === level) {
        return;
      }
      this.$emit('levelChange', { level });
    },
  },
};
</script>

<style lang="scss" scoped>
.header-navbar-root {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  width: 100%;
  height: 0.88em;
  z-index: 100;
  border-radius: 0 0 0.2em 0.2em;
  padding: 0.24em 0.32em;

  .header-navbar {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .logo {
      margin-right: 0.16em;
      width: 5em;
      height: 0.48em;
    }
  }

  .bread-crumb {
    display: flex;
    justify-content: flex-start;

    .item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      margin-left: 0.32em;

      .icon {
        margin-right: 0.06em;
        width: 0.12em;
        height: 0.12em;
        background: url("../assets/header-bar/home-icon.png") center/cover no-repeat;
      }

      .title {
        color: #969799;
        font-size: 0.14em;
        cursor: pointer;

        &:hover {
          color: #323233;
        }
      }

      .active {
        color: #323233;
      }

      &::after {
        position: absolute;
        content: '';
        left: -0.2em;
        top: 0.06em;
        width: 0.07em;
        height: 0.07em;
        border-top: 1px solid #c8c9cc;
        border-right: 1px solid #c8c9cc;
        border-bottom: transparent;
        border-left: transparent;
        transform: rotate(45deg);
      }

      &:first-child {
        margin-left: 0;

        &::after {
          display: none;
        }
      }
    }
  }
}
</style>
