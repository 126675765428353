<template>
  <div class="shelves-preview">
    <div class="horizontal-list">
      <span
        v-for="(item) in horizontalList"
        :key="item.id"
        class="horizontal"
        :class="{
          'active': item.state === STORE_GOODS_STATE.ACTIVE,
        }"
        @click="handleChangeKey(item.id)"
      />
    </div>

    <div class="vertical-list">
      <span
        v-for="(item) in verticalList"
        :key="item.id"
        class="vertical"
        :class="{
          'active': item.state === STORE_GOODS_STATE.ACTIVE,
        }"
        @click="handleChangeKey(item.id)"
      />
    </div>
  </div>
</template>

<script>
import { STORE_GOODS_STATE } from '../../const';

export default {
  name: 'ShelvesPreview',
  props: {
    positionKey: {
      type: String,
      default: '',
    },
    positionData: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['changeKey'],
  data() {
    return {
      STORE_GOODS_STATE,
    };
  },
  computed: {
    horizontalList() {
      if (this.positionData.length === 0) {
        return [];
      }
      return this.positionData.slice(0, 2);
    },
    verticalList() {
      if (this.positionData.length === 0) {
        return [];
      }
      return this.positionData.slice(2, this.positionData.length);
    },
  },
  methods: {
    handleChangeKey(key) {
      if (key === this.positionKey) {
        return;
      }
      this.$emit('changeKey', key);
    },
  },
};
</script>

<style lang="scss" scoped>
.shelves-preview {
  display: flex;
  justify-content: flex-start;

  .horizontal-list {
    display: flex;
    justify-content: flex-start;

    .horizontal {
      position: relative;
      width: 0.04em;
      height: 0.74em;
      background-color: #ebedf0;
      cursor: pointer;

      &::after {
        position: absolute;
        content: '';
        width: 0.06em;
        height: 0.1em;
        background-color: #fff;
        top: 0.26em;
        left: -0.01em;
      }

      &.active {
        background-color: #06b880;
      }

      &:last-child {
        margin-left: 0.02em;
      }
    }
  }

  .vertical-list {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-left: 0.06em;

    .vertical {
      width: 0.94em;
      height: 0.04em;
      background-color: #ebedf0;
      border-radius: 1px;
      cursor: pointer;

      &:first-child {
        margin-bottom: 0.06em;
      }

      &:nth-child(4) {
        margin-bottom: 0.06em;
      }

      &:nth-child(6) {
        margin-bottom: 0.06em;
      }

      &:nth-child(9) {
        margin-bottom: 0.06em;
      }

      &.active {
        background-color: #06b880;
      }
    }
  }
}
</style>
