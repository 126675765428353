<template>
  <div class="location-dom-root">
    <div class="location-dom">
      <p
        v-if="topGoods.length > 0"
        class="head-title"
      >
        1-22列
      </p>
      <div class="layer-list">
        <div
          v-for="(item, itemIndex) in topGoods"
          :key="item.x"
          class="layer"
        >
          <p class="column">
            <span>
              {{ 1 + itemIndex }}列
            </span>
          </p>
          <div
            v-for="(good, goodIndex) in item.content"
            :key="good.id"
            class="row"
          >
            <p
              v-if="itemIndex === 0"
              class="title"
            >
              <span>
                第{{ goodIndex + 1 }}层
              </span>
            </p>
            <p
              class="goods-item"
              :class="{
                'active':good.state === STORE_GOODS_STATE.ACTIVE,
                'tips': good.waring === STORE_GOODS_WARING.ACTIVE,
                'empty': good.empty,
                'near': good.aboutToStopWarningStockNum > 0,
                'expire': good.aboutToExpireGoodsNum > 0
              }"
              @click="choiceOneGoods(good)"
            >
              <span class="item-detail">
                <span class="text">
                  {{ good.code }}
                </span>
              </span>
            </p>
          </div>
        </div>
      </div>
      <p
        v-if="footerGoods.length > 0"
        class="head-title"
      >
        23-44列
      </p>
      <div
        v-if="footerGoods.length > 0"
        class="layer-list"
      >
        <div
          v-for="(item, itemIndex) in footerGoods"
          :key="item.x"
          class="layer"
        >
          <p class="column">
            <span>
              {{ 23 + itemIndex }}列
            </span>
          </p>
          <div
            v-for="(good, goodIndex) in item.content"
            :key="good.id"
            class="row"
          >
            <p
              v-if="itemIndex === 0"
              class="title"
            >
              <span>
                第{{ goodIndex + 1 }}层
              </span>
            </p>
            <p
              class="goods-item"
              :class="{
                'active':good.state === STORE_GOODS_STATE.ACTIVE,
                'tips': good.waring === STORE_GOODS_WARING.ACTIVE,
                'empty': good.empty,
                'near': good.aboutToStopWarningStockNum > 0,
                'expire': good.aboutToExpireGoodsNum > 0
              }"
              @click="choiceOneGoods(good)"
            >
              <span class="item-detail">
                <span class="text">
                  {{ good.code }}
                </span>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="topGoods.length > 0"
      class="footer-state-list"
    >
      <p class="has-goods">
        <span class="f-text">有货</span>
      </p>
      <p class="no-goods">
        <span class="f-text">无货</span>
      </p>
      <p class="near">
        <span class="f-text">临期品</span>
      </p>
      <p class="no-date">
        <span class="f-text">过期品</span>
      </p>
      <p class="result">
        <span class="f-text">筛选结果</span>
      </p>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus';
import { STORE_GOODS_STATE, STORE_GOODS_WARING } from '../const';

export default {
  name: 'Location',
  props: {
    warehouseConfig: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['handleClickStore', 'resetSearchCondition', 'setChoiceKey'],
  data() {
    return {
      STORE_GOODS_STATE,
      STORE_GOODS_WARING,
      goods: [],
    };
  },
  computed: {
    topGoods() {
      if (this.goods.length <= 0) {
        return [];
      }
      return this.goods.slice(0, 22);
    },
    footerGoods() {
      if (this.goods.length <= 22) {
        return [];
      }
      return this.goods.slice(22);
    },
  },
  watch: {
    warehouseConfig: {
      deep: true,
      handler() {
        if (!this.warehouseConfig) {
          return;
        }
        this.$nextTick(() => {
          this.goods = [...this.warehouseConfig];
        });
      },
    },
  },
  mounted() {
  },
  methods: {
    choiceOneGoods(goods) {
      if (goods.empty) {
        ElMessage({
          type: 'warning',
          message: '该库位无货',
        });
        return;
      }
      // 1.根据goods中的x、y 定位
      const { x, y } = goods;
      const X = this.goods.findIndex((good) => good.x === x);
      const Y = this.goods[X].content.findIndex((good) => good.y === y);

      // 如果存在waring, 则是筛选状态
      // const array = this.goodsWaringArr();
      // if (array.length) {
      //   this.$emit('resetSearchCondition', {
      //     query: {
      //       loc: goods.id,
      //     },
      //   });
      //
      //   // 清除筛选值
      //   this.resetLocalDataParams('waring', STORE_GOODS_WARING.NORMAL);
      //   this.goods[X].content[Y].state = STORE_GOODS_STATE.ACTIVE;
      //   this.$emit('handleClickStore', goods);
      //   return;
      // }

      if (this.goods[X].content[Y].state === STORE_GOODS_STATE.ACTIVE) {
        this.goods[X].content[Y].state = STORE_GOODS_STATE.NORMAL;
        this.$emit('handleClickStore', null);
        this.$emit('setChoiceKey', '');
        return;
      }
      // 2. 改变goods数组 中state, 类判断成激活状态
      this.resetLocalDataParams('state', STORE_GOODS_STATE.NORMAL);
      this.goods[X].content[Y].state = STORE_GOODS_STATE.ACTIVE;
      this.$emit('handleClickStore', goods);
      this.$emit('setChoiceKey', goods.id);
    },
    resetLocalDataParams(key, value) {
      this.goods.forEach((goodsItem, goodsIndex) => {
        goodsItem.content.forEach((good, goodIndex) => {
          this.goods[goodsIndex].content[goodIndex][key] = value;
        });
      });
    },
    goodsWaringArr() {
      const waringArr = [];
      // 改变goods数组 中state, 类判断成激活状态
      this.goods.forEach((goodsItem, goodsIndex) => {
        goodsItem.content.forEach((good, goodIndex) => {
          if (this.goods[goodsIndex].content[goodIndex].waring === STORE_GOODS_WARING.ACTIVE) {
            waringArr.push(this.goods[goodsIndex]);
          }
        });
      });
      return waringArr;
    },
  },
};
</script>

<style lang="scss" scoped>
  .location-dom-root {
    width: 100%;
    height: calc(100% - 2.9em);
    position: relative;
    margin-top: 2.1em;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      &::-webkit-scrollbar-track-piece {
        -webkit-border-radius: 0;
      }

      &::-webkit-scrollbar-track { background-color: #eee; } /* 滚动条的滑轨背景颜色 */
      &::-webkit-scrollbar-button { background-color: #eee; } /* 滑轨两头的监听按钮颜色 */
      &::-webkit-scrollbar-corner {
        background-color: #eee;
      } /* 横向滚动条和纵向滚动条相交处尖角的颜色 */

      &::-webkit-scrollbar-thumb {
        height: 30px;
        -webkit-border-radius: 2px;
        background-color: rgba(0, 0, 0, 0.2);
      }

      &-webkit-scrollbar-thumb:hover {
        height: 50px;
        -webkit-border-radius: 4px;
      }

    .location-dom {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      z-index: 100;

      .head-title {
        color: #323233;
        font-size: 0.2em;
        font-weight: 600;
      }

      .layer-list {
        display: flex;
        justify-content: flex-start;
        padding-left: 0.48em;
        margin-bottom: 0.3em;

        .layer {
          display: flex;
          flex-direction: column-reverse;
          align-items: center;
          margin-left: 0.15em;

          &:first-child {
            margin-left: 0;
          }

          .column {
            margin-top: 0.08em;

            span {
              display: block;
              font-size: 0.12em;
            }
          }

          .row {
            position: relative;
            margin-top: 0.16em;

            .title {
              position: absolute;
              left: -0.48em;
              top: 0.1em;
              line-height: 0.18em;

              span {
                display: block;
                font-size: 0.12em;
              }
            }

            .goods-item {
              position: relative;
              display: block;
              width: 0.4em;
              height: 0.4em;
              background: #fff;
              border-radius: 0.04em;
              border: 0.01em solid #c8c9cc;
              cursor: pointer;

              .item-detail {
                display: none;
                position: absolute;
                top: -0.54em;
                left: 50%;
                min-width: 0.76em;
                height: 0.4em;
                padding: 0 0.12em;
                line-height: 0.4em;
                background-color: #fff;
                box-shadow: 0 0.02em 0.08em 0 rgba(200, 201, 204, 0.5);
                border-radius: 0.04em;
                z-index: 99;
                transform: translateX(-50%);

                .text {
                  display: block;
                  font-size: 0.14em;
                  font-weight: 600;
                  text-align: center;
                  white-space: nowrap;
                }

                &::after {
                  position: absolute;
                  content: '';
                  bottom: -0.02em;
                  left: 50%;
                  width: 10px;
                  height: 10px;
                  z-index: 100;
                  background-color: #fff;
                  transform: translateX(-50%) rotate(45deg);
                }
              }

              &:hover {
                background-color: rgba(55, 197, 152, 1);
                border: 0.01em solid #37c598;

                .item-detail {
                  display: block;
                  color: #323233;
                  z-index: 100;
                }
              }
            }

            .goods-item.active {
              background: #06b880;
              box-shadow: 0 0.05em 0.07em 0.01em rgba(6, 184, 128, 0.3);
              border-radius: 0.04em;
              border: 0.01em solid #06b880;

              .item-detail {
                display: block;
                color: #06b880;
              }
            }

            /* 货架有货 */
            .tips {
              background: yellow;
              box-shadow: 0 0.05em 0.07em 1px rgba(255, 128, 48, 0.3);
              border-radius: 0.04em;
              border: 1px solid yellow;
            }

            .empty {
              background: #eaeaea;
              border-radius: 0.04em;
              border: 1px solid #c8c9cc;
            }

            .near {
              background: #ff8030;
              border-radius: 0.04em;
              border: 1px solid #ff8030;
            }

            .expire {
              background: #f5222d;
              box-shadow: 0 0.05em 0.07em 0.01em rgba(245, 34, 45, 0.3);
              border-radius: 0.04em;
              border: 0.01px solid #f5222d;
            }
          }
        }
      }
    }

    .footer-state-list {
      position: fixed;
      left: 0.54em;
      bottom: 0.6em;
      display: flex;
      justify-content: flex-start;
      .has-goods,
      .no-goods,
      .no-date,
      .near,
      .result {
        position: relative;
        margin-right: 0.24em;
        padding-left: 0.2em;
        line-height: 0.2em;

        .f-text {
          display: block;
          font-size: 0.12em;
          color: #303233;
        }
      }

      .has-goods {
        &::after {
          position: absolute;
          left: 0;
          top: 0.04em;
          content: '';
          width: 0.12em;
          height: 0.12em;
          background-color: #fff;
          border: 0.01em solid #c8c9cc;
          border-radius: 0.02em;
        }
      }

      .no-goods {
        &::after {
          position: absolute;
          left: 0;
          top: 0.04em;
          content: '';
          width: 0.12em;
          height: 0.12em;
          background-color: #eaeaea;
          border: 0.01em solid #c8c9cc;
          border-radius: 0.02em;
        }
      }

      .no-date {
        &::after {
          position: absolute;
          left: 0;
          top: 0.04em;
          content: '';
          width: 0.12em;
          height: 0.12em;
          background-color: #f5222d;
          border: 0.01em solid #f5222d;
          border-radius: 0.02em;
          box-shadow: 0 0.02em 0.07em 0.01em rgba(245, 34, 45, 0.3);
        }
      }

      .result {
        &::after {
          position: absolute;
          left: 0;
          top: 0.04em;
          content: '';
          width: 0.12em;
          height: 0.12em;
          background-color: yellow;
          border: 0.01em solid yellow;
          border-radius: 0.02em;
          box-shadow: 0 0.02em 0.07em 0.01em rgba(255, 128, 48, 0.3);
        }
      }

      .near {
        &::after {
          position: absolute;
          left: 0;
          top: 0.04em;
          content: '';
          width: 0.12em;
          height: 0.12em;
          background-color: #ff8030;
          border: 0.01em solid #ff8030;
          border-radius: 0.02em;
          box-shadow: 0 0.02em 0.07em 0.01em rgba(255, 128, 48, 0.3);
        }
      }
    }
  }
</style>
