<template>
  <div
    v-if="detailItem"
    class="item"
    :class="{'is-goods-detail': hasGoods}"
  >
    <div
      class="title-wrap"
      :class="{'warp-color': hasGoods}"
    >
      <div
        class="title"
      >
        <span>{{ hasGoods ? detailItem.goods : detailItem?.title }}</span>
        <a
          v-if="compItem.detail"
          class="title-detail"
        >
          总计: <span class="spe-num">{{ compItem.detail }}</span>
        </a>
        <a
          v-if="compItem.detail && WAREHOUSE_TYPE.HOME !== level"
          class="show-detail-list"
          @click="handleToNextPage"
        >
          <span class="text">查看货主列表</span>
        </a>
        <a
          v-if="compItem.stockNumStr"
          class="stock-num"
        >
          {{ compItem.stockNumStr }}
        </a>
      </div>
      <div
        v-if="hasGoods"
        class="goods"
      >
        <img
          class="goods-icon"
          src="../../assets/detail-comp/goods.png"
          alt="icon"
        >
        <span>{{ detailItem.title }}</span>
      </div>
    </div>
    <div
      v-if="!hasGoods"
      class="detail"
    >
      <p
        v-for="(item) in compItem.content"
        :key="item.key"
        class="detail-item"
      >
        <template v-if="item?.percentKey === 'WORD_BREAK'">
          <span
            v-for="(valueItem) in item.value"
            :key="valueItem"
            class="value"
          >
            {{ valueItem }}
          </span>
        </template>
        <span v-else class="value"> {{ item.value }}</span>
        <span class="label">{{ item.label }}</span>
      </p>
    </div>
    <div
      v-else
      class="goods-detail"
    >
      <p
        v-for="(item) in compItem.content"
        :key="item.key"
        class="goods-item"
      >
        <span class="label">{{ item.label }}：</span>
        <span class="value"> {{ item.value }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import { WAREHOUSE_TYPE } from '../../const';

export default {
  name: 'DetailItem',
  props: {
    detailItem: {
      type: Object,
      default: null,
    },
    level: {
      type: Number,
      default: null,
    },
  },
  emits: ['handleShowCustomer'],
  data() {
    return {
      WAREHOUSE_TYPE,
      compItem: {},
    };
  },
  computed: {
    hasGoods() {
      return !!this.detailItem?.goods;
    },
  },
  watch: {
    detailItem: {
      deep: true,
      immediate: true,
      handler() {
        this.compItem = this.detailItem;
      },
    },
  },
  methods: {
    handleToNextPage() {
      this.$emit('handleShowCustomer');
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  padding: 0.24em;

  .title-wrap {
    .goods {
      margin-top: 0.12em;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .goods-icon {
        margin-right: 0.02em;
        width: 0.16em;
        height: 0.16em;
      }

      span {
        font-size: 0.14em;
        color: #646566;
      }
    }

    .title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 0.12em;
      position: relative;
      color: #646566;
      line-height: 0.24em;
      text-align: left;

      &::after {
        position: absolute;
        left: 0;
        top: 0.05em;
        content: '';
        background-color: #06b880;
        width: 0.04em;
        height: 0.14em;
        border-radius: 0.02em;
      }

      span {
        display: block;
        font-size: 0.16em;
      }

      .title-detail {
        margin-left: 16px;
        color: rgba(100, 101, 102, 1);
        font-size: 0.16em;

        .spe-num {
          display: inline-block;
          font-weight: 600;
          font-size: inherit;
          font-family: "DinBold", serif;
          color: #323233;
        }
      }

      .show-detail-list {
        position: relative;
        color: #06b880;
        flex-grow: 1;
        text-align: right;
        padding-right: 0.1em;

        .text {
          font-size: 0.16em;
        }

        &::after {
          position: absolute;
          right: 0;
          top: 0.09em;
          content: '';
          width: 0.06em;
          height: 0.06em;
          border-width: 0.01px;
          border-style: solid;
          border-color: #06b880 #06b880 transparent transparent;
          transform: rotate(45deg);
        }
      }

      .stock-num {
        display: inline-block;
        font-size: 0.14em;
        font-weight: 600;
        font-family: "DinBold", serif;
        color: #323233;
        flex-grow: 1;
        text-align: right;
        padding-right: 0.1em;
      }
    }
  }

  .warp-color {
    padding: 0.16em 0.24em;
    background: #f7f8fa;
    border-radius: 0.12em;

    .title {
      span {
        font-size: 0.2em;
        font-weight: 600;
      }
    }
  }

  .detail {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    .detail-item {
      margin-top: 0.16em;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      flex-shrink: 0;
      width: 50%;

      .label {
        font-size: 0.14em;
        font-weight: 400;
        color: #646566;
        flex-shrink: 0;
      }

      .value {
        margin-bottom: 0.04em;
        display: inline-block;
        font-size: 0.24em;
        font-family: "DinBold", serif;
        font-weight: 600;
        color: #323233;
        word-break: break-all;
        text-align: left;
      }
    }
  }

  .goods-detail {
    display: flex;
    flex-direction: column;
    padding: 0 0.24em;

    .goods-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 0.12em;

      .label {
        font-size: 0.14em;
        font-weight: 400;
        color: #646566;
        flex-shrink: 0;
      }

      .value {
        display: inline-block;
        font-size: 0.16em;
        font-family: "DinBold", serif;
        font-weight: 600;
        color: #323233;
        word-break: break-all;
        text-align: left;
      }
    }
  }
}

.is-goods-detail {
  padding: 0.16em 0;
}
</style>
