<template>
  <div
    v-if="detailItem"
    class="item"
  >
    <div
      class="title"
    >
      <span>{{ detailItem?.title }}</span>
    </div>
    <div class="detail">
      <p
        v-for="(item) in detailItem.content"
        :key="item.label"
        class="detail-item"
      >
        <span
          class="value"
        >
          {{ item.value }}
        </span>
        <span class="label">{{ item.label }}</span>
      </p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DetailItem',
  props: {
    detailItem: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      compItem: {},
    };
  },
  watch: {
    detailItem: {
      deep: true,
      immediate: true,
      handler() {
        this.compItem = this.detailItem;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  padding: 0.24em;

  .goods {
    margin-top: 0.12em;
    margin-bottom: 0.16em;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .goods-icon {
      margin-right: 0.02em;
      width: 0.16em;
      height: 0.16em;
    }

    span {
      font-size: 0.14em;
      color: #646566;
    }
  }

  .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding-left: 0;
    font-weight: 600;
    color: #323233;
    line-height: 0.28em;
    text-align: left;

    span {
      display: block;
      font-size: 0.16em;
    }

    .title-detail {
      margin-left: 16px;
      color: rgba(100, 101, 102, 1);
      font-size: 0.16em;
    }
  }

  .detail {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    .detail-item {
      margin-top: 0.12em;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      flex-shrink: 0;
      width: 39%;

      .label {
        flex-shrink: 0;
        margin-right: 2px;
        font-size: 0.14em;
        font-weight: 400;
        color: #646566;
      }

      .value {
        display: inline-block;
        font-size: 0.24em;
        font-family: "DinBold", serif;
        font-weight: 600;
        color: #323233;
        word-break: break-all;
        text-align: left;
      }

      &:nth-child(3n + 2) {
        width: 22%;
      }
    }
  }
}
</style>
