import {
  CANVAS_HEIGHT,
  CANVAS_WIDTH,
  CANVAS_MULTIPLE,
} from './const';

/**
 * author: He Ding Xiong
 * date: 2021-12-13
 * des: 存放通用方法、划分类
 * */

/**
 * 获取真实比例x、y坐标点
 * @param event
 * @returns {{X: number, Y: number}}
 */
export const getRealCoordinate = (event, moduleName = null) => {
  let xProp = document.documentElement.clientWidth / CANVAS_WIDTH;
  let yProp = document.documentElement.clientHeight / CANVAS_HEIGHT;
  if (moduleName !== null) {
    xProp = document.querySelector(moduleName).clientWidth / CANVAS_WIDTH;
    yProp = document.querySelector(moduleName).clientHeight / CANVAS_HEIGHT;
  }
  const ex = event.offsetX;
  const ey = event.offsetY;

  const X = parseInt(ex / xProp, 10);
  const Y = parseInt(ey / yProp, 10);
  return {
    X,
    Y,
  };
};
/**
 * 是否有key值
 * @param object
 * @returns {boolean}
 */
export const hasExistProperty = (object = {}) => {
  const cloneObj = { ...object };
  // 判断的时候删除仓库编码, 这里包含内嵌逻辑
  if (cloneObj.warehouseCode) {
    delete cloneObj.warehouseCode;
  }
  if (cloneObj.zoneCode) {
    delete cloneObj.zoneCode;
  }
  if (cloneObj.rowCode) {
    delete cloneObj.rowCode;
  }
  const arr = Object.keys(cloneObj);
  return arr.length > 0;
};

/*
* 获取小数位数
*/
export const getMaxDecimalLength = (...val) => {
  // 最大小数位长度
  let maxDecimalLength = 0;

  val.forEach((x) => {
    const strVal = x.toString();
    const dotIndex = strVal.indexOf('.');
    if (dotIndex > -1) {
      // 获取当前值小数位长度
      const curDecimalLength = strVal.length - 1 - dotIndex;

      if (curDecimalLength > maxDecimalLength) {
        maxDecimalLength = curDecimalLength;
      }
    }
  });
  return maxDecimalLength;
};

export const multiply = (...val) => {
  let sum = 0;
  let decimalLengthSum = 0;

  val.forEach((x, index) => {
    // 获取当前小数位长度
    const decimalLength = getMaxDecimalLength(x);
    // 将当前数变为整数
    // eslint-disable-next-line no-restricted-properties
    const nurVal = Math.round(x * Math.pow(10, decimalLength));

    decimalLengthSum += decimalLength;

    if (index === 0) {
      sum = nurVal;
    } else {
      sum *= nurVal;
    }
  });

  // eslint-disable-next-line no-restricted-properties
  return sum / Math.pow(10, decimalLengthSum);
};

/**
 * 时间戳节流
 * @param func 执行函数
 * @param wait 执行频率
 * @returns {function(): void}
 */
export const throttle = (func, wait) => {
  let previous = 0;

  return () => {
    const now = Date.now();
    const context = this;
    if (now - previous > wait) {
      // eslint-disable-next-line no-undef
      func.apply(context, arguments);
      previous = now;
    }
  };
};

/**
 * 防抖
 * @param func
 * @param wait
 */
export const debounce = (func, wait) => {
  let timeout;
  return () => {
    const context = this;
    // eslint-disable-next-line no-undef
    const args = arguments;

    if (timeout) clearTimeout(timeout);

    timeout = setTimeout(() => {
      func.apply(context, args);
    }, wait);
  };
};

/**
 * 计算弧度值
 * @param x
 * @param y
 * @param x1
 * @param y1
 * @returns {number}
 */
export const computedSlope = (x, y, x1, y1) => {
  const tanA = (y1 - y) / (x1 - x);
  return Math.atan(tanA);
};

/**
 * 创建一个Canvas
 * @param width
 * @param height
 * @returns {{canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D}}
 */
export const creatCanvasPanel = (width, height) => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  return {
    canvas,
    ctx,
  };
};

/**
 * 在一象限中，判断是否在范围内 目前只能有矩形定位
 * @param targetX
 * @param targetY
 * @param originX
 * @param originY
 * @param width
 * @param height
 */
export const judgeInBoundary = (targetX, targetY, originX, originY, width, height) => (
  (targetX >= originX && targetX <= originX + width)
  && (targetY >= originY && targetY <= originY + height)
);

/**
 * 根据x,y 计算canvas中原点
 * @param {Number} x x坐标
 * @param {Number} y y坐标
 * @returns {{x: number, y: number}}
 */
export const computedOriginPosition = (x, y) => {
  const scale = document.documentElement.clientWidth / CANVAS_WIDTH;
  return {
    x: parseInt((x / scale).toString(), 10),
    y: parseInt((y / scale).toString(), 10),
  };
};

/**
 * 数字添加千分分隔符
 * @param num
 * @returns {string}
 */
export const thousandBitSeparator = (num) => num.toString().replace(/\d+/,
  (n) => n.replace(/(\d)(?=(\d{3})+$)/g, ($1) => `${$1},`));

/**
 * 图片加载 返回img对象
 * @param url
 * @returns {HTMLImageElement}
 */
export const drawImg = (url) => {
  const img = new Image();
  img.src = url;
  return img;
};

/**
 * 阴影绘制
 * @param ctx 2d画板
 * @param x
 * @param y
 * @param blur
 * @param color
 */
export const drawShadow = (ctx, x, y, blur, color) => {
  // eslint-disable-next-line no-param-reassign
  ctx.shadowOffsetX = x;
  // eslint-disable-next-line no-param-reassign
  ctx.shadowOffsetY = y;
  // eslint-disable-next-line no-param-reassign
  ctx.shadowBlur = blur;
  // eslint-disable-next-line no-param-reassign
  ctx.shadowColor = color;
};

/**
 * 绘制左对齐的标题文案
 * @param ctx canvas2d实例
 * @param content 文本内容
 * @param x x坐标
 * @param y y坐标
 * @param color 颜色
 */
export const drawLeftTitle = (ctx, content, x, y, color) => {
  // eslint-disable-next-line no-param-reassign
  ctx.font = `bold ${16 * CANVAS_MULTIPLE}px "微软雅黑"`;
  // eslint-disable-next-line no-param-reassign
  ctx.fillStyle = color;

  // eslint-disable-next-line no-param-reassign
  ctx.textBaseline = 'top';
  // eslint-disable-next-line no-param-reassign
  ctx.textAlign = 'left';

  ctx.fillText(content, x, y);
};

/**
 * 绘制左对齐的内容文案
 * @param ctx canvas2d实例
 * @param content 文本内容
 * @param num 数字内容
 * @param x x坐标
 * @param y y坐标
 * @param cColor 文本颜色
 * @param nColor 数字颜色
 */
export const drawLeftContent = (ctx, content, num, x, y, cColor, nColor) => {
  // eslint-disable-next-line no-param-reassign
  ctx.font = `normal ${14 * CANVAS_MULTIPLE}px "微软雅黑"`;
  // eslint-disable-next-line no-param-reassign
  ctx.fillStyle = cColor;

  // eslint-disable-next-line no-param-reassign
  ctx.textBaseline = 'top';
  // eslint-disable-next-line no-param-reassign
  ctx.textAlign = 'left';
  ctx.fillText(content, x, y);

  const contentLen = ctx.measureText(content).width;

  // eslint-disable-next-line no-param-reassign
  ctx.font = `normal ${16 * CANVAS_MULTIPLE}px "DinBold"`;
  // eslint-disable-next-line no-param-reassign
  ctx.fillStyle = nColor;

  // 像素多了2 向上偏移 1像素
  ctx.fillText(num, x + contentLen, y - 1);
};

/**
 * 获取文本宽度
 * @param ctx
 * @param content
 * @param fontSize
 * @param fontFamily
 * @returns {number}
 */
export const measureTextWidth = (ctx, content, fontSize, fontFamily = '微软雅黑') => {
  ctx.save();
  // eslint-disable-next-line no-param-reassign
  ctx.font = `normal ${fontSize * CANVAS_MULTIPLE}px "${fontFamily}"`;
  const { width } = ctx.measureText(content);
  ctx.restore();

  return width;
};

// 绘制矩形的高度
const TriangleHeight = 12 * CANVAS_MULTIPLE;

/**
 * 绘制矩形
 * @param ctx canvas2d实例
 * @param width 对话框宽度
 * @param height 对话框高度
 * @param radius 圆角
 */
export const drawRoundRectPath = (ctx, width, height, radius) => {
  ctx.beginPath();
  ctx.arc(width - radius, height - radius, radius, 0, Math.PI / 2);

  // 底部
  // ctx.lineTo(radius, height);
  // start 底部 三角形绘制 10是三角形高度
  ctx.lineTo((width / 2) + TriangleHeight, height);

  ctx.lineTo((width / 2), height + TriangleHeight);

  ctx.lineTo((width / 2) - TriangleHeight, height);
  // end 三角形绘制

  ctx.lineTo(radius, height);

  ctx.arc(radius, height - radius, radius, Math.PI / 2, Math.PI);

  ctx.lineTo(0, radius);

  ctx.arc(radius, radius, radius, Math.PI, Math.PI * (3 / 2));

  ctx.lineTo(width - radius, 0);

  ctx.arc(width - radius, radius, radius, Math.PI * (3 / 2), Math.PI * 2);

  ctx.lineTo(width, height - radius);

  ctx.closePath();
};

/**
 * 绘制椭圆 https://developer.mozilla.org/zh-CN/docs/Web/API/CanvasRenderingContext2D/ellipse
 * @param ctx canvas实例对象
 * @param x 坐标位置
 * @param y 坐标位置
 * @param rx 椭圆长轴半径
 * @param ry 椭圆短轴半径
 * @param mRx 小圆长轴半径
 * @param mRy 小圆短轴半径
 * @param minColor 小圆颜色
 * @param maxColor 大圆颜色
 */
export const drawDoubleEllipse = (ctx, x, y, rx, ry, mRx, mRy, minColor, maxColor) => {
  // 外部透明圆 这里需要做成动画
  ctx.beginPath();
  ctx.ellipse(x, y, rx, ry, 0, 0, 2 * Math.PI);
  // eslint-disable-next-line no-param-reassign
  ctx.fillStyle = maxColor;
  ctx.fill();
  ctx.closePath();
  // 内部小圆
  ctx.beginPath();
  ctx.ellipse(x, y, mRx, mRy, 0, 0, 2 * Math.PI);
  // eslint-disable-next-line no-param-reassign
  ctx.fillStyle = minColor;
  // eslint-disable-next-line no-param-reassign
  ctx.strokeStyle = '#fff';
  ctx.fill();
  ctx.stroke();
  ctx.closePath();
};
