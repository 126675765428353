/**
 * Author: He DingXiong
 * Date: 2021-21-17
 * Des: canvas-config 配置当前canvas的节点数据结构
 * */
import { CANVAS_MULTIPLE } from '../const';
import { multiply } from '../utils';

/**
 * 库区数据结构
 * @type {{name: string, dotP: {x: number, y: number}, dialogP: {x: number, y: number},
 * content: [{label: string, value: number}, {label: string, value: number}]}[]}
 */
const StoreOriginConfig = [{
  id: 4,
  title: '库区D',
  type: 1, // 1.normal 2.hidden
  state: 1, // 1.normal. 2.active
  waring: 1, // 是否被选中
  isDisabled: false,
  isHover: false,
  content: [{
    label: '库存数量（EA）',
    value: 12345678.009,
  }, {
    label: '板位',
    value: 2345,
  }],
  x: 235,
  y: 120,
}, {
  id: 5,
  title: '库区E',
  type: 1, // 1.normal 2.hidden
  state: 1, // 1.normal. 2.active
  waring: 1, // 是否被选中 1.normal 2.active
  isDisabled: false,
  isHover: false,
  content: [{
    label: '库存数量（EA）',
    value: 12345678.009,
  }, {
    label: '板位',
    value: 2345,
  }],
  x: 712,
  y: 120,
}];

export const StoreHouseConfig = StoreOriginConfig.map((config) => ({
  ...config,
  x: multiply(config.x, CANVAS_MULTIPLE),
  y: multiply(config.y, CANVAS_MULTIPLE),
}));

/**
 * 货架数据结构
 * @type {*[]}
 */
export const ShelvesOriginConfig = [{
  id: 2,
  title: '',
  state: 1, // 1.normal. 2.active
  waring: 1, // 是否被选中
  isDisabled: false,
  isHover: false,
  content: [{
    label: '库存数量（EA）',
    value: 12345678.009,
  }, {
    label: '板位',
    value: 2345,
  }],
  x: 150,
  y: 150,
}, {
  id: 3,
  title: '',
  state: 1, // 1.normal. 2.active
  waring: 1, // 是否被选中
  isDisabled: false,
  isHover: false,
  content: [{
    label: '库存数量（EA）',
    value: 12345678.009,
  }, {
    label: '板位',
    value: 2345,
  }],
  x: 340,
  y: 400,
}, {
  id: 4,
  title: '',
  state: 1, // 1.normal. 2.active
  waring: 1, // 是否被选中
  isHover: false,
  isDisabled: false,
  content: [{
    label: '库存数量（EA）',
    value: 12345678.009,
  }, {
    label: '板位',
    value: 2345,
  }],
  x: 330,
  y: 100,
}, {
  id: 5,
  title: '',
  state: 1, // 1.normal. 2.active
  waring: 1, // 是否被选中
  isHover: false,
  isDisabled: false,
  content: [{
    label: '库存数量（EA）',
    value: 12345678.009,
  }, {
    label: '板位',
    value: 2345,
  }],
  x: 525,
  y: 355,
}, {
  id: 6,
  title: '',
  state: 1, // 1.normal. 2.active
  waring: 1, // 是否被选中
  isHover: false,
  isDisabled: false,
  content: [{
    label: '库存数量（EA）',
    value: 12345678.009,
  }, {
    label: '板位',
    value: 2345,
  }],
  x: 520,
  y: 70,
}, {
  id: 7,
  title: '',
  state: 1, // 1.normal. 2.active
  waring: 1, // 是否被选中
  isHover: false,
  isDisabled: false,
  content: [{
    label: '库存数量（EA）',
    value: 12345678.009,
  }, {
    label: '板位',
    value: 2345,
  }],
  x: 715,
  y: 310,
}, {
  id: 8,
  title: '',
  state: 1, // 1.normal. 2.active
  waring: 1, // 是否被选中
  isHover: false,
  isDisabled: false,
  content: [{
    label: '库存数量（EA）',
    value: 12345678.009,
  }, {
    label: '板位',
    value: 2345,
  }],
  x: 715,
  y: 25,
}, {
  id: 9,
  title: '',
  state: 1, // 1.normal. 2.active
  waring: 1, // 是否被选中
  isHover: false,
  isDisabled: false,
  content: [{
    label: '库存数量（EA）',
    value: 12345678.009,
  }, {
    label: '板位',
    value: 2345,
  }],
  x: 900,
  y: 80,
}, {
  id: 10,
  title: '',
  state: 1, // 1.normal. 2.active
  waring: 1, // 是否被选中
  isHover: false,
  isDisabled: false,
  content: [{
    label: '库存数量（EA）',
    value: 12345678.009,
  }, {
    label: '板位',
    value: 2345,
  }],
  x: 945,
  y: 320,
}, {
  id: 11,
  title: '',
  state: 1, // 1.normal. 2.active
  waring: 1, // 是否被选中
  isHover: false,
  isDisabled: false,
  content: [{
    label: '库存数量（EA）',
    value: 12345678.009,
  }, {
    label: '板位',
    value: 2345,
  }],
  x: 760,
  y: 200,
}];

export const ShelvesConfig = ShelvesOriginConfig.map((config) => ({
  ...config,
  x: config.x * CANVAS_MULTIPLE,
  y: config.y * CANVAS_MULTIPLE,
}));

/**
 * 库区位置信息
 * @type {({id: number, title: string})[]}
 */
export const storePositionConfig = [{
  id: 1,
  title: '库区A',
  state: 2, // 1.normal 2.active
  waring: 1, // 1.normal 2.active
}, {
  id: 2,
  title: '库区B',
  state: 1, // 1.normal 2.active
  waring: 1, // 1.normal 2.active
}, {
  id: 3,
  title: '库区C',
  state: 1, // 1.normal 2.active
  waring: 1, // 1.normal 2.active
}, {
  id: 4,
  title: '库区D',
  state: 1, // 1.normal 2.active
  waring: 1, // 1.normal 2.active
}];

/**
 * 库位位置信息
 * @type {*[]}
 */
export const shelvesPositionConfig = [{
  id: 1,
  title: '货架1',
  state: 2, // 1.normal 2.active
  waring: 1, // 1.normal 2.active
}, {
  id: 2,
  title: '货架2',
  state: 1, // 1.normal 2.active
  waring: 1, // 1.normal 2.active
}, {
  id: 3,
  title: '货架3',
  state: 1, // 1.normal 2.active
  waring: 2, // 1.normal 2.active
}, {
  id: 4,
  title: '货架4',
  state: 1, // 1.normal 2.active
  waring: 1, // 1.normal 2.active
}, {
  id: 5,
  title: '货架5',
  state: 1, // 1.normal 2.active
  waring: 1, // 1.normal 2.active
}, {
  id: 6,
  title: '货架6',
  state: 1, // 1.normal 2.active
  waring: 2, // 1.normal 2.active
}, {
  id: 7,
  title: '货架7',
  state: 1, // 1.normal 2.warming 3.active
  waring: 1, // 1.normal 2.active
}, {
  id: 8,
  title: '货架8',
  state: 1, // 1.normal 2.warming 3.active
  waring: 1, // 1.normal 2.active
}, {
  id: 9,
  title: '货架9',
  state: 1, // 1.normal 2.warming 3.active
  waring: 1, // 1.normal 2.active
}, {
  id: 10,
  title: '货架10',
  state: 1, // 1.normal 2.warming 3.active
  waring: 1, // 1.normal 2.active
}, {
  id: 11,
  title: '货架11',
  state: 1, // 1.normal 2.warming 3.active
  waring: 1, // 1.normal 2.active
}, {
  id: 12,
  title: '货架12',
  state: 1, // 1.normal 2.warming 3.active
  waring: 1, // 1.normal 2.active
}];
