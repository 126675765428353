<template>
  <div
    v-if="hasSwitch"
    class="switch-bar-root"
  >
    <el-switch
      v-model="showTipsState"
      active-color="#06b880"
      inactive-color="#c8c9cc"
      @change="handleSwitch"
    />
    <p class="tips">
      {{ switchTips }}
    </p>
  </div>
</template>

<script>
import { WAREHOUSE_TYPE } from '../const';

export default {
  name: 'SwitchBar',
  props: {
    level: {
      type: Number,
      default: WAREHOUSE_TYPE.HOME,
    },
    isIframe: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['dialogState'],
  data() {
    return {
      hiddenSwitchBar: true,
      showTipsState: true,
    };
  },
  computed: {
    switchTips() {
      return this.showTipsState ? '所有数据已显示' : '所有数据已隐藏';
    },
    hasSwitch() {
      // 库房和货架时 且没有进行筛选 才显示
      // eslint-disable-next-line max-len
      return this.hiddenSwitchBar && (this.level === WAREHOUSE_TYPE.SHELVES || this.level === WAREHOUSE_TYPE.STOREHOUSE);
    },
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler(to) {
        const { cond, owner, goods } = to.query;
        // 如果存在筛选条件、则隐藏按钮
        if (cond || (owner && !this.isIframe) || goods) {
          this.hiddenSwitchBar = false;
          this.showTipsState = false;
          this.handleSwitch(false);
        } else {
          this.hiddenSwitchBar = true;
          this.showTipsState = true;
          this.handleSwitch(true);
        }
      },
    },
  },
  methods: {
    handleSwitch(state) {
      this.$emit('dialogState', state);
    },
  },
};
</script>

<style lang="scss" scoped>
.switch-bar-root {
  position: absolute;
  padding: 0.12em;
  top: 8em;
  left: 5.84em;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #fff;
  border-radius: 0.08em;
  z-index: 1000;

  .tips {
    margin-left: 8px;
    font-size: 0.14em;
    color: #323233;
  }
}
</style>
